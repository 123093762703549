import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import isEmail from 'validator/lib/isEmail';
import { useHistory } from 'react-router';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { StoreContext } from '../stores';
import { TextField } from '../library';
import { useSnackbar } from 'notistack';
import { SubmitButton } from './SubmitButton';
import { PublicPaths } from '../pages';
import { Fragment, useCallback, useContext } from 'react';

const useStyles = makeStyles(theme => ({
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
}));

export const RequestPasswordResetForm: React.FC = () => {
	const { passwordResetStore } = useContext(StoreContext);
	const classes = useStyles();

	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const onRequestReset = useCallback(
		async ({ email }: { email: string }) => {
			if (await passwordResetStore.requestPasswordReset(email)) {
				history.push(PublicPaths.passwordResetLinkSent);
			} else {
				enqueueSnackbar('Failed to request password reset', { variant: 'error' });
			}
		},
		[passwordResetStore, history, enqueueSnackbar]
	);

	const formMethods = useForm<{ email: string }>({
		mode: 'all',
		defaultValues: {
			email: '',
		},
	});
	const { handleSubmit, control } = formMethods;

	return (
		<Fragment>
			<Typography variant="h6">Request Password Reset</Typography>
			<FormProvider {...formMethods}>
				<form className={classes.form} onSubmit={handleSubmit(onRequestReset)}>
					<Grid container>
						<Grid item xs={12}>
							<Controller
								name="email"
								control={control}
								defaultValue=""
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<TextField
										label="E-Mail"
										placeholder="E-Mail"
										fullWidth
										autoFocus
										value={value}
										onChange={onChange}
										error={!!error}
										helperText={error?.message || ''}
									/>
								)}
								rules={{
									validate: email => {
										if (!email) {
											return 'Required';
										} else if (!isEmail(email)) return 'Not a valid email address';
										return undefined;
									},
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<SubmitButton className={classes.submit}>Request password reset link</SubmitButton>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
		</Fragment>
	);
};
