import { observer } from 'mobx-react';
import { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { Company } from '../models/company';
import { PrivatePath } from '../pages';
import { StoreContext } from '../stores';
import { LoadingIndicator } from './LoadingIndicator';

type Props = {
	companyConsumer: (company: Company) => React.ReactElement;
};
export const WithCompany: React.FC<Props> = observer(function WithCompany({ companyConsumer }) {
	const { companyStore } = useContext(StoreContext);
	const history = useHistory();
	const params = useParams<{ companyId: string }>();
	const loaded = companyStore.loaded;
	useEffect(() => {
		if (loaded) {
			if (!companyStore.selectById(params.companyId)) {
				history.push(PrivatePath.companies);
			}
		} else {
			companyStore.loadCompanies().then(() => {
				if (!companyStore.selectById(params.companyId)) {
					history.push(PrivatePath.companies);
				}
			});
		}
	}, [params, companyStore, loaded, history]);
	if (
		!companyStore.loaded ||
		companyStore.selectedCompany == null ||
		companyStore.selectedCompany.id !== params.companyId
	) {
		return <LoadingIndicator />;
	}
	return companyConsumer(companyStore.selectedCompany);
});
