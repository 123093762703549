import { GetUserResponse } from '@thingos/m4i-webservice-shared';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import isEmail from 'validator/lib/isEmail';
import { User } from '../models/user';

async function getUser(email: string): Promise<GetUserResponse> {
	console.log(`getUser(${email})`);
	await new Promise(r => setTimeout(r, 500));
	const getUserResponse = email.startsWith('test')
		? ({
				exists: true,
				id: '9c2c20cf-b59e-4102-8815-ce3d30c09b43',
				email,
				userDetailsHidden: true,
		  } as GetUserResponse)
		: email.startsWith('benjamin')
		? ({
				exists: true,
				id: 'c8d80c26-bd7a-41c0-9f77-255e876a5894',
				email,
				userDetailsHidden: false,
				userDetails: {
					name: 'Benjamin Rau',
					id: 'c8d80c26-bd7a-41c0-9f77-255e876a5894',
					email,
					admin: true,
				},
		  } as GetUserResponse)
		: ({ exists: false } as GetUserResponse);
	return getUserResponse;
}

export class UserSelectionStore {
	public user: User | null = null;
	public email = '';
	public fetching = false;
	private emailSubject: Subject<string> = new Subject<string>();

	get validEmail(): boolean {
		return this.emailError.length === 0;
	}

	get emailError(): string {
		if (!this.email) return 'Required';
		if (!isEmail(this.email)) return 'Not a valid E-Mail';
		return '';
	}

	public setEmail(email: string) {
		this.email = email;
		this.emailSubject.next(email);
	}

	public constructor() {
		makeObservable(this, {
			user: observable,
			fetching: observable,
			email: observable,
			setEmail: action,
			emailError: computed,
			validEmail: computed,
		});

		this.emailSubject
			.pipe(
				distinctUntilChanged(),
				tap(() => runInAction(() => (this.fetching = true))),
				debounceTime(300),
				switchMap(getUser)
			)
			.subscribe(
				u => {
					runInAction(() => {
						if (u.exists && !u.userDetailsHidden) {
							this.user = new User({
								id: u.id,
								email: u.email,
							});
						} else {
							this.user = null;
						}
						this.fetching = false;
					});
				},
				e => {
					console.log(e);
				}
			);
	}
}
