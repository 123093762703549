import { InputLabel, TextField } from '@material-ui/core';
import styled from 'styled-components';
import isHexColor from 'validator/lib/isHexColor';

import { useCallback, useState } from 'react';
import { useInputStyle } from '../library';
import shadows from '@material-ui/core/styles/shadows';
import { useTranslation } from 'react-i18next';

const ColorDot = styled.img<{ color: string }>(({ color }) => ({
	backgroundColor: color,
	borderRadius: '50%',
	borderStyle: 'hidden',
	height: '100%',
	aspectRatio: '1',
}));

const ChipRow = styled.div({
	boxShadow: shadows[2],
	display: 'flex',
	flexDirection: 'row',
	borderRadius: 25,
	height: 50,
	padding: 8,
	backgroundColor: '#FBFBFB',
});

const ColorInput = styled.div({
	flex: 1,
	marginLeft: 10,
	marginRight: 10,
});

type ColorPickerProps = {
	label: string;
	value: string;
	onChange: (value: string) => void;
	error?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const ColorPicker: React.FC<ColorPickerProps> = props => {
	const inputClasses = useInputStyle();
	const [inputValue, setInputValue] = useState(props.value || '#D20A27');
	const onChange = useCallback(
		(value: string) => {
			setInputValue(value);
			if (isHexColor(value)) {
				props.onChange(value);
			}
		},
		[props, setInputValue]
	);

	const { t } = useTranslation('companies');

	return (
		<div {...props}>
			<InputLabel
				className={inputClasses.label}
				shrink
				style={{ marginBottom: 8, marginLeft: -8 }}
				error={props.error != null}
			>
				{props.label}
			</InputLabel>
			<ChipRow>
				<ColorDot color={props.value} />
				<ColorInput>
					<TextField
						placeholder={t('newCompany.highlightColorPlaceholder')}
						value={inputValue}
						onChange={e => onChange(e.target.value)}
					/>
				</ColorInput>
			</ChipRow>
		</div>
	);
};
