/**
 * Login form
 */

import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import isEmail from 'validator/lib/isEmail';
import { useHistory } from 'react-router';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Fragment, useCallback, useContext, useRef } from 'react';
import { useSnackbar } from 'notistack';

import { Link } from '@thingos/thingos-components';
import { LoginData } from '@thingos/m4i-webservice-shared';

import { StoreContext } from '../stores';
import { PublicPaths as publicPaths } from '../pages/public';
import { PasswordField, TextField } from '../library';
import { SubmitButton } from './SubmitButton';
import { PrivatePath } from '../pages';

const useStyles = makeStyles(theme => ({
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
}));

export const LoginForm: React.FC = () => {
	const { accountStore, companyStore } = useContext(StoreContext);
	const classes = useStyles();
	const { t } = useTranslation('public');
	const { t: common } = useTranslation('common');

	const passwordInputRef = useRef<HTMLInputElement>();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const onLogin = useCallback(
		async (loginData: LoginData) => {
			accountStore.login(loginData).then(result => {
				if (!result.success) {
					enqueueSnackbar(result.message, { variant: 'error' });
				} else {
					companyStore.loadCompanies().then(size => {
						if (size === 1) {
							history.push(`/${companyStore.selectedCompany!.id}/templates`);
						} else {
							history.push(PrivatePath.companies);
						}
					});
				}
			});
		},
		[accountStore, history, enqueueSnackbar, companyStore]
	);

	const formMethods = useForm<LoginData>({
		mode: 'all',
		defaultValues: {
			email: '',
			password: '',
			remember: false,
		},
	});
	const { handleSubmit, control } = formMethods;

	return (
		<Fragment>
			<Typography variant="h6">{t('login.title')}</Typography>
			<FormProvider {...formMethods}>
				<form className={classes.form} onSubmit={handleSubmit(onLogin)}>
					<Grid container>
						<Grid item xs={12}>
							<Controller
								name="email"
								control={control}
								defaultValue=""
								render={({ field: { onChange, value }, fieldState: { error, isTouched } }) => (
									<TextField
										label={common('forms.emailLabel')}
										placeholder={common('forms.emailPlaceholder')}
										fullWidth
										type="email"
										autoComplete="email"
										inputMode="email"
										autoFocus
										value={value}
										onChange={onChange}
										error={!!error && isTouched}
										helperText={error?.message || ''}
										nextInputOnEnter={passwordInputRef}
									/>
								)}
								rules={{
									validate: email => {
										if (!email) {
											return common('forms.required');
										} else if (!isEmail(email)) return common('forms.invalidMail');
										return undefined;
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} style={{ position: 'relative' }}>
							<Controller
								name="password"
								control={control}
								defaultValue=""
								render={({ field: { onChange, value }, fieldState: { error, isTouched } }) => (
									<PasswordField
										label={t('login.password')}
										inputRef={ref => (passwordInputRef.current = ref)}
										placeholder={t('login.password')}
										fullWidth
										autoComplete="password"
										autoFocus
										value={value}
										onChange={onChange}
										error={!!error && isTouched}
										helperText={error?.message || ''}
									/>
								)}
								rules={{
									required: common('forms.required'),
								}}
							/>
							<Link
								to={publicPaths.requestPasswordReset}
								variant="caption"
								style={{ position: 'absolute', right: '0px' }}
							>
								{t('login.forgotPassword')}
							</Link>
						</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Controller
										name="remember"
										control={control}
										defaultValue={false}
										render={({ field: { onChange, value } }) => (
											<Checkbox
												checked={value}
												onChange={onChange}
												name="remember"
												color="primary"
											/>
										)}
									/>
								}
								label={t('login.remember')}
							/>
						</Grid>

						<Grid item xs={12}>
							<SubmitButton className={classes.submit}>{t('login.login')}</SubmitButton>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
		</Fragment>
	);
};
