import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as TE from 'fp-ts/lib/TaskEither';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import { AdminsStore, NewAdminData } from '../stores/adminsStore';
import { pipe } from 'fp-ts/lib/function';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { SubmitButton } from './SubmitButton';
import { useCallback } from 'react';

const useStyles = makeStyles(theme => ({
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	progress: {
		width: '100%',
		position: 'absolute',
		borderTopLeftRadius: 2,
		borderTopRightRadius: 2,
		top: 0,
	},
}));

type Props = {
	done: () => void;
	adminsStore: AdminsStore;
};

export const AddAdminForm: React.FC<Props> = ({ adminsStore, done }) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation('admins');
	const { t: common } = useTranslation('common');

	const onAdd = useCallback(
		async (userData: NewAdminData) =>
			pipe(
				adminsStore.invite(userData),
				TE.map(s =>
					s === 'Added'
						? t('addForm.successAdd', { email: userData.email })
						: t('addForm.successInvite', { email: userData.email })
				),
				TE.mapLeft(e => {
					const errorMessage =
						e === 'Error' ? t('addForm.failureOther') : t('addForm.failureAlreadyAdmin');
					console.log(errorMessage);
					return errorMessage;
				}),
				TE.match(
					e => {
						enqueueSnackbar(e, { variant: 'error' });
					},
					s => {
						done();
						enqueueSnackbar(s);
					}
				)
			)(),
		[done, adminsStore, enqueueSnackbar, t]
	);
	const formMethods = useForm<NewAdminData>({ mode: 'all' });
	const { handleSubmit, control } = formMethods;

	return (
		<FormProvider {...formMethods}>
			<form className={classes.form} onSubmit={handleSubmit(onAdd)} style={{ padding: '16px' }}>
				<Grid container spacing={1} direction="column">
					<Grid item>
						<Controller
							name="email"
							control={control}
							defaultValue=""
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<TextField
									label={t('addForm.emailLabel')}
									placeholder={t('addForm.emailPlaceholder')}
									fullWidth
									autoFocus
									value={value}
									onChange={onChange}
									error={!!error}
									helperText={error?.message || ''}
								/>
							)}
							rules={{
								validate: email => {
									if (!email) {
										return common('forms.required');
									} else if (!isEmail(email)) return common('forms.invalidMail');
									return undefined;
								},
							}}
						/>
					</Grid>
					<Grid item>
						<SubmitButton>{t('addForm.invite')}</SubmitButton>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	);
};
