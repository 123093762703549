import { StatusCodes } from 'http-status-codes';
import * as TE from 'fp-ts/lib/TaskEither';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { action, makeObservable, observable, runInAction } from 'mobx';

import {
	AdminInfo,
	InviteAdminRequest,
	GetAdminsResponse,
	RemoveAdminRequest,
} from '@thingos/m4i-webservice-shared';

import { api } from '../config';

export type { AdminInfo };
export type NewAdminData = InviteAdminRequest;

export class AdminsStore {
	admins: Map<string, AdminInfo>;

	constructor() {
		this.admins = observable.map();
		makeObservable(this, {
			admins: observable.shallow,
			invite: action,
		});
	}

	async loadAdmins(): Promise<void> {
		pipe(
			TE.tryCatch(
				() =>
					fetch(api + '/admins', {
						method: 'GET',
						credentials: 'include',
						mode: 'cors',
					}),
				() => 'Failed to fetch admins'
			),
			TE.chain(response => {
				if (response.status === StatusCodes.OK) {
					return pipe(
						TE.tryCatch(
							() => response.json(),
							() => 'Failed to parse json'
						),
						TE.chainW(json =>
							pipe(
								GetAdminsResponse.decode(json),
								E.mapLeft(() => Error('Response does not have expected format')),
								TE.fromEither
							)
						)
					);
				}
				return TE.left('Failed to fetch companies');
			}),
			TE.match(
				error => console.error(`Failed to get companies ${error}`),
				admins =>
					runInAction(() => (this.admins = observable.map(admins.map(admin => [admin.id, admin]))))
			)
		)();
	}

	invite(
		newAdminData: NewAdminData
	): TE.TaskEither<'AlreadyExists' | 'Error', 'Added' | 'Invited'> {
		return pipe(
			TE.tryCatch(
				async () =>
					fetch(api + '/admins', {
						method: 'POST',
						credentials: 'include',
						mode: 'cors',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(newAdminData),
					}),
				() => 'Error' as const
			),
			TE.chain(response => {
				console.log(response);
				if (response.status === StatusCodes.OK) {
					return pipe(
						TE.tryCatch(
							() => response.json(),
							() => 'Error' as const
						),
						TE.chain(response =>
							pipe(
								AdminInfo.decode(response),
								E.mapLeft(() => 'Error' as const),
								TE.fromEither
							)
						),
						TE.map(adminData => {
							runInAction(() => {
								this.admins.set(adminData.id, adminData);
							});
							return adminData.invitePending ? 'Invited' : 'Added';
						})
					);
				}
				console.log('returning error');
				return TE.left('Error');
			})
		);
	}
	public async removeAdmin(admin: AdminInfo, password: string): Promise<boolean> {
		const removeAdminRequest = RemoveAdminRequest.encode({
			id: admin.id,
			password,
		});
		return pipe(
			TE.tryCatch(
				() =>
					fetch(api + '/admin', {
						method: 'DELETE',
						credentials: 'include',
						mode: 'cors',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(removeAdminRequest),
					}),
				() => 'Failed to issue delete'
			),
			TE.chainW(response => {
				if (response.status === StatusCodes.OK) {
					return TE.right(true);
				}
				return TE.left('Status code indicated failure');
			}),
			TE.match(
				error => {
					console.error(`Failed to delete the company: ${error}`);
					return false;
				},
				() => {
					runInAction(() => {
						this.admins.delete(admin.id);
					});
					return true;
				}
			)
		)();
	}
}
