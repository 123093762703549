/**
 * NavbarSidebarButton
 */

import { Button, makeStyles, Typography, useTheme } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { Link, useLocation } from 'react-router-dom';

import { Icons, WebIconType } from '@thingos/design-icons';
import styled from 'styled-components';

const ItemRow = styled.div({
	display: 'flex',
	flexDirection: 'row',
	paddingTop: '8px',
	paddingBottom: '8px',
});

const Icon = styled(Icons)({
	marginRight: 16,
});

const CustomLink = styled(Link)({
	textDecoration: 'none',
});

const useStyles = makeStyles({
	root: {
		justifyContent: 'start',
		width: '100%',
		paddingLeft: '24px',
		paddingRight: '24px',
		paddingTop: '12px',
		paddingBottom: '12px',
		textTransform: 'none',
		borderRadius: 0,
	},
});

type NavbarButtonProps = {
	icon: WebIconType;
	label: string;
	iconRight?: WebIconType;
	onClick?: () => void;
	active?: boolean;
};

export const NavbarSidebarButton: React.FC<NavbarButtonProps> = ({
	icon,
	iconRight,
	label,
	onClick,
	active = false,
}) => {
	const theme = useTheme();
	const classes = useStyles();
	return (
		<Button onClick={onClick} className={classes.root} color="primary">
			<ItemRow>
				<Icon
					kind={icon}
					height="24"
					width="24"
					color={active ? theme.palette.primary.main : grey[600]}
				/>
				<Typography
					variant="body1"
					style={{
						color: active ? theme.palette.primary.main : grey[600],
						fontWeight: active ? 600 : 400,
					}}
				>
					{label}
				</Typography>
				{iconRight && (
					<Icon
						kind={iconRight}
						height="24"
						width="24"
						color={active ? theme.palette.primary.main : grey[600]}
					/>
				)}
			</ItemRow>
		</Button>
	);
};

type Props = {
	icon: WebIconType;
	iconRight?: WebIconType;
	label: string;
	to: string;
	active?: boolean;
} & ({ onClick: () => void } | { to: string });
export const NavbarSidebarLink: React.FC<Props> = ({ to, icon, label, iconRight, active }) => {
	const { pathname } = useLocation();
	return (
		<CustomLink to={to}>
			<NavbarSidebarButton
				active={active != null ? active : pathname === to}
				label={label}
				icon={icon}
				iconRight={iconRight}
			/>
		</CustomLink>
	);
};
