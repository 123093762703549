import { TagInfo, TemplateInfo } from '@thingos/m4i-webservice-shared';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { Templates } from './templates';
import { User } from './user';
import { Role, UserRole } from './userRole';

type CompanyData = {
	readonly id: string;
	name: string;
	highlightColor: string;
	tags: TagInfo[];
	companyLogo: string;
	templates: TemplateInfo[];
} & (
	| { isCompanyAdmin: false }
	| {
			isCompanyAdmin: true;
			users: { isAdmin: boolean; user: User }[];
	  }
);

export type NewUserData = {
	email: string;
	role: Role;
};

export class Company {
	readonly id: string;
	name: string;
	isCompanyAdmin: boolean;
	highlightColor: string;
	companyLogo: string;
	users: UserRole[];
	templates: Templates;
	tags: TagInfo[];

	get adminRoles() {
		return this.users.filter(userRole => userRole.role === Role.CompanyAdmin);
	}

	get templateCreatorRoles() {
		return this.users.filter(user => user.role === Role.TemplateCreator);
	}

	get adminNames() {
		return this.adminRoles.map(adminRole => adminRole.user.name || adminRole.user.email).join(', ');
	}

	addUserRole(isAdmin: boolean, user: User) {
		if (isAdmin) {
			const adminRole = new UserRole(user, this, Role.CompanyAdmin);
			user.roles.push(adminRole);
			this.users.push(adminRole);
		} else {
			const userRole = new UserRole(user, this, Role.TemplateCreator);
			user.roles.push(userRole);
			this.users.push(userRole);
		}
	}

	removeRole(userRole: UserRole) {
		runInAction(() => {
			this.users = this.users.filter(ur => ur !== userRole);
			userRole.user.roles = userRole.user.roles.filter(ur => ur !== userRole);
		});
	}

	addTag(tag: TagInfo) {
		this.tags.push(tag);
	}

	constructor(companyData: CompanyData) {
		this.id = companyData.id;
		this.name = companyData.name;
		this.highlightColor = companyData.highlightColor;
		this.companyLogo = companyData.companyLogo;
		this.isCompanyAdmin = companyData.isCompanyAdmin;
		this.tags = companyData.tags;
		this.templates = new Templates(this.id, companyData.templates);
		this.users = observable.array([]);
		makeObservable(this, {
			name: observable,
			highlightColor: observable,
			companyLogo: observable,
			users: observable,
			adminRoles: computed,
			templateCreatorRoles: computed,
			adminNames: computed,
			addUserRole: action,
		});
		if (companyData.isCompanyAdmin) {
			companyData.users.forEach(({ isAdmin, user }) => this.addUserRole(isAdmin, user));
		}
	}
}
