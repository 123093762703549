import { Button, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PublicPaths } from '../pages';
import { StoreContext } from '../stores';

export const PasswordResetSent: React.FC = observer(function PasswordResetSent() {
	const { passwordResetStore } = useContext(StoreContext);
	const { t } = useTranslation('public');
	return (
		<Fragment>
			<Typography variant="h6">{t('passwordReset.sentTitle')}</Typography>
			<Grid container>
				<Grid item xs={12}>
					<Typography>
						{t('passwordReset.sentInfo', { email: passwordResetStore.email })}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography>{t('passwordReset.sentMissing')}</Typography>
					<Button href={PublicPaths.requestPasswordReset}>
						{t('passwordReset.requestAnother')}
					</Button>
				</Grid>
			</Grid>
		</Fragment>
	);
});
