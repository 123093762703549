import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { Fragment, useCallback, useContext, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

import { PasswordField, TextField } from '../../library';
import { SubmitButton } from '../../components/SubmitButton';
import { StoreContext } from '../../stores';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	progress: {
		width: '100%',
		position: 'absolute',
		borderTopLeftRadius: 2,
		borderTopRightRadius: 2,
		top: 0,
	},
}));

type RegistrationFormData = {
	name?: string;
	password: string;
	passwordRepeat: string;
};

const RegistrationForm: React.FC<{ email: string; invitation: string }> = ({
	email,
	invitation,
}) => {
	const { t } = useTranslation('public');
	const { t: common } = useTranslation('common');
	const { registrationStore, accountStore } = useContext(StoreContext);
	const classes = useStyles();

	const nameInputRef = useRef<HTMLInputElement>();
	const passwordInputRef = useRef<HTMLInputElement>();
	const passwordRepeatInputRef = useRef<HTMLInputElement>();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const register = useCallback(
		async (registrationFormData: RegistrationFormData) => {
			if (await registrationStore.register({ invitation, ...registrationFormData })) {
				accountStore
					.login({ email, password: registrationFormData.password, remember: false })
					.then(result => {
						if (!result.success) {
							enqueueSnackbar(result.message, { variant: 'error' });
						} else {
							history.push(result.redirect);
						}
					});
			} else {
				enqueueSnackbar(t('registration.failure'));
			}
		},
		[registrationStore, history, enqueueSnackbar, invitation, accountStore, email, t]
	);

	const formContext = useForm<RegistrationFormData>({
		mode: 'all',
	});
	const { handleSubmit, control, watch } = formContext;

	return (
		<FormProvider {...formContext}>
			<form className={classes.form} onSubmit={handleSubmit(register)}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<TextField
							label={common('forms.emailLabel')}
							placeholder={common('forms.emailPlaceholder')}
							fullWidth
							disabled
							value={email}
							autoComplete="email"
							name="username"
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="name"
							control={control}
							defaultValue=""
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<TextField
									label={t('registration.nameLabel')}
									placeholder={t('registration.namePlaceholder')}
									inputRef={ref => (nameInputRef.current = ref)}
									fullWidth
									autoFocus
									value={value}
									onChange={onChange}
									error={!!error}
									helperText={error?.message || ''}
									nextInputOnEnter={passwordInputRef}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} style={{ position: 'relative' }}>
						<Controller
							name="password"
							control={control}
							defaultValue=""
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<PasswordField
									label={t('registration.password')}
									inputRef={ref => (passwordInputRef.current = ref)}
									placeholder={t('registration.password')}
									fullWidth
									value={value}
									onChange={onChange}
									error={!!error}
									helperText={error?.message || ''}
									nextInputOnEnter={passwordRepeatInputRef}
								/>
							)}
							rules={{
								required: common('forms.required'),
							}}
						/>
					</Grid>
					<Grid item xs={12} style={{ position: 'relative' }}>
						<Controller
							name="passwordRepeat"
							control={control}
							defaultValue=""
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<PasswordField
									label={t('registration.repeatPassword')}
									inputRef={ref => (passwordRepeatInputRef.current = ref)}
									placeholder={t('registration.repeatPassword')}
									fullWidth
									value={value}
									onChange={onChange}
									error={!!error}
									helperText={error?.message || ''}
								/>
							)}
							rules={{
								validate: value =>
									value !== watch('password') ? t('registration.mustMatch') : undefined,
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<SubmitButton>{t('registration.create')}</SubmitButton>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	);
};

export const Registration: React.FC = observer(function Registration() {
	const { invitation } = useParams<{ invitation: string }>();
	const { registrationStore } = useContext(StoreContext);
	const { t } = useTranslation('public');

	useEffect(() => {
		registrationStore.fetchInvitationInfo(invitation);
	}, [invitation, registrationStore]);

	return registrationStore.invitationInfo == null ? (
		<Fragment>
			<CircularProgress />
			<Typography>{t('registration.checkingInvitation')}</Typography>
		</Fragment>
	) : (
		<Fragment>
			<Typography variant="h6">{t('registration.title')}</Typography>
			{registrationStore.invitationInfo.error ? (
				<Typography>{t('registration.invitationInvalid')}</Typography>
			) : registrationStore.invitationInfo.info.expired ? (
				<Typography>{t('registration.invitationExpired')}</Typography>
			) : (
				<RegistrationForm
					email={registrationStore.invitationInfo.info.email}
					invitation={invitation}
				/>
			)}
		</Fragment>
	);
});
