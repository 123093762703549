import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';

import { SvgHiker } from '../svgs';
import React from 'react';

const EmptyContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
	justifyContent: 'center',
});

const GridRow = styled(Grid).attrs({ item: true, xs: 12 })({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
});

type Props = {
	text: string;
};

export const Empty: React.FC<Props> = ({ text }) => (
	<EmptyContainer>
		<Grid container spacing={1}>
			<GridRow>
				<SvgHiker />
			</GridRow>
			<GridRow>
				<Typography variant="h5">{text}</Typography>
			</GridRow>
		</Grid>
	</EmptyContainer>
);
