const strongPasswordRegex = new RegExp(
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
);
const mediumPasswordRegex = new RegExp(
	/^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/
);
export type PasswordStrength = 'weak' | 'medium' | 'strong';

export function passwordStrengthColor(passwordStrength: PasswordStrength) {
	if (passwordStrength === 'strong') {
		return 'green';
	} else if (passwordStrength === 'medium') {
		return 'orange';
	}
	return 'red';
}

export function calculatePasswordStrength(password: string): PasswordStrength {
	if (strongPasswordRegex.test(password)) {
		return 'strong';
	} else if (mediumPasswordRegex.test(password)) {
		return 'medium';
	}
	return 'weak';
}

export type PasswordData = {
	password?: string;
	repeatPassword?: string;
};

export const mininumPasswordLength = 8;
export function validate(values: PasswordData): PasswordData {
	const errors: PasswordData = {};
	if (!values.password) {
		errors.password = 'Required';
	} else if (values.password.length < mininumPasswordLength) {
		errors.password = `At least ${mininumPasswordLength} characters required`;
	}
	if (values.repeatPassword !== values.password) {
		errors.repeatPassword = 'Passwords do not match';
	}
	return errors;
}
