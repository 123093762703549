import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/lib/TaskEither';
import { StatusCodes } from 'http-status-codes';
import { api } from '../config';

export type NewBatchData = {
	customer: string;
	amount: number;
	purposeNotes: string;
};

export type BatchData = NewBatchData & {
	templateName: string;
	id: string;
	date: Date;
};

export class Batch implements BatchData {
	id: string;
	templateName: string;
	purposeNotes: string;
	customer: string;
	amount: number;
	date: Date;

	constructor(batchData: BatchData) {
		this.id = batchData.id;
		this.templateName = batchData.templateName;
		this.purposeNotes = batchData.purposeNotes;
		this.customer = batchData.customer;
		this.amount = batchData.amount;
		this.date = batchData.date;
	}

	downloadCSV(): Promise<boolean> {
		return pipe(
			TE.tryCatch(
				async () =>
					fetch(api + `/batch/csv/${this.id}`, {
						method: 'GET',
						credentials: 'include',
						mode: 'cors',
					}),
				e => 'Failed to get batch csv' + e
			),
			TE.chain(response => {
				if (response.status === StatusCodes.OK) {
					return TE.tryCatch(
						async () => response.blob(),
						() => 'failed to get blob'
					);
				}
				return TE.left('Bad status code for get batch csv request');
			}),
			TE.match(
				e => {
					console.log(e);
					return false;
				},
				blob => {
					const file = window.URL.createObjectURL(blob);
					window.location.assign(file);
					return true;
				}
			)
		)();
	}
}
