import { makeObservable, observable } from 'mobx';
import { UserRole } from './userRole';

export type UserData = {
	readonly id: string;
	email: string;
	name?: string;
};

export class User implements UserData {
	id: string;
	email: string;
	name?: string;
	roles: UserRole[];

	constructor(userData: UserData) {
		this.id = userData.id;
		this.email = userData.email;
		this.roles = observable.array([]);
		this.name = userData.name;
		makeObservable(this, {
			email: observable,
			name: observable,
			roles: observable,
		});
	}
}
