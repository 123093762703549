/**
 * NavbarSidebar
 */

import { observer } from 'mobx-react-lite';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Logo } from './Logo';
import { NavbarSidebarLink, NavbarSidebarButton } from './NavbarSidebarItem';
import { StoreContext } from '../stores';
import { PrivatePath } from '../pages';
import { Raised } from './Raised';
import { useTranslation } from 'react-i18next';
import { Fragment, useCallback, useContext } from 'react';

const LogoContainer = styled(Logo)({
	margin: `32px 24px 32px 24px`,
});

const Sidebar = styled(Raised).attrs({ layer: 2 })({
	position: 'relative',
	minWidth: 200,
	height: '100%',
	zIndex: 10,
	display: 'flex',
	flexDirection: 'column',
});

const Group = styled.div({
	display: 'flex',
	flexDirection: 'column',
});
const MainGroup = styled(Group)({
	flex: 1,
	paddingBottom: '24px',
});

const Company: React.FC = observer(function Company() {
	const { accountStore, companyStore } = useContext(StoreContext);
	const { t } = useTranslation('sidebar');
	if (accountStore.showCompaniesInNavbar) {
		return (
			<NavbarSidebarLink
				to={PrivatePath.companies}
				icon="ui/shop"
				iconRight="ui/arrowDown"
				label={companyStore.selectedCompany?.name || t('companies')}
			/>
		);
	} else {
		return (
			<NavbarSidebarButton
				label={companyStore.selectedCompany?.name || ''}
				icon="ui/shop"
				iconRight="ui/arrowDown"
			/>
		);
	}
});

const UsersAndTemplates: React.FC = observer(function UsersAndTemplates() {
	const { accountStore, companyStore } = useContext(StoreContext);
	const { t } = useTranslation('sidebar');
	const { pathname } = useLocation();
	return (
		<Group>
			{companyStore.selectedCompany != null && (
				<NavbarSidebarLink
					to={`/${companyStore.selectedCompany!.id}/templates`}
					icon="ui/networkDecentral"
					label={t('templates')}
					active={
						pathname === `/${companyStore.selectedCompany.id}/templates` ||
						pathname === '/edit-template' ||
						pathname === '/template' ||
						pathname === '/:templateId/network-codes'
					}
				/>
			)}
			{accountStore.showUsersInNavbar && (
				<NavbarSidebarLink
					to={`/${companyStore.selectedCompany!.id}/users`}
					icon="ui/structure"
					label={t('users')}
				/>
			)}
		</Group>
	);
});

export const NavbarSidebar: React.FC = observer(function NavbarSidebar() {
	const { accountStore } = useContext(StoreContext);
	const { t } = useTranslation('sidebar');
	const history = useHistory();
	const onClickLogout = useCallback(() => {
		accountStore.logout().then(() => history.push('/login'));
	}, [accountStore, history]);

	return (
		<Sidebar>
			<LogoContainer />
			<MainGroup>
				<Fragment>
					<Company />
					<UsersAndTemplates />
				</Fragment>
			</MainGroup>
			<Group>
				{accountStore.isAdmin && (
					<NavbarSidebarLink to={PrivatePath.admin} icon="ui/windowSettings" label={t('admin')} />
				)}
				<NavbarSidebarLink to={PrivatePath.settings} icon="ui/settings" label={t('settings')} />
				<NavbarSidebarButton onClick={onClickLogout} icon="ui/logout" label={t('logout')} />
			</Group>
		</Sidebar>
	);
});
