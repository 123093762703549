import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	title: string;
	info: string;
	open: boolean;
	onCancel: () => void;
	onDelete: () => Promise<void>;
};
export const DeleteDialog: React.FC<Props> = observer(
	({ title, info, open, onCancel, onDelete }) => {
		const { t } = useTranslation('common');
		return (
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				maxWidth="xs"
				aria-labelledby="confirmation-dialog-title"
				open={open}
				onClick={e => e.stopPropagation()}
			>
				<DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
				<DialogContent dividers={false}>
					<DialogContentText>{info}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button fullWidth autoFocus onClick={onCancel} color="primary">
						{t('actions.cancel')}
					</Button>
					<Button fullWidth variant="contained" color="primary" onClick={onDelete}>
						{t('actions.delete')}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
);
