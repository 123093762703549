import { Typography } from '@material-ui/core';
import MDEditor from '@uiw/react-md-editor';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator } from '../../components/LoadingIndicator';

import { getCurrentLng } from '../../i18n';

export const TermsOfService: React.FC = () => {
	const { t } = useTranslation('public');
	const [value, setValue] = useState('');
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		setLoading(true);
		fetch(`/locales/${getCurrentLng()}/termsOfService.md`)
			.then(response => {
				console.log(response);
				return response.text();
			})
			.then(value => {
				console.log(value);
				setValue(value);
			})
			.catch(e => console.log('Error: ', e))
			.finally(() => setLoading(false));
	}, [setValue, setLoading]);
	return (
		<Fragment>
			<Typography variant="h6">{t('footer.termsOfService')}</Typography>
			{loading ? (
				<LoadingIndicator />
			) : (
				<MDEditor.Markdown source={value} style={{ overflow: 'auto' }} />
			)}
		</Fragment>
	);
};
