import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TemplateEditor } from '../components/TemplateEditor';
import { StoreContext } from '../stores';
import { DefaultRedirect } from './DefaultRedirect';

export const EditTemplate: React.FC = observer(function EditTemplate() {
	const { companyStore } = useContext(StoreContext);
	const { t } = useTranslation('templates');
	const selectedTemplate = companyStore.selectedCompany?.templates?.selectedTemplate || null;
	if (companyStore.selectedCompany == null || selectedTemplate == null) {
		return <DefaultRedirect />;
	}
	return (
		<TemplateEditor
			allowDraft={selectedTemplate.isDraft}
			templates={companyStore.selectedCompany.templates}
			template={selectedTemplate}
			title={selectedTemplate.name || t('edit.unnamed')}
		/>
	);
});
