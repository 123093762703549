import { pipe } from 'fp-ts/lib/function';
import { StatusCodes } from 'http-status-codes';
import * as TE from 'fp-ts/lib/TaskEither';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { Company } from './company';
import { User } from './user';
import {
	ChangeUserRoleRequest,
	RemoveUserFromCompanyRequest,
} from '@thingos/m4i-webservice-shared';
import { api } from '../config';

export enum Role {
	TemplateCreator = 'TemplateCreator',
	CompanyAdmin = 'CompanyAdmin',
}

export class UserRole {
	constructor(
		public readonly user: User,
		public readonly company: Company,
		public role: Role,
		public invitePending: boolean = false
	) {
		makeObservable(this, { role: observable, changeRole: action });
	}

	async changeRole(role: Role): Promise<boolean> {
		return pipe(
			TE.tryCatch(
				() =>
					fetch(api + '/company/role', {
						method: 'POST',
						credentials: 'include',
						mode: 'cors',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(
							ChangeUserRoleRequest.encode({
								companyId: this.company.id,
								userId: this.user.id,
								admin: role === Role.CompanyAdmin,
							})
						),
					}),
				() => 'Failed to change user role'
			),
			TE.match(
				() => false,
				response => {
					if (response.status === StatusCodes.OK) {
						runInAction(() => {
							this.role = role;
						});
						return true;
					}
					return false;
				}
			)
		)();
	}

	async deleteRole(): Promise<boolean> {
		return pipe(
			TE.tryCatch(
				() =>
					fetch(api + '/company/user', {
						method: 'DELETE',
						credentials: 'include',
						mode: 'cors',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(
							RemoveUserFromCompanyRequest.encode({
								companyId: this.company.id,
								userId: this.user.id,
							})
						),
					}),
				() => 'Failed to remove user from company'
			),
			TE.match(
				() => false,
				response => {
					if (response.status === StatusCodes.OK) {
						this.company.removeRole(this);
						return true;
					}
					return false;
				}
			)
		)();
	}
}
