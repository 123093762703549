import { action, makeObservable, observable } from 'mobx';
import { TagId, TagInfo } from '@thingos/m4i-webservice-shared';

export class TagStore {
	tags: Map<TagId, TagInfo> = new Map<TagId, TagInfo>();
	tagNames: Map<string, TagId> = new Map<string, TagId>();
	selectedTags: Set<TagInfo> = new Set<TagInfo>();

	addTags(tags: TagInfo[]) {
		tags.forEach(tag => {
			this.tags.set(tag.id, tag);
			this.tagNames.set(tag.name, tag.id);
		});
	}

	setTags(tags: TagInfo[]) {
		const tagMap = new Map<string, TagInfo>();
		const tagNames = new Map<string, string>();
		tags.forEach(tag => {
			tagMap.set(tag.id, tag);
			tagNames.set(tag.name, tag.id);
		});
		this.tags = observable.map(tagMap);
		this.tagNames = observable.map(tagNames);
		this.selectedTags.clear();
	}

	selectTag(tag: TagInfo) {
		this.selectedTags.add(tag);
	}

	deselectTag(tag: TagInfo) {
		this.selectedTags.delete(tag);
	}

	public constructor() {
		makeObservable(this, {
			tags: observable,
			selectedTags: observable,
			addTags: action,
			setTags: action,
			selectTag: action.bound,
			deselectTag: action.bound,
		});
	}
}
