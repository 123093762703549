import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import MDEditor from '@uiw/react-md-editor';
import { useTranslation } from 'react-i18next';

import Icon from '@thingos/design-icons/web';

import { StoreContext } from '../stores';
import { DefaultRedirect } from './DefaultRedirect';
import { Template } from '../models/template';
import { Raised } from '../components/Raised';
import { Header } from '../components/Header';
import { PrivatePath } from '../pages';
import { Preview } from '../components/Preview';
import { filesize } from '../utils/filesize';
import { AsyncButton } from '../components/AsyncButton';
import { Templates } from '../models/templates';

const Page = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
});

const Body = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'row',
});

const Side = styled(Raised).attrs({ layer: 1 })({
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
	maxWidth: 333,
	padding: '48px',
});

const Center = styled.div({
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
	padding: '48px',
});

type Props = {
	template: Template;
	templates: Templates;
	title: string;
};
const TemplateInfo: React.FC<Props> = ({ templates, template, title }) => {
	const { t: common } = useTranslation('common');
	const { t } = useTranslation('templates');
	const history = useHistory();

	const onEdit = React.useCallback(() => {
		history.push(PrivatePath.editTemplate);
	}, [history]);

	const onNetworkCodes = React.useCallback(() => {
		history.push(PrivatePath.networkCodes);
	}, [history]);

	const onDownload = React.useCallback(async () => {
		templates.downloadConnectFile(template);
	}, [template, templates]);

	return (
		<Page>
			<Header
				title={title}
				backButton
				actionGroup={
					<div>
						<Grid spacing={1} container justify="flex-end">
							<Grid item>
								<Button fullWidth={false} color="default" variant="outlined" onClick={onEdit}>
									{common('actions.edit')}
								</Button>
							</Grid>
							<Grid item>
								<Button color="primary" variant="contained" onClick={onNetworkCodes}>
									{t('showNetworkCodes')}
								</Button>
							</Grid>
						</Grid>
					</div>
				}
			/>
			<Body>
				<Side>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<div
								style={{
									width: '100%',
									aspectRatio: '1',
									borderRadius: '8px',
									overflow: 'hidden',
								}}
							>
								<Preview url={template.imageUrl!} />
							</div>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2">
								<b>{t('info.company')}</b>
							</Typography>
							<Typography variant="body2">{template.vendor}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2">
								<b>{t('info.version')}</b>
							</Typography>
							<Typography variant="body2">{template.version}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2">
								<b>{t('info.lastUpdate')}</b>
							</Typography>
							<Typography variant="body2">{template.updatedAt?.toLocaleDateString()}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2">
								<b>{t('info.size')}</b>
							</Typography>
							<Typography variant="body2">{filesize(template.connectFileSize!)}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2">
								<b>{template.connectFileName}</b>
							</Typography>
							<AsyncButton onClick={onDownload} variant="outlined">
								<Typography variant="body2" style={{ marginRight: 8 }}>
									{t('info.download')}
								</Typography>
								<Icon kind="ui/cloudDownload" height="18" width="18" />
							</AsyncButton>
						</Grid>
					</Grid>
				</Side>
				<Center>
					<MDEditor.Markdown source={template.description} />
				</Center>
			</Body>
		</Page>
	);
};

export const TemplateInfoPage: React.FC = observer(function TemplateInfoPage() {
	const { companyStore } = useContext(StoreContext);
	const selectedTemplate = companyStore.selectedCompany?.templates?.selectedTemplate || null;
	if (companyStore.selectedCompany == null || selectedTemplate == null) {
		return <DefaultRedirect />;
	}
	return (
		<TemplateInfo
			template={companyStore.selectedCompany.templates.selectedTemplate!}
			templates={companyStore.selectedCompany.templates}
			title={selectedTemplate.name!}
		/>
	);
});
