import { StatusCodes } from 'http-status-codes';
import { action, makeObservable, observable, runInAction } from 'mobx';
import * as TE from 'fp-ts/lib/TaskEither';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { CheckInvitationResponse, RegistrationData } from '@thingos/m4i-webservice-shared';
import { api } from '../config';

export class RegistrationStore {
	invitationInfo: { info: CheckInvitationResponse; error: false } | { error: true } | null = null;

	public constructor() {
		makeObservable(this, {
			invitationInfo: observable,
			fetchInvitationInfo: action,
		});
	}

	public async fetchInvitationInfo(invitation: string): Promise<void> {
		this.invitationInfo = null;
		return pipe(
			TE.tryCatch(
				async () =>
					fetch(api + `/invitation/info/${invitation}`, {
						method: 'GET',
						mode: 'cors',
					}),
				() => 'Error' as const
			),
			TE.chain(response => {
				if (response.status === StatusCodes.OK) {
					return pipe(
						TE.tryCatch(
							() => response.json(),
							() => 'Error' as const
						),
						TE.chain(response =>
							pipe(
								CheckInvitationResponse.decode(response),
								E.mapLeft(() => 'Error' as const),
								TE.fromEither
							)
						)
					);
				}
				return TE.left('Error');
			}),
			TE.match(
				e =>
					runInAction(() => {
						console.log(e);
						this.invitationInfo = { error: true };
					}),
				invitationInfo =>
					runInAction(() => {
						this.invitationInfo = { info: invitationInfo, error: false };
					})
			)
		)();
	}

	public async register(registrationData: RegistrationData): Promise<boolean> {
		return pipe(
			TE.tryCatch(
				async () =>
					fetch(api + '/register', {
						method: 'POST',
						mode: 'cors',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(registrationData),
					}),
				() => 'Error' as const
			),
			TE.map(response => response.status === StatusCodes.OK),
			TE.match(
				() => false,
				s => s
			)
		)();
	}
}
