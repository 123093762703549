function SvgHiker(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width={395} height={329} viewBox="0 0 395 329" {...props}>
			<g fill="none" fillRule="evenodd" opacity={0.864}>
				<path
					fill="#F8F8F8"
					fillRule="nonzero"
					d="M294.74 42.34c-22.818-12.979-48.484-18.41-75.072-20.568l1.585 3.63-.342 4-1.284 2.947-3.574 2.284-2.501.834-2.302 5.739-1.722 8.202 11.287-.137 10.84.3 2.368 2.5.371 4.676-.504 13.425 2.977 3.4 2.864 4.135.771 2.663-1.296 3.205 3.23 4.01 9.077 12.64 10.203 14.517 7.042 10.952 2.126 2.789 5.166 2.917 1.784 3.663-.354 4.102-1.93 2.954-3.42 1.647-4.169-.417-3.08-2.371-2.052-5.664-11.461-11.478-17.128-18.934.175 10.419v16.1l-.175 7.085-2.055 2.617-1.898.859-6.541.112-53.488-8.46-1.593-.609a5.792 5.792 0 01-1.755-.666 1.36 1.36 0 01-.23-.192c-.237-.275-.504-1.05-.767-2.242-.575-2.618-1.134-7.244-1.338-12.983l-.088-.833.021-1.622c-.041-2.5 0-5.205.1-8.002l.038-2.917.304-2.447c.25-2.992.617-6.055 1.122-9.123v-.054c.446-2.688 1-5.38 1.667-8.023v-.029a67.292 67.292 0 014.116-11.67 29.313 29.313 0 01-5.42-3.025l-.067-.046c-.38-.267-.76-.538-1.126-.834l-3.219-2.338-2.334-2.713c-.45-.486-.868-.957-1.251-1.413a18.822 18.822 0 01-1.597-2.192l-.676-.98.071-.337a1.442 1.442 0 01-.058-.263 4.25 4.25 0 01.571-2.184l-7.505-9.044-4.903-8.594-.592-4.197 1.08-3.071 4.903-5.214 6.342-4.797c-29.973 1.509-57.29 6.81-72.605 23.485-19.633 21.393-19.35 61.704-22.326 98.471-2.977 36.768-9.281 70.064.283 101.235 7.818 25.652 26.434 49.959 49.136 60.37 35.926-24.857 104.44-61.345 169.995-86.556a560.378 560.378 0 013.544-3.143c26.871-23.735 62.731-53.347 67.038-85.726 4.265-32.442-23.019-67.722-56.398-86.776z"
				/>
				<path
					stroke="#000"
					strokeLinecap="round"
					strokeWidth={2.5}
					d="M189.853 26.065a19.316 19.316 0 00-.754 5.384c0 6.781 3.423 12.554 8.213 14.725l-.608 5.88m4.56 5.606c2.944.675 6.184.834 6.463-.117.417-1.492 2.744-12.57 3.553-16.45a17.413 17.413 0 002.176-5.393"
				/>
				<path
					fill="#000"
					fillRule="nonzero"
					d="M305.243 209c-3.481-1.193-14.872-16.255-14.872-34.13 0-20.747 21.994-60.078 24.078-74.095 1.33-8.906 7.33-87.372 52.892-87.372 24.845 0 26.917 48.558 26.917 70.276 0 50.734-73.797 93.95-73.797 120.302A112.806 112.806 0 01305.243 209z"
				/>
				<path
					stroke="#FFF"
					strokeLinecap="round"
					strokeWidth={4}
					d="M310.847 207.115c0-40.685 30.373-114.813 57.09-140.898"
				/>
				<path
					fill="#000"
					fillRule="nonzero"
					d="M135.452 288.599c-2.326-.692-8.651-8.752-8.651-19.138 0-10.003 2.81-24.59 2.81-38.123 0-7.527-2.81-10.194-2.81-19.15 0-14.275 13.534-18.622 18.278-18.622 6.984 0 10.72 5.972 10.72 9.136 0 4.413-1.251 7.764-1.251 12.649 0 9.548 3.335 10.744 2.985 18.971-1.042 24.052-11.032 47.567-19.813 52.902l-2.268 1.375z"
				/>
				<path
					fill="#000"
					fillRule="nonzero"
					d="M135.452 288.599c2.852-2.459-13.116-80.23-26.967-93.991-15.068-14.975-27.455-22.88-31.545-27.786-7.254-8.707-11.874-41.54-43.807-41.54-8.447 0-28.351 6.98-31.933 36.526-1.171 9.698-3.302 24.535 16.89 46.037 13.6 14.487 30.611 9.498 47.239 25.194 11.97 11.299 10.294 28.34 21.472 42.74 8.943 11.503 32.162 27.02 48.651 12.82z"
				/>
				<path
					stroke="#FFF"
					strokeLinecap="round"
					strokeWidth={4}
					d="M131.533 290.454c0-19.518-61.593-107.607-86.238-120.206m90.157 118.35c-.613-4.625-3.198-43.927 4.128-58.247"
				/>
				<path
					stroke="#000"
					strokeLinecap="round"
					strokeWidth={7}
					d="M86.805 324.704c18.428-26.31 183.087-116.076 282.004-132.655"
				/>
				<path
					stroke="#000"
					strokeLinecap="round"
					strokeWidth={2.5}
					d="M184.237 56.797v-2.625a5.002 5.002 0 015.004-5.001H197m37.24 55.73v32.2a5.002 5.002 0 01-5.003 5.002h-7.126M209.583 49.17h19.654a5.002 5.002 0 015.003 5v16.31m-45.704 223.57v10.836h-29.435v-4.584l18.236-8.373zm63.853-10.39c1.488 3.334 5.003 11.974 5.003 11.974l-14.688 9.232h-13.309l-1.56-5.102 16.72-12.79 7.834-3.314z"
				/>
				<path
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2.5}
					d="M192.98 6.168a3.479 3.479 0 00-.508-2.676c-1.626-2.242-6.217-1.717-10.253 1.188a14.106 14.106 0 00-3.819 4.03 1.118 1.118 0 00-.417-.096c-1.96 0-34.534 17.417-34.225 25.453.266 6.901 8.338 17.367 13.054 21.76"
				/>
				<path
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2.5}
					d="M171.788 42.844c-3.336-3.334-8.118-7.348-9.285-8.448 2.043-1.067 18.395-17.263 20.242-19.097a13.853 13.853 0 005.374-2.442c2.735-1.971 4.511-4.53 4.866-6.668"
				/>
				<path
					stroke="#000"
					strokeLinecap="round"
					strokeWidth={2.5}
					d="M182.874 57.785c-1.843-7.322-5.303-14.833-8.893-15.166-6.671-.617-18.27 11.92-17.803 15.479.308 2.359 7.876 10.836 15.697 14.078m36.753-18.554c3.018 1.796 5.528 4.768 7.592 8.527"
				/>
				<path
					stroke="#000"
					strokeLinecap="round"
					strokeWidth={2.5}
					d="M224.267 96.166c1.15 16.846-.613 32.508-3.194 34.53-5.837 4.584-51.49 4.793-54.41 1.458-2.805-3.205-9.268-74.569 30.02-80.1"
				/>
				<path
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2.5}
					d="M206.931 26.59c.959 1.596 1.993 3.075 3.056 3.434.064.02.127.044.188.07m-27.743-14.511a2.617 2.617 0 00-.567 1.58c0 3.8.1 8.902 7.997 8.902.525-1.826 2.501-5.835 4.753-7.62.417.176 1.45.614 2.706 1.126m17.036 15.558c1.78-.196 6.896-.942 6.896-8.994 0-3.171-3.032-5.46-3.032-7.218 0-1.759 1.039-4.91-1.284-7.164-2.822-2.739-7.321-1.597-11.349-1.597-3.785 0-5.874-4.397-11.04-4.397a2.815 2.815 0 00-1.563.417"
				/>
				<path
					stroke="#000"
					strokeLinecap="round"
					strokeWidth={2.5}
					d="M209.762 30.482a3.282 3.282 0 015.713 2.131 3.28 3.28 0 01-2.002 3.092"
				/>
				<path
					stroke="#000"
					strokeLinecap="round"
					strokeWidth={7}
					d="M267.615 142.699l-29.581 146.596m44.795-221.991l-12.17 60.32"
				/>
				<path
					stroke="#000"
					strokeLinecap="round"
					strokeWidth={2.5}
					d="M225.943 95.357c5.837 6.752 25.816 30.042 36.911 39.715a8.296 8.296 0 002.502 5.989c3.089 3.092 7.834 3.36 10.594.604 2.76-2.755 2.501-7.502-.6-10.594a8.502 8.502 0 00-4.587-2.397c-5.295-8.544-22.677-34.017-31.528-45.115"
				/>
				<path
					stroke="#000"
					strokeLinecap="round"
					strokeWidth={2.5}
					d="M212.247 63.891c10.786-8.015 28.568 11.365 28.276 16.463-.292 5.097-13.959 16.4-18.633 16.412-5.707.008-18.824-26.052-9.643-32.875z"
				/>
				<path
					fill="#000"
					fillRule="nonzero"
					d="M221.949 129.425a3.563 3.563 0 01-.876 1.27c-3.64 2.86-22.79 4.018-37.382 3.672 7.013 34.784 55.452 148.122 59.3 154.14 2.994.642 9.84-2.35 10.907-4.276 1.034-1.859-27.713-140.186-31.95-154.806z"
				/>
				<path
					fill="#000"
					fillRule="nonzero"
					d="M168.227 132.909c.121 11.986 2.19 147.992 5.837 160.12 3.336 1.667 12.091 2.084 15.01.834 2.639-1.13 18.124-130.605 21.059-160.492-13.771 1.58-35.681 1.35-41.906-.462z"
				/>
			</g>
		</svg>
	);
}

export default SvgHiker;
