import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { PrivatePath, Root } from './pages';
import { DefaultRedirect } from './pages/DefaultRedirect';
import { PublicPaths as publicPaths, Public } from './pages/public';
import { StoreContext, stores } from './stores';
import i18n from './i18n';
import { LoadingIndicator } from './components/LoadingIndicator';
import { DynamicThemeProvider } from './components/DynamicThemeProvider';
import { I18nextProvider } from 'react-i18next';

const AppRoot = styled.div({
	position: 'fixed',
	height: '100%',
	width: '100%',
	display: 'flex',
	flexDirection: 'row',
	backgroundColor: '#F5F5F5',
});

const publicPathArray = Object.values(publicPaths);
const privatePathArray = Object.values(PrivatePath);
const App: React.FC = () => {
	return (
		<AppRoot>
			<CssBaseline></CssBaseline>
			<StoreContext.Provider value={stores}>
				<DynamicThemeProvider>
					<I18nextProvider i18n={i18n}>
						<SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
							<Suspense fallback={<LoadingIndicator />}>
								<BrowserRouter>
									<div
										id="measure-layer"
										style={{
											display: 'inline-block',
											position: 'absolute',
											visibility: 'hidden',
											zIndex: -1,
										}}
									/>
									<Switch>
										<Route exact path="/">
											<DefaultRedirect />
										</Route>
										<Route path={publicPathArray}>
											<Public />
										</Route>
										<Route path={privatePathArray}>
											<Root />
										</Route>
									</Switch>
								</BrowserRouter>
							</Suspense>
						</SnackbarProvider>
					</I18nextProvider>
				</DynamicThemeProvider>
			</StoreContext.Provider>
		</AppRoot>
	);
};

export default App;
