import { Grid, styled, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { Fragment, useCallback, useContext, useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isEmail from 'validator/lib/isEmail';

import { TextField } from '../library';
import { StoreContext } from '../stores';
import { ColorPicker } from './ColorPicker';
import { ImageUpload } from './ImageUpload';
import { SubmitButton } from './SubmitButton';

const useStyles = makeStyles(theme => ({
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	progress: {
		width: '100%',
		position: 'absolute',
		borderTopLeftRadius: 2,
		borderTopRightRadius: 2,
		top: 0,
	},
}));

type NewCompanyData = {
	name: string;
	adminMail: string;
	highlightColor: string;
	image?: File;
};

const FormGrid = styled(Grid)({ justifyContent: 'center' });

type Props = {
	done: () => void;
};

export const NewCompanyForm: React.FC<Props> = ({ done }) => {
	const { t } = useTranslation(['companies', 'common']);
	const { companyStore, accountStore } = useContext(StoreContext);
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const onAdd = useCallback(
		async (companyData: NewCompanyData) => {
			if (
				await companyStore.addCompany(
					companyData.name,
					companyData.adminMail,
					companyData.highlightColor,
					companyData.image
				)
			) {
				done();
			} else {
				enqueueSnackbar(t('companies:newCompany.failure'), { variant: 'error' });
			}
		},
		[companyStore, done, enqueueSnackbar, t]
	);
	const formMethods = useForm<NewCompanyData>({
		mode: 'all',
		defaultValues: {
			adminMail: accountStore.userInfo?.email || '',
			highlightColor: '#D20A27',
		},
	});
	const { handleSubmit, control } = formMethods;
	const companyAdminEmailInputRef = useRef<HTMLInputElement>();

	return (
		<FormProvider {...formMethods}>
			<form className={classes.form} onSubmit={handleSubmit(onAdd)} style={{ padding: '16px' }}>
				<FormGrid container spacing={1}>
					<Grid item xs={12}>
						<Controller
							name="name"
							control={control}
							defaultValue=""
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<TextField
									label={t('companies:newCompany.companyNameLabel')}
									placeholder={t('companies:newCompany.companyNamePlaceholder')}
									fullWidth
									autoFocus
									value={value}
									onChange={onChange}
									error={!!error}
									helperText={error?.message || ''}
									nextInputOnEnter={companyAdminEmailInputRef}
								/>
							)}
							rules={{
								required: t('common:forms.required'),
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="adminMail"
							control={control}
							defaultValue=""
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<TextField
									label={t('companies:newCompany.companyAdminLabel')}
									placeholder={t('companies:newCompany.companyAdminPlaceholder')}
									fullWidth
									value={value}
									onChange={onChange}
									error={!!error}
									helperText={error?.message || ''}
									nextInputOnEnter={companyAdminEmailInputRef}
								/>
							)}
							rules={{
								validate: email => {
									if (!email) {
										return t('common:forms.required');
									} else if (!isEmail(email)) return t('common:forms.invalidMail');
									return undefined;
								},
							}}
						/>
					</Grid>
					<Grid item xs={6} alignItems="center">
						<Controller
							name="image"
							control={control}
							render={props => (
								<ImageUpload
									label={t('companies:newCompany.companyLogo')}
									style={{ padding: '8px' }}
									accept="image/svg+xml"
									{...props}
								/>
							)}
						/>
						<Typography variant="body2" color="textSecondary">
							{t('companies:newCompany.logoInstructions')}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Controller
							name="highlightColor"
							control={control}
							render={({ field: { value, onChange } }) => (
								<ColorPicker
									label={t('companies:newCompany.highlightColor')}
									value={value}
									onChange={onChange}
								/>
							)}
						/>
					</Grid>
				</FormGrid>
				<SubmitButton>
					<Fragment>{t('companies:newCompany.addButton')}</Fragment>
				</SubmitButton>
			</form>
		</FormProvider>
	);
};
