import styled from 'styled-components';

const Badge = styled.div(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	borderRadius: 50,
	width: 50,
	height: 50,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}));

function initialsFromParts(parts: string[]) {
	return parts.map(n => n.slice(0, 1)).join('');
}

function initialsFromName(name: string) {
	return initialsFromParts(name.split(' '));
}

function initialsFromEmail(email: string) {
	return initialsFromParts(email.split('@')[0]!.split('.'));
}

type Props = {
	name?: string;
	email: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const NameIcon: React.FC<Props> = ({ name, email, ...restProps }) => {
	const initials = name != null ? initialsFromName(name) : initialsFromEmail(email);
	return (
		<Badge {...restProps}>
			<p style={{ color: 'white', fontSize: '1.5em', textTransform: 'uppercase' }}>{initials}</p>
		</Badge>
	);
};
