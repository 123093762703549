export type IMeshNetwork = unknown;
export type PowerManagementSetting = unknown;

export type IDeviceType =
	| 'de.ledvance.socket'
	| 'de.nimbus.lighting.pad'
	| 'de.nimbus.lighting.pad.direct'
	| 'de.nimbus.lighting.pad.indirect'
	| 'de.nimbus.lighting.pad.sensor'
	| 'de.nimbus.leggera'
	| 'de.nimbus.leggera.switch'
	| 'de.nimbus.leggera.slider'
	| 'com.haefele.remote'
	| 'com.haefele.remote.v2'
	| 'com.haefele.remote.eco'
	| 'com.haefele.furniture.switch'
	| 'com.haefele.furniture.sensor.mains'
	| 'com.haefele.furniture.sensor.battery'
	| 'com.haefele.tvlift'
	| 'com.haefele.motor'
	| 'com.haefele.lift.wardrobe'
	| 'com.haefele.pushlock'
	| 'com.haefele.pushlock.5s'
	| 'com.haefele.led.rgb'
	| 'com.haefele.led.rgb.spot'
	| 'com.haefele.led.multiwhite.spot'
	| 'com.haefele.led.multiwhite.2200K'
	| 'com.haefele.led.multiwhite.2700K'
	| 'com.haefele.led.multiwhite.2wire.monochrome.spot'
	| 'com.haefele.led.multiwhite.2wire.monochrome.stripe'
	| 'com.haefele.led.multiwhite.2wire.mw.spot'
	| 'com.haefele.led.multiwhite.2wire.mw.stripe'
	| 'com.haefele.led.white'
	| 'com.haefele.led.white.strip'
	| 'com.haefele.meshbox.eco.2wire.4c'
	| 'com.haefele.meshbox.eco.2wire.4cs'
	| 'com.haefele.meshbox.eco.2wire.4csh'
	| 'com.haefele.meshbox.eco.monochrome.4c'
	| 'com.haefele.meshbox.eco.monochrome.4cs'
	| 'com.haefele.meshbox.eco.mw.1c'
	| 'com.haefele.meshbox.eco.mw.1cs'
	| 'com.haefele.meshbox.eco.rgb.1c'
	| 'com.haefele.meshbox.eco.rgb.1cs'
	| 'com.haefele.12v.blebox'
	| 'com.haefele.12v.blebox.v2'
	| 'com.haefele.12v.blebox.2wire'
	| 'com.haefele.24v.blebox'
	| 'com.haefele.24v.blebox.v2'
	| 'com.haefele.24v.blebox.2wire'
	| 'com.haefele.wallswitch'
	| 'com.haefele.wallswitch.us'
	| 'com.haefele.wallcontroller.actuator'
	| 'com.haefele.wallcontroller'
	| 'com.haefele.socket.actuator'
	| 'com.haefele.socket'
	| 'com.haefele.multisensor'
	| 'com.haefele.ir.dimmer'
	| 'com.haefele.ir.dimmer.modular'
	| 'com.haefele.ir.switch'
	| 'com.haefele.motion.sensor'
	| 'com.haefele.capacitive.dimmer'
	| 'com.haefele.door.sensor'
	| 'com.haefele.power.permanent'
	| 'com.haefele.switch.v0'
	| 'com.haefele.dimmer.v0'
	| 'com.haefele.switch'
	| 'com.haefele.dimmer'
	| 'com.haefele.motion'
	| 'com.haefele.enocean.switch.single'
	| 'com.haefele.enocean.switch.double'
	| 'com.haefele.q.dev.multiwhite'
	| 'com.haefele.q.dev.monochrome'
	| 'com.generic.led.multiwhite'
	| 'com.generic.led.white'
	| 'com.generic.led.rgb'
	| 'com.generic.button'
	| 'com.generic.level'
	| 'com.generic.ble.device'
	| 'com.nordic.devkit'
	| 'com.nordic.devkit.level'
	| 'com.nordic.devkit.button'
	| 'io.thingos.garmin.motionsensor'
	| 'io.thingos.meshthing'
	| 'de.fela.wallswitch.1button.battery'
	| 'de.fela.wallswitch.2button.battery'
	| 'de.fela.wallswitch.4button.battery'
	| 'de.fela.wallswitch.1button.mains'
	| 'de.fela.wallswitch.2button.mains'
	| 'de.fela.wallswitch.4button.mains'
	| 'io.thingos.meshthing.switch'
	| 'io.thingos.meshthing.motion'
	| 'io.thingos.meshthing.button'
	| 'io.thingos.meshthing.notification';
export type IModel = unknown;
export type ImageType = unknown;
export type IGroup = unknown;
export type IRoom = unknown;
export type IDeviceModule = unknown;
export type ICompositionData = unknown;
interface IConfigEntry {
	type: IDeviceType;
	ports: number[];
	configurable?: boolean;
}
export type IModelMap = unknown;
export type DeviceWallswitchBehaviour = unknown;
export type DeviceRemoteBehaviour = unknown;

export interface IDeviceConfigurationSlotDeviceType {
	type: IDeviceType;
	count: number;
	available: boolean;
	isAvailableForFirmware: boolean;
}

export interface IDeviceModelMap {
	[sigModelNumber: string]: IModel;
}

export interface IDevice {
	id: string;
	deviceKey: string | null;
	macBytes: string;
	bleAddress: string;
	unicastAddress: number | null; //
	type: IDeviceType; // type from deviceFormatter, will be parsed from name
	icon: ImageType;
	name: string; // name, changeable by user
	description: string; // manufacturer, currently used while provisioning
	roomId: string; // reference room group
	hardwareVersion: string; // hardware version
	powerManagementDefault: PowerManagementSetting; // power management
	switchingBehaviour: DeviceWallswitchBehaviour | DeviceRemoteBehaviour;

	// after provisioning
	serviceData: string | null;
	rssi: number;
	isProvisioned: boolean; // set after node is provisioned
	isNodeKeyBound: boolean; // set after appkeys are bound
	isModelKeyBound: boolean; // set after appkeys are bound
	compositionData: ICompositionData | null;
	virtual?: string | null;
	boxConfiguration?: IConfigEntry[];
	hasBatteryWarning: boolean;
	publish?: number;

	element?: number;
	model?: number;
	value: any;
	lastValue: any;
	models?: IModelMap;
	ports?: number[] | null;
	version?: string;
	bootloaderVersion?: string;
	modules?: IDeviceModule[];

	networkTransmitCount?: number;
	networkTransmitInterval?: number;
	relayCount?: number;
	relayInterval?: number;
}

export interface INetwork {
	id: string;
	name: string;
	location: string;
	version: number;

	applicationKey: string;
	presharedKey: string;
	networkKey: string;
	networkUnicastAddress: number;
	networkKeyIndex: number;
	networkIvIndex: number;
	networkFlags: number;
	networkGlobalTtl: number;
	multicastAddress: number;
	sceneNumber: number;
	nordic: IMeshNetwork;
	provisionerAddress: number;
	powerManagementDefault: PowerManagementSetting;

	defaultNetworkTransmissionCount: number;
	defaultNetworkTransmissionInterval: number;
	defaultRelayCount: number;
	defaultRelayInterval: number;

	// flags
	useHighSecurityDefault: boolean;
	shouldAllPublishStatusToGroups: boolean;
	shouldExtendDeviceInfo: boolean;

	devices: IDevice[];
	groups: IGroup[];
	rooms: IRoom[];
}

export function parseConnectFile(connectFileContent: string) {
	const network: INetwork = JSON.parse(connectFileContent);
	console.log(network.devices.filter(d => vendorMap[d.type] == null));
	return network.devices
		.filter(d => d.virtual == null)
		.sort(a => (vendorMap[a.type].needsAttention ? 1 : -1));
}

export function toDeviceInfo(device: IDevice): string {
	function portsDescription(ports: number[]): string {
		if (ports.length === 1) {
			return `${ports[0]}`;
		} else if (ports.length === 2) {
			return `${ports.join(' & ')}`;
		} else if (ports.length > 2) {
			const sorted = ports.sort();
			const last = sorted[sorted.length - 1]!;
			const first = sorted[0]!;
			if (last - first === sorted.length - 1) {
				return `${first} - ${last}`;
			} else {
				return `${ports.join(', ')}`;
			}
		} else {
			return '';
		}
	}
	function boxConfigEntry(configEntry: IConfigEntry): string {
		return `- ${portsDescription(configEntry.ports.map(p => p + 1))}: ${configEntry.type}`;
	}
	return `#### ${device.name}\n${(device.boxConfiguration || []).map(boxConfigEntry).join('\n')}`;
}

export const vendorMap: { [name in IDeviceType]: { needsAttention: boolean } } = {
	'com.haefele.remote': {
		needsAttention: true,
	},
	'com.haefele.remote.eco': {
		needsAttention: true,
	},
	'com.haefele.furniture.switch': {
		needsAttention: true,
	},
	'com.haefele.furniture.sensor.mains': {
		needsAttention: false,
	},
	'com.haefele.furniture.sensor.battery': {
		needsAttention: false,
	},
	'com.haefele.tvlift': {
		needsAttention: false,
	},
	'com.haefele.pushlock': {
		needsAttention: false,
	},
	'com.haefele.pushlock.5s': {
		needsAttention: false,
	},
	'com.haefele.motor': {
		needsAttention: false,
	},
	'com.haefele.lift.wardrobe': {
		needsAttention: false,
	},
	'com.haefele.led.rgb': {
		needsAttention: false,
	},
	'com.haefele.led.rgb.spot': {
		needsAttention: false,
	},
	'com.haefele.led.multiwhite.spot': {
		needsAttention: false,
	},
	'com.haefele.led.multiwhite.2wire.monochrome.spot': {
		needsAttention: false,
	},
	'com.haefele.led.multiwhite.2wire.monochrome.stripe': {
		needsAttention: false,
	},
	'com.haefele.led.multiwhite.2wire.mw.spot': {
		needsAttention: false,
	},
	'com.haefele.led.multiwhite.2wire.mw.stripe': {
		needsAttention: false,
	},
	'com.haefele.led.multiwhite.2200K': {
		needsAttention: false,
	},
	'com.haefele.led.multiwhite.2700K': {
		needsAttention: false,
	},
	'com.haefele.led.white': {
		needsAttention: false,
	},
	'com.haefele.led.white.strip': {
		needsAttention: false,
	},
	'com.haefele.meshbox.eco.2wire.4c': {
		needsAttention: false,
	},
	'com.haefele.meshbox.eco.2wire.4cs': {
		needsAttention: false,
	},
	'com.haefele.meshbox.eco.2wire.4csh': {
		needsAttention: false,
	},
	'com.haefele.meshbox.eco.monochrome.4c': {
		needsAttention: false,
	},
	'com.haefele.meshbox.eco.monochrome.4cs': {
		needsAttention: false,
	},
	'com.haefele.meshbox.eco.mw.1c': {
		needsAttention: false,
	},
	'com.haefele.meshbox.eco.mw.1cs': {
		needsAttention: false,
	},
	'com.haefele.meshbox.eco.rgb.1c': {
		needsAttention: false,
	},
	'com.haefele.meshbox.eco.rgb.1cs': {
		needsAttention: false,
	},
	'com.haefele.12v.blebox': {
		needsAttention: false,
	},
	'com.haefele.12v.blebox.v2': {
		needsAttention: false,
	},
	'com.haefele.12v.blebox.2wire': {
		needsAttention: false,
	},
	'com.haefele.24v.blebox.2wire': {
		needsAttention: false,
	},
	'com.haefele.24v.blebox': {
		needsAttention: false,
	},
	'com.haefele.24v.blebox.v2': {
		needsAttention: false,
	},
	'com.haefele.wallswitch': {
		needsAttention: true,
	},
	'com.haefele.wallswitch.us': {
		needsAttention: true,
	},
	'com.haefele.multisensor': {
		needsAttention: true,
	},
	'com.haefele.wallcontroller': {
		needsAttention: false,
	},
	'com.haefele.wallcontroller.actuator': {
		needsAttention: false,
	},
	'com.haefele.socket': {
		needsAttention: false,
	},
	'com.haefele.socket.actuator': {
		needsAttention: false,
	},
	'com.haefele.switch': {
		needsAttention: false,
	},
	'com.haefele.switch.v0': {
		needsAttention: false,
	},
	'com.haefele.dimmer': {
		needsAttention: false,
	},
	'com.haefele.dimmer.v0': {
		needsAttention: false,
	},
	'com.haefele.motion': {
		needsAttention: false,
	},
	'com.haefele.power.permanent': {
		needsAttention: false,
	},
	// DEPRECATED
	'com.haefele.ir.dimmer': {
		needsAttention: false,
	},
	// DEPRECATED
	'com.haefele.ir.dimmer.modular': {
		needsAttention: false,
	},
	// DEPRECATED
	'com.haefele.ir.switch': {
		needsAttention: false,
	},
	// DEPRECATED
	'com.haefele.motion.sensor': {
		needsAttention: false,
	},
	// DEPRECATED
	'com.haefele.capacitive.dimmer': {
		needsAttention: false,
	},
	// DEPRECATED
	'com.haefele.door.sensor': {
		needsAttention: false,
	},
	'com.nordic.devkit': {
		needsAttention: false,
	},
	'com.nordic.devkit.level': {
		needsAttention: false,
	},
	'com.nordic.devkit.button': {
		needsAttention: false,
	},
	'com.generic.led.white': {
		needsAttention: false,
	},
	'com.generic.led.multiwhite': {
		needsAttention: false,
	},
	'com.generic.led.rgb': {
		needsAttention: false,
	},
	'com.generic.button': {
		needsAttention: false,
	},
	'com.generic.level': {
		needsAttention: false,
	},
	'com.generic.ble.device': {
		needsAttention: false,
	},
	'io.thingos.garmin.motionsensor': {
		needsAttention: false,
	},
	'io.thingos.meshthing': {
		needsAttention: false,
	},
	'io.thingos.meshthing.notification': {
		needsAttention: false,
	},
	'io.thingos.meshthing.switch': {
		needsAttention: false,
	},
	'io.thingos.meshthing.motion': {
		needsAttention: false,
	},
	'io.thingos.meshthing.button': {
		needsAttention: true,
	},
	'de.nimbus.leggera': {
		needsAttention: false,
	},
	'de.nimbus.leggera.switch': {
		needsAttention: false,
	},
	'de.nimbus.leggera.slider': {
		needsAttention: false,
	},
	'de.nimbus.lighting.pad': {
		needsAttention: false,
	},
	'de.nimbus.lighting.pad.sensor': {
		needsAttention: false,
	},
	'de.nimbus.lighting.pad.direct': {
		needsAttention: false,
	},
	'de.nimbus.lighting.pad.indirect': {
		needsAttention: false,
	},
	'de.ledvance.socket': {
		needsAttention: false,
	},
	'com.haefele.remote.v2': {
		needsAttention: true,
	},
	'com.haefele.enocean.switch.single': {
		needsAttention: false,
	},
	'com.haefele.enocean.switch.double': {
		needsAttention: false,
	},
	'com.haefele.q.dev.multiwhite': {
		needsAttention: false,
	},
	'com.haefele.q.dev.monochrome': {
		needsAttention: false,
	},
	'de.fela.wallswitch.1button.battery': {
		needsAttention: true,
	},
	'de.fela.wallswitch.2button.battery': {
		needsAttention: true,
	},
	'de.fela.wallswitch.4button.battery': {
		needsAttention: true,
	},
	'de.fela.wallswitch.1button.mains': {
		needsAttention: false,
	},
	'de.fela.wallswitch.2button.mains': {
		needsAttention: false,
	},
	'de.fela.wallswitch.4button.mains': {
		needsAttention: false,
	},
};
