import { StatusCodes } from 'http-status-codes';
import { PathReporter } from 'io-ts/lib/PathReporter';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/lib/TaskEither';
import * as E from 'fp-ts/lib/Either';

import {
	AddUserToCompanyRequest,
	CompanyAdminInfo,
	UpdateCompanyRequest,
	DeleteCompanyRequest,
	GetCompaniesResponse,
	PostCompanyRequest,
	UserInfo,
	CompanyLogoUploadResponse,
	TagInfo,
} from '@thingos/m4i-webservice-shared';

import { Company, NewUserData } from '../models/company';
import { User, UserData } from '../models/user';
import { Role, UserRole } from '../models/userRole';
import { api } from '../config';
import { CompanyEdit } from '../components/EditCompanyForm';
import { ThemeStore } from './themeStore';
import { TagStore } from './tagStore';

export type NewUser = {
	email: string;
	name: string;
};

export type ExistingUser = {
	email: string;
	id: string;
};

export class CompanyStore {
	companies: Map<string, Company>;
	users: Map<string, User>;
	roles: Map<string, UserRole>;
	selectedCompany: Company | null;
	loaded: boolean;

	get isEmpty(): boolean {
		return this.companies.size === 0;
	}

	selectCompany(company: Company | null) {
		if (company !== this.selectedCompany) {
			this.selectedCompany = company;
			this.themeStore.primaryColor = company?.highlightColor || '#D20A27';
			if (company != null) {
				this.tagStore.setTags(company.tags);
			}
		}
	}

	selectById(companyId: string): boolean {
		const selectedCompany = this.companies.get(companyId);
		if (selectedCompany) {
			this.selectCompany(selectedCompany);
			return true;
		}
		return false;
	}

	addTagToCompany(tag: TagInfo) {
		this.selectedCompany?.addTag(tag);

		if (!this.tagStore.tags.has(tag.id)) {
			this.tagStore.addTags([tag]);
		}
	}

	constructor(private themeStore: ThemeStore, private tagStore: TagStore) {
		this.companies = observable.map();
		this.users = observable.map();
		this.roles = observable.map();
		this.selectedCompany = null;
		this.loaded = false;
		makeObservable(this, {
			companies: observable,
			selectedCompany: observable,
			loaded: observable,
			isEmpty: computed,
			addCompany: action,
			selectCompany: action,
			selectById: action,
			getOrSetUser: action,
		});
	}

	public async addCompany(
		name: string,
		adminMail: string,
		highlightColor: string,
		image?: File
	): Promise<boolean> {
		return pipe(
			TE.tryCatch(
				async () =>
					fetch(api + '/companies', {
						method: 'POST',
						credentials: 'include',
						mode: 'cors',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(PostCompanyRequest.encode({ name, adminMail, highlightColor })),
					}),
				() => 'Failed to post company'
			),
			TE.chain(response => {
				console.log(response);
				if (response.status === StatusCodes.OK) {
					return pipe(
						TE.tryCatch(
							() => response.json(),
							() => 'Could not get json body of response'
						),
						TE.chain(response =>
							pipe(
								CompanyAdminInfo.decode(response),
								E.mapLeft(() => 'Could not decode response'),
								TE.fromEither
							)
						)
					);
				}
				return TE.left('Error');
			}),
			TE.chain(response => {
				if (image == null) {
					return TE.right(response);
				}
				return pipe(
					this.uploadLogo(response.id, image),
					TE.map(({ companyLogo }) => ({ ...response, companyLogo }))
				);
			}),
			TE.match(
				e => {
					console.log(e);
					return false;
				},
				companyData => {
					runInAction(() => {
						this.companies.set(companyData.id, this.fromCompanyData(companyData));
					});
					return true;
				}
			)
		)();
	}

	public async loadCompanies(): Promise<number> {
		return pipe(
			TE.tryCatch(
				() =>
					fetch(api + '/companies', {
						method: 'GET',
						credentials: 'include',
						mode: 'cors',
					}),
				() => 'Failed to fetch companies'
			),
			TE.chain(response => {
				if (response.status === StatusCodes.OK) {
					return pipe(
						TE.tryCatch(
							() => response.json(),
							() => 'Failed to parse json'
						),
						TE.chainW(json =>
							pipe(
								GetCompaniesResponse.decode(json),
								E.mapLeft(e => PathReporter.report(E.left(e)).join('')),
								TE.fromEither
							)
						)
					);
				} else {
					console.log(`failed to fetch companies ${response}`);
					return TE.left('Failed to fetch companies');
				}
			}),
			TE.match(
				error => {
					console.error(`Failed to get companies ${error}`);
					return 0;
				},
				companiesData => {
					//companiesData.forEach(company => this.tagStore.addTags(company.tags));
					const companyArray = companiesData.map(company => {
						if (company.isCompanyAdmin) {
							return this.fromCompanyData(company);
						} else {
							return new Company(company);
						}
					});
					runInAction(() => {
						this.companies = observable.map(companyArray.map(company => [company.id, company]));
						if (this.companies.size === 1) {
							this.selectedCompany = this.companies.values().next().value;
						}
						this.loaded = true;
					});
					return companyArray.length;
				}
			)
		)();
	}

	public async deleteCompany(company: Company, password: string): Promise<boolean> {
		const deleteCompanyRequest = DeleteCompanyRequest.encode({
			companyId: company.id,
			password,
		});
		return pipe(
			TE.tryCatch(
				() =>
					fetch(api + '/company', {
						method: 'DELETE',
						credentials: 'include',
						mode: 'cors',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(deleteCompanyRequest),
					}),
				() => 'Failed to issue delete'
			),
			TE.chainW(response => {
				if (response.status === StatusCodes.OK) {
					return TE.right(true);
				}
				return TE.left('Status code indicated failure');
			}),
			TE.match(
				error => {
					console.error(`Failed to dlete the company: ${error}`);
					return false;
				},
				() => {
					runInAction(() => {
						this.companies.delete(company.id);
					});
					return true;
				}
			)
		)();
	}

	public async updateCompany(company: Company, updateCompanyData: CompanyEdit): Promise<boolean> {
		const updateCompanyRequest = UpdateCompanyRequest.encode({
			companyId: company.id,
			...updateCompanyData,
		});
		return pipe(
			TE.tryCatch(
				() =>
					fetch(api + '/company', {
						method: 'POST',
						credentials: 'include',
						mode: 'cors',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(updateCompanyRequest),
					}),
				() => 'Failed to post update'
			),
			TE.chainW(response => {
				if (response.status === StatusCodes.OK) {
					return TE.right(true);
				}
				return TE.left('Status code indicated failure');
			}),
			TE.chain(() => {
				if (updateCompanyData.image != null) {
					return this.uploadLogo(company.id, updateCompanyData.image);
				}
				return TE.right({});
			}),
			TE.match(
				error => {
					console.error(`Failed to update the company: ${error}`);
					return false;
				},
				companyLogoUploadResponse => {
					runInAction(() => {
						company.name = updateCompanyData.name;
						company.highlightColor = updateCompanyData.highlightColor;
						if ((companyLogoUploadResponse as CompanyLogoUploadResponse).companyLogo != null) {
							company.companyLogo = (companyLogoUploadResponse as CompanyLogoUploadResponse).companyLogo;
						}
					});
					return true;
				}
			)
		)();
	}

	uploadLogo(companyId: string, image: File): TE.TaskEither<string, CompanyLogoUploadResponse> {
		const formData = new FormData();
		formData.set('file', image);
		formData.set('companyId', companyId);
		return pipe(
			TE.tryCatch(
				() =>
					fetch(api + `/company/logo`, {
						credentials: 'include',
						mode: 'cors',
						method: 'POST',
						body: formData,
					}),
				() => 'Failed to upload company logo'
			),
			TE.chain(response => {
				if (response.status === StatusCodes.OK) {
					return pipe(
						TE.tryCatch(
							() => response.json(),
							() => 'Failed to get response json'
						),
						TE.chain(json =>
							pipe(
								CompanyLogoUploadResponse.decode(json),
								E.mapLeft(() => 'Failed to decode image upload response'),
								TE.fromEither
							)
						)
					);
				} else return TE.left('Unexpected image upload response');
			})
		);
	}

	async addUserToCompany(company: Company, { email, role }: NewUserData): Promise<boolean> {
		const body = JSON.stringify(
			AddUserToCompanyRequest.encode({
				companyId: company.id,
				email,
				admin: role === Role.CompanyAdmin,
			})
		);
		return pipe(
			TE.tryCatch(
				() =>
					fetch(api + '/company/user', {
						method: 'POST',
						credentials: 'include',
						mode: 'cors',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body,
					}),
				() => 'Failed to add user to company'
			),
			TE.chainW(response => {
				if (response.status === StatusCodes.OK) {
					return pipe(
						TE.tryCatch(
							() => response.json(),
							() => 'Failed to parse json'
						),
						TE.chainW(json => {
							console.error(`Got json: ${json}`);
							return pipe(
								UserInfo.decode(json),
								E.mapLeft(e => PathReporter.report(E.left(e)).join('')),
								TE.fromEither
							);
						})
					);
				} else {
					return TE.left(`Failed request with ${response.status}`);
				}
			}),
			TE.match(
				error => {
					console.error(`Failed to add user to companies ${error}`);
					return false;
				},
				userData => {
					runInAction(() => {
						company.addUserRole(userData.isAdmin, this.getOrSetUser(userData));
					});
					return true;
				}
			)
		)();
	}

	public getOrSetUser(userData: UserData): User {
		const u = this.users.get(userData.id);
		if (u != null) return u;
		const newUser = new User(userData);
		runInAction(() => {
			this.users.set(userData.id, newUser);
		});
		return newUser;
	}

	private fromCompanyData(company: CompanyAdminInfo): Company {
		const companyUsers = company.users.map(userData => {
			return { isAdmin: userData.isAdmin, user: this.getOrSetUser(userData) };
		});
		return new Company({
			id: company.id,
			highlightColor: company.highlightColor,
			companyLogo: company.companyLogo,
			name: company.name,
			isCompanyAdmin: true,
			users: companyUsers,
			templates: company.templates,
			tags: company.tags,
		});
	}
}
