import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import { Fragment, useCallback, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { Role, UserRole } from '../models/userRole';
import { RemoveButton } from './RemoveButton';

const useStyles = makeStyles(theme => ({
	submit: {
		margin: theme.spacing(1, 0, 1),
	},
	form: {
		marginTop: theme.spacing(1),
	},
	progress: {
		width: '100%',
		position: 'absolute',
		borderTopLeftRadius: 2,
		borderTopRightRadius: 2,
		top: 0,
	},
}));

type UserEdit = {
	role: Role;
};

type Props = {
	done: () => void;
	userRole: UserRole;
};

export const EditUserForm: React.FC<Props> = observer(function EditUserForm({ userRole, done }) {
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();

	const onAdd = useCallback(
		async (userData: UserEdit) => {
			if (await userRole.changeRole(userData.role)) {
				done();
			} else {
				enqueueSnackbar('Failed to change user', { variant: 'error' });
			}
		},
		[userRole, done, enqueueSnackbar]
	);
	const [removing, setRemoving] = useState(false);
	const onRemove = useCallback(async () => {
		setRemoving(true);
		if (await userRole.deleteRole()) {
			done();
		} else {
			enqueueSnackbar('Failed to delete user', { variant: 'error' });
		}
		setRemoving(false);
	}, [userRole, done, enqueueSnackbar, setRemoving]);
	const { handleSubmit, control, formState } = useForm<UserEdit>({
		mode: 'all',
		defaultValues: { role: userRole.role },
	});

	return (
		<Fragment>
			<form className={classes.form} onSubmit={handleSubmit(onAdd)} style={{ padding: '16px' }}>
				<Grid container direction="column" spacing={1}>
					<Grid item xs={12}>
						<Controller
							name="role"
							control={control}
							render={({ field: { onChange, value } }) => (
								<FormControl fullWidth>
									<InputLabel id="role-select-label">Role</InputLabel>
									<Select
										labelId="role-select-label"
										id="role-select"
										fullWidth
										value={value}
										onChange={onChange}
									>
										<MenuItem value={Role.CompanyAdmin}>Company Admin</MenuItem>
										<MenuItem value={Role.TemplateCreator}>Template Creator</MenuItem>
									</Select>
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item>
						<Grid container direction="row" spacing={1}>
							<Grid item>
								<RemoveButton variant="contained" onClick={onRemove} inProgress={removing} />
							</Grid>
							<Grid item>
								<Button
									type="submit"
									disabled={!formState.isValid || formState.isSubmitting || removing}
									variant="contained"
									color="primary"
								>
									<Fragment>
										{formState.isSubmitting && <LinearProgress className={classes.progress} />}
										Save
									</Fragment>
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</Fragment>
	);
});
