import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { Fragment, useCallback, useContext, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

import { PasswordField, TextField } from '../../library';
import { SubmitButton } from '../../components/SubmitButton';
import { StoreContext } from '../../stores';
import { PublicPaths } from '.';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	progress: {
		width: '100%',
		position: 'absolute',
		borderTopLeftRadius: 2,
		borderTopRightRadius: 2,
		top: 0,
	},
}));

type PasswordResetFormData = {
	password: string;
	passwordRepeat: string;
};

const PasswordResetForm: React.FC<{ email: string; token: string }> = ({ email, token }) => {
	const { t } = useTranslation('public');
	const { t: common } = useTranslation('common');
	const { passwordResetStore, accountStore } = useContext(StoreContext);
	const classes = useStyles();

	const passwordRepeatInputRef = useRef<HTMLInputElement>();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const register = useCallback(
		async (passwordResetFormData: PasswordResetFormData) => {
			if (
				await passwordResetStore.resetPassword({ token, password: passwordResetFormData.password })
			) {
				accountStore
					.login({ email, password: passwordResetFormData.password, remember: false })
					.then(result => {
						if (!result.success) {
							enqueueSnackbar(result.message, { variant: 'error' });
						} else {
							history.push(result.redirect);
						}
					});
			} else {
				enqueueSnackbar(t('passwordReset.failure'));
			}
		},
		[passwordResetStore, history, enqueueSnackbar, token, accountStore, email, t]
	);

	const formContext = useForm<PasswordResetFormData>({
		mode: 'all',
	});
	const { handleSubmit, control, watch } = formContext;

	return (
		<FormProvider {...formContext}>
			<form className={classes.form} onSubmit={handleSubmit(register)}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<TextField
							label={common('forms.emailLabel')}
							placeholder={common('forms.emailPlaceholder')}
							fullWidth
							disabled
							value={email}
							autoComplete="email"
							name="username"
						/>
					</Grid>
					<Grid item xs={12} style={{ position: 'relative' }}>
						<Controller
							name="password"
							control={control}
							defaultValue=""
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<PasswordField
									label={t('passwordReset.newPassword')}
									placeholder={t('passwordReset.newPassword')}
									fullWidth
									value={value}
									onChange={onChange}
									error={!!error}
									helperText={error?.message || ''}
									nextInputOnEnter={passwordRepeatInputRef}
								/>
							)}
							rules={{
								required: common('forms.required'),
							}}
						/>
					</Grid>
					<Grid item xs={12} style={{ position: 'relative' }}>
						<Controller
							name="passwordRepeat"
							control={control}
							defaultValue=""
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<PasswordField
									label={t('passwordReset.repeatNewPassword')}
									inputRef={ref => (passwordRepeatInputRef.current = ref)}
									placeholder={t('passwordReset.repeatNewPassword')}
									fullWidth
									value={value}
									onChange={onChange}
									error={!!error}
									helperText={error?.message || ''}
								/>
							)}
							rules={{
								validate: value =>
									value !== watch('password') ? t('passwordReset.mustMatch') : undefined,
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<SubmitButton>{t('passwordReset.change')}</SubmitButton>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	);
};

export const PasswordReset: React.FC = observer(function PasswordReset() {
	const { passwordResetToken } = useParams<{ passwordResetToken: string }>();
	const { passwordResetStore } = useContext(StoreContext);
	const { t } = useTranslation('public');

	useEffect(() => {
		passwordResetStore.fetchPasswordResetInfo(passwordResetToken);
	}, [passwordResetToken, passwordResetStore]);

	return passwordResetStore.passwordResetInfo == null ? (
		<Fragment>
			<CircularProgress />
			<Typography>{t('passwordReset.checking')}</Typography>
		</Fragment>
	) : (
		<Fragment>
			<Typography variant="h6">{t('passwordReset.title')}</Typography>
			{passwordResetStore.passwordResetInfo.error ? (
				<Typography>{t('passwordReset.invalid')}</Typography>
			) : passwordResetStore.passwordResetInfo.info.expired ? (
				<Fragment>
					<Typography>{t('passwordReset.expired')}</Typography>
					<Button href={PublicPaths.requestPasswordReset}>{t('passwordReset.requestNew')}</Button>
				</Fragment>
			) : (
				<PasswordResetForm
					email={passwordResetStore.passwordResetInfo.info.email}
					token={passwordResetToken}
				/>
			)}
		</Fragment>
	);
});
