import { Button, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Cell, Column, useFlexLayout, useTable } from 'react-table';

import { TableHeaderCell, TableRowCell, TagList } from '@thingos/thingos-components';
import { TagId, TagInfo } from '@thingos/m4i-webservice-shared';

import { Header } from '../components/Header';
import { PrivatePath } from './Root';
import { Template } from '../models/template';
import { Preview } from '../components/Preview';
import { Templates } from '../models/templates';
import { TableHeader, TableBody } from './Companies';
import { Empty } from '../components/Empty';
import { Group } from './Users';
import { TableRow } from '../components/TableRow';
import { WithCompany } from '../components/WithCompany';
import { useTranslation } from 'react-i18next';
import { DefaultCellRenderer } from '../components/TableCellRenderer';
import { Tags } from '../components/Tags';
import { StoreContext } from '../stores';

const Page = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
});

const ImagePreview: React.FC<{ cell: Cell<Template, string> }> = ({ cell }): JSX.Element => (
	<div
		style={{
			position: 'absolute',
			height: '100%',
			maxWidth: 64,
			aspectRatio: '1',
		}}
	>
		<Preview url={cell.value} />
	</div>
);

const TagsCell: React.FC<{ cell: Cell<Template, Set<TagId>> }> = observer(function TagCell({
	cell,
}) {
	const { tagStore } = useContext(StoreContext);
	const tags = Array.from(cell.value)
		.map(id => tagStore.tags.get(id))
		.filter(v => v !== undefined) as TagInfo[];
	return (
		<Tags tags={tags} template={cell.row.original} style={{ paddingLeft: 2, flexWrap: 'nowrap' }} />
	);
});

function Fallback(fallback: string): React.FC<{ cell: Cell<Template, string> }> {
	return ({ cell }): JSX.Element =>
		cell.value == null ? <i> {fallback}</i> : <div>{cell.value}</div>;
}

function filterByTags(templates: Template[], tags: Array<TagInfo>): Template[] {
	return tags.length === 0
		? templates
		: templates.filter(template => {
				for (const tag of tags) {
					if (!template.tags.has(tag.id)) return false;
				}
				return true;
		  });
}

export const DraftActions: React.FC<{ cell: Cell<Template, string>; templates: Templates }> = ({
	cell,
	templates,
}): JSX.Element => {
	const { t } = useTranslation('common');
	const history = useHistory();
	const edit = useCallback(() => {
		templates.selectTemplate(cell.row.original);
		history.push(PrivatePath.editTemplate);
	}, [templates, history, cell.row.original]);
	return (
		<Grid container direction="row" justify="flex-end">
			<Grid item>
				<Button variant="contained" color="primary" onClick={edit}>
					{t('actions.edit')}
				</Button>
			</Grid>
		</Grid>
	);
};

export const TemplateActions: React.FC<{ cell: Cell<Template, string>; templates: Templates }> = ({
	cell,
	templates,
}): JSX.Element => {
	const { t } = useTranslation('templates');
	const history = useHistory();
	const onView = useCallback(() => {
		templates.selectTemplate(cell.row.original);
		history.push(PrivatePath.template);
	}, [templates, cell.row.original, history]);
	const onNetworkCodes = useCallback(() => {
		templates.selectTemplate(cell.row.original);
		history.push(PrivatePath.networkCodes);
	}, [templates, cell.row.original, history]);
	return (
		<Grid container direction="row" justify="flex-end" spacing={1}>
			<Grid item>
				<Button variant="outlined" color="default" onClick={onNetworkCodes}>
					{t('showNetworkCodes')}
				</Button>
			</Grid>
			<Grid item>
				<Button variant="contained" color="primary" onClick={onView}>
					{t('view')}
				</Button>
			</Grid>
		</Grid>
	);
};

type Props = {
	templates: Templates;
};
export const TemplatesView: React.FC<Props> = observer(function TemplatesView({ templates }) {
	const history = useHistory();
	const { t } = useTranslation('templates');
	const { tagStore } = useContext(StoreContext);

	const draftColumns = useMemo<Column<Template>[]>(
		() => [
			{
				Header: '',
				id: 'preview',
				Cell: ImagePreview,
				accessor: 'imageUrl',
				width: 80,
				canResize: false,
			},
			{
				Header: t('nameHeader'),
				accessor: 'name',
				Cell: Fallback(t('edit.unnamed')),
				width: 250,
				canResize: false,
			},
			{
				Header: t('tagsHeader'),
				Cell: TagsCell,
				accessor: 'tags',
			},
			{
				Header: '',
				id: 'actions',
				Cell: DraftActions,
				accessor: 'name',
				width: 300,
				canResize: false,
			},
		],
		[t]
	);

	const templateColumns = useMemo<Column<Template>[]>(
		() => [
			...draftColumns.slice(0, -1),
			{
				...draftColumns[draftColumns.length - 1],
				id: 'actions',
				Cell: TemplateActions,
			},
		],
		[draftColumns]
	);

	const filteredDrafts = filterByTags(
		Array.from(templates.drafts.values()),
		Array.from(tagStore.selectedTags.values())
	);
	const {
		getTableProps,
		headerGroups,
		rows: draftRows,
		prepareRow: prepareDraftRow,
	} = useTable(
		{
			defaultColumn: {
				Cell: DefaultCellRenderer,
			},
			columns: draftColumns,
			data: filteredDrafts,
		},
		useFlexLayout
	);
	const filteredTemplates = filterByTags(
		Array.from(templates.templates.values()),
		Array.from(tagStore.selectedTags.values())
	);

	const { rows: templateRows, prepareRow: prepareTemplateRow } = useTable(
		{
			defaultColumn: {
				Cell: DefaultCellRenderer,
			},
			columns: templateColumns,
			data: filteredTemplates,
		},
		useFlexLayout
	);

	const addTemplate = useCallback(() => {
		history.push(PrivatePath.newTemplate);
	}, [history]);
	return (
		<Page {...getTableProps()}>
			<Header
				title={t('title')}
				actionGroup={
					<Button variant="contained" color="primary" onClick={addTemplate}>
						{t('add')}
					</Button>
				}
				subHeader={
					templates.isEmpty ? undefined : (
						<TableHeader>
							{headerGroups.map((headerGroup, index) => (
								<div
									{...(headerGroup.getHeaderGroupProps({
										// style: { paddingRight: '15px' },
									}) as any)}
									className="tr"
									key={index}
								>
									{headerGroup.headers.map((column, _index) => {
										return (
											<TableHeaderCell {...(column.getHeaderProps() as any)} key={column.id}>
												{column.render('Header')}
											</TableHeaderCell>
										);
									})}
								</div>
							))}
						</TableHeader>
					)
				}
			/>
			{templates.isEmpty ? (
				<Empty text={t('noTemplates')} />
			) : (
				<TableBody>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<TagList
								tags={Array.from(tagStore.selectedTags.values())}
								onDelete={tag => tagStore.deselectTag(tag)}
							/>
						</Grid>
						<Group name={t('drafts')} />
						<Grid item xs={12}>
							{draftRows.length === 0 ? (
								<Typography variant="body2">
									<i>{templates.drafts.size === 0 ? t('noDrafts') : t('draftsFiltered')}</i>
								</Typography>
							) : (
								draftRows.map(row => {
									prepareDraftRow(row);
									return (
										<TableRow
											{...(row.getRowProps() as any)}
											key={row.id}
											style={{
												paddingLeft: 0,
											}}
										>
											{row.cells.map((cell, index) => (
												<TableRowCell {...(cell.getCellProps() as any)} key={index}>
													{cell.render('Cell', { templates })}
												</TableRowCell>
											))}
										</TableRow>
									);
								})
							)}
						</Grid>
						<Group name={t('templates')} />
						<Grid item xs={12}>
							{templateRows.length === 0 ? (
								<Typography variant="body2">
									<i>
										{templates.templates.size === 0 ? t('noTemplates') : t('templatesFiltered')}
									</i>
								</Typography>
							) : (
								templateRows.map(row => {
									prepareTemplateRow(row);
									return (
										<TableRow
											{...(row.getRowProps() as any)}
											key={row.id}
											style={{ paddingLeft: 0 }}
										>
											{row.cells.map((cell, index) => (
												<TableRowCell {...(cell.getCellProps() as any)} key={index}>
													{cell.render('Cell', { templates })}
												</TableRowCell>
											))}
										</TableRow>
									);
								})
							)}
						</Grid>
					</Grid>
				</TableBody>
			)}
		</Page>
	);
});

export const TemplatesPage: React.FC = () => {
	return (
		<WithCompany companyConsumer={company => <TemplatesView templates={company.templates} />} />
	);
};
