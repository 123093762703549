import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import MDEditor, { commands } from '@uiw/react-md-editor';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { ConnectFileUpload } from '../components/ConnectFileUpload';
import { ImageUpload } from '../components';
import { Header } from '../components/Header';
import { Raised } from '../components/Raised';
import { TextField } from '../library';
import { Template } from '../models/template';
import { Templates } from '../models/templates';
import { SubmitButton } from './SubmitButton';
import { DeleteTemplateDialog } from './DeleteTemplateDialog';
import { useTranslation } from 'react-i18next';
import { isBlank, parseConnectFile, toDeviceInfo } from '../utils';
import { AsyncButton } from './AsyncButton';
import { PublishDialog } from './PublishDialog';
import { RemoveButton } from './RemoveButton';

const Page = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
});

const Body = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'row',
	overflow: 'hidden',
});

const Side = styled(Raised).attrs({ layer: 1 })({
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
	maxWidth: 333,
	padding: '48px',
});

const Center = styled.div({
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
	overflow: 'auto',
	padding: '48px',
});

export type TemplateFormData = {
	name?: string;
	vendor?: string;
	version?: string;
	image?: File;
	reference?: string;
	description?: string;
	connectFile?: File;
};

type Props = {
	templates: Templates;
	template: Template;
	title: string;
	allowDraft?: boolean;
};
export const TemplateEditor: React.FC<Props> = ({
	templates,
	template,
	title,
	allowDraft = false,
}) => {
	const { t } = useTranslation('templates');
	const { t: common } = useTranslation('common');
	const history = useHistory();
	const companyInputRef = React.useRef<HTMLInputElement>();
	const versionInputRef = React.useRef<HTMLInputElement>();
	const referenceInputRef = React.useRef<HTMLInputElement>();
	const { enqueueSnackbar } = useSnackbar();

	const [deleteDialogIsOpen, setDeleteDialogState] = useState(false);
	const onHandleDialogOk = React.useCallback(async () => {
		setDeleteDialogState(false);
		if (await templates.delete(template)) {
			enqueueSnackbar(t('edit.removed'), { variant: 'success' });
			history.push(`/${templates.companyId}/templates`);
		} else {
			enqueueSnackbar(t('edit.removeFailed'), { variant: 'error' });
		}
	}, [enqueueSnackbar, templates, template, history, t]);

	const onHandleDialogCancel = React.useCallback(() => {
		setDeleteDialogState(false);
	}, [setDeleteDialogState]);

	const formMethods = useForm<TemplateFormData>({
		mode: 'all',
		defaultValues: {
			name: template.name,
			vendor: template.vendor,
			version: template.version,
			reference: template.reference,
			description: template.description,
		},
	});

	const { handleSubmit, control, getValues, setValue, watch, trigger } = formMethods;
	React.useEffect(() => {
		trigger();
	}, [trigger]);

	const connectFile = watch('connectFile');
	const description = watch('description');
	useEffect(() => {
		if (connectFile != null) {
			connectFile
				.text()
				.then(parseConnectFile)
				.then(templateDevices => {
					const deviceList = templateDevices.map(toDeviceInfo).join('\n\n');
					const previousDescription = isBlank(description)
						? ''
						: `${description} \n\n// Generated\n`;
					setValue(
						'description',
						`${previousDescription}#### Template Description\n` +
							`General information about your template.\n\n` +
							deviceList
					);
				});
		}
		// Description should only be read once and not updated upon.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connectFile, setValue]);

	const onDelete = React.useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			setDeleteDialogState(true);
		},
		[setDeleteDialogState]
	);

	const onSaveDraft = React.useCallback(async () => {
		const templateData = getValues();
		templates.saveAsDraft(templateData, template).then(success => {
			if (success) {
				history.goBack();
			} else {
				enqueueSnackbar(t('edit.saveFailed'), { variant: 'error' });
			}
		});
	}, [templates, template, history, enqueueSnackbar, t, getValues]);
	const [publishDialogOpen, setPublishDialogOpen] = useState(false);

	const onPublishClicked = React.useCallback(() => setPublishDialogOpen(true), [
		setPublishDialogOpen,
	]);

	const onPublishCancel = React.useCallback(() => setPublishDialogOpen(false), [
		setPublishDialogOpen,
	]);
	const onPublish = React.useCallback(
		async (templateData: TemplateFormData) => {
			templates.saveAndPublish(templateData, template).then(success => {
				if (success) {
					history.goBack();
				} else {
					enqueueSnackbar(t('edit.publishFailed'), { variant: 'error' });
				}
			});
		},
		[templates, template, history, enqueueSnackbar, t]
	);
	return (
		<Page>
			<FormProvider {...formMethods}>
				<form style={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
					<Header
						title={title}
						backButton
						actionGroup={
							<div>
								<Grid spacing={1} container justify="flex-end">
									{template.id != null && (
										<Grid item>
											<RemoveButton
												fullWidth={false}
												color="default"
												variant="contained"
												onClick={onDelete}
												label={t('edit.remove')}
											/>
											<DeleteTemplateDialog
												open={deleteDialogIsOpen}
												onCancel={onHandleDialogCancel}
												onDelete={onHandleDialogOk}
											/>
										</Grid>
									)}
									<Grid item>
										<SubmitButton
											color="default"
											validate={true}
											fullWidth={false}
											onClick={handleSubmit(onPublishClicked)}
											type="button"
										>
											{t('edit.publish')}
										</SubmitButton>
										<PublishDialog
											open={publishDialogOpen}
											onCancel={onPublishCancel}
											onPublish={handleSubmit(onPublish)}
										/>
									</Grid>
									{allowDraft && (
										<Grid item>
											<AsyncButton fullWidth={false} onClick={onSaveDraft}>
												{t('edit.saveDraft')}
											</AsyncButton>
										</Grid>
									)}
								</Grid>
							</div>
						}
					/>
					<Body>
						<Side>
							<Grid container>
								<Grid item xs={12}>
									<Controller
										name="image"
										control={control}
										rules={{
											validate: image => {
												if (image == null && template.imageUrl == null) {
													return common('forms.required');
												}
												return undefined;
											},
										}}
										render={props => (
											<ImageUpload
												accept="image/png, image/gif, image/jpeg"
												label={t('edit.thumbnail')}
												style={{ padding: '8px' }}
												{...props}
												defaultSrc={template.imageUrl}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12}>
									<Controller
										name="name"
										control={control}
										rules={{
											validate: name => (isBlank(name) ? common('forms.required') : undefined),
										}}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<TextField
												label={t('edit.name')}
												placeholder={t('edit.name')}
												fullWidth
												autoFocus
												value={value}
												onChange={onChange}
												error={!!error}
												helperText={error?.message || ''}
												nextInputOnEnter={companyInputRef}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} style={{ position: 'relative' }}>
									<Controller
										name="vendor"
										control={control}
										rules={{
											validate: value => (isBlank(value) ? common('forms.required') : undefined),
										}}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<TextField
												inputRef={ref => (companyInputRef.current = ref)}
												label={t('edit.company')}
												placeholder={t('edit.company')}
												fullWidth
												value={value}
												onChange={onChange}
												error={!!error}
												helperText={error?.message || ''}
												nextInputOnEnter={versionInputRef}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} style={{ position: 'relative' }}>
									<Controller
										rules={{
											validate: value => (isBlank(value) ? common('forms.required') : undefined),
										}}
										name="version"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<TextField
												inputRef={ref => (companyInputRef.current = ref)}
												label={t('edit.version')}
												placeholder={t('edit.version')}
												fullWidth
												value={value}
												onChange={onChange}
												error={!!error}
												helperText={error?.message || ''}
												nextInputOnEnter={referenceInputRef}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} style={{ position: 'relative' }}>
									<Controller
										name="reference"
										rules={{
											validate: value => (isBlank(value) ? common('forms.required') : undefined),
										}}
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<TextField
												inputRef={ref => (referenceInputRef.current = ref)}
												label={t('edit.reference')}
												placeholder={t('edit.reference')}
												fullWidth
												value={value}
												onChange={onChange}
												error={!!error}
												helperText={error?.message || ''}
											/>
										)}
									/>
								</Grid>
							</Grid>
						</Side>
						<Center>
							<Controller
								name="description"
								control={control}
								render={({ field: { value, onChange } }) => (
									<Grid container spacing={1}>
										<Grid item xs={12}>
											<MDEditor
												value={value}
												onChange={onChange}
												preview="edit"
												commands={[
													commands.title,
													commands.divider,
													commands.unorderedListCommand,
													commands.orderedListCommand,
													commands.checkedListCommand,
												]}
											/>
										</Grid>
										<Grid item xs={12}>
											{description == null ? (
												<Typography variant="body2">{t('edit.addConnectFile')}</Typography>
											) : (
												<MDEditor.Markdown source={value} />
											)}
										</Grid>
									</Grid>
								)}
							/>
						</Center>
						<Side>
							<Controller
								name="connectFile"
								control={control}
								render={props => (
									<ConnectFileUpload
										style={{ padding: '8px' }}
										{...props}
										defaultFilename={template.connectFileName}
									/>
								)}
								rules={{
									validate: value =>
										value == null && template.connectFileName == null
											? common('forms.required')
											: undefined,
								}}
							/>
						</Side>
					</Body>
				</form>
			</FormProvider>
		</Page>
	);
};
