import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { Fragment, useCallback, useContext } from 'react';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isEmail from 'validator/lib/isEmail';
import { Company, NewUserData } from '../models/company';
import { Role } from '../models/userRole';
import { StoreContext } from '../stores';

const useStyles = makeStyles(theme => ({
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	progress: {
		width: '100%',
		position: 'absolute',
		borderTopLeftRadius: 2,
		borderTopRightRadius: 2,
		top: 0,
	},
}));

type Props = {
	done: () => void;
	company: Company;
};

export const NewUserForm: React.FC<Props> = ({ company, done }) => {
	const { t } = useTranslation('users');
	const { t: common } = useTranslation('common');
	const { companyStore } = useContext(StoreContext);
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();

	const onAdd = useCallback(
		async (userData: NewUserData) => {
			if (await companyStore.addUserToCompany(company, userData)) {
				done();
			} else {
				enqueueSnackbar(t('addForm.failure'), { variant: 'error' });
			}
		},
		[done, company, companyStore, enqueueSnackbar, t]
	);
	const formMethods = useForm<NewUserData>({ mode: 'all' });
	const { handleSubmit, control, formState } = formMethods;

	return (
		<FormProvider {...formMethods}>
			<form className={classes.form} onSubmit={handleSubmit(onAdd)} style={{ padding: '16px' }}>
				<Grid container>
					<Controller
						name="email"
						control={control}
						defaultValue=""
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<TextField
								label={common('forms.emailLabel')}
								placeholder={common('forms.emailPlaceholder')}
								fullWidth
								autoFocus
								value={value}
								onChange={onChange}
								error={!!error}
								helperText={error?.message || ''}
							/>
						)}
						rules={{
							validate: email => {
								if (!email) {
									return common('forms.required');
								} else if (!isEmail(email)) return common('forms.invalidMail');
								return undefined;
							},
						}}
					/>
					<Grid item xs={12}>
						<Controller
							name="role"
							control={control}
							defaultValue={Role.TemplateCreator}
							render={({ field: { onChange, value } }) => (
								<FormControl fullWidth>
									<InputLabel id="role-select-label">{t('addForm.roleLabel')}</InputLabel>
									<Select
										labelId="role-select-label"
										fullWidth
										id="role-select"
										value={value}
										onChange={onChange}
									>
										<MenuItem value={Role.CompanyAdmin}>{t('addForm.roleCompanyAdmin')}</MenuItem>
										<MenuItem value={Role.TemplateCreator}>{t('addForm.templateCreator')}</MenuItem>
									</Select>
								</FormControl>
							)}
						/>
					</Grid>
				</Grid>
				<Button
					type="submit"
					disabled={!formState.isValid || formState.isSubmitting}
					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
				>
					<Fragment>
						{formState.isSubmitting && <LinearProgress className={classes.progress} />}
						{t('addForm.add')}
					</Fragment>
				</Button>
			</form>
		</FormProvider>
	);
};
