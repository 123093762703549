import { action, makeObservable, observable, runInAction } from 'mobx';
import {
	ResetPasswordRequest,
	PasswordResetInfoResponse,
	PasswordResetRequest,
} from '@thingos/m4i-webservice-shared';
import { api } from '../config';
import { StatusCodes } from 'http-status-codes';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/lib/TaskEither';
import * as E from 'fp-ts/lib/Either';

export class PasswordResetStore {
	email: string | null = null;
	passwordResetInfo:
		| { info: PasswordResetInfoResponse; error: false }
		| { error: true }
		| null = null;

	public constructor() {
		makeObservable(this, {
			email: observable,
			passwordResetInfo: observable,
			fetchPasswordResetInfo: action.bound,
			requestPasswordReset: action.bound,
		});
	}

	public async requestPasswordReset(email: string): Promise<boolean> {
		this.email = email;
		const response = await fetch(api + '/password-reset/request', {
			method: 'POST',
			credentials: 'include',
			mode: 'cors',
			headers: new Headers({
				'Content-Type': 'application/json',
			}),
			body: JSON.stringify(ResetPasswordRequest.encode({ email })),
		});
		if (response.status === StatusCodes.OK) {
			runInAction(() => {
				this.email = email;
			});
			return true;
		} else {
			return false;
		}
	}

	public async fetchPasswordResetInfo(token: string): Promise<void> {
		this.passwordResetInfo = null;
		return pipe(
			TE.tryCatch(
				async () =>
					fetch(api + `/password-reset/info/${token}`, {
						method: 'GET',
						mode: 'cors',
					}),
				() => 'Error' as const
			),
			TE.chain(response => {
				if (response.status === StatusCodes.OK) {
					return pipe(
						TE.tryCatch(
							() => response.json(),
							() => 'Error' as const
						),
						TE.chain(response =>
							pipe(
								PasswordResetInfoResponse.decode(response),
								E.mapLeft(() => 'Error' as const),
								TE.fromEither
							)
						)
					);
				}
				return TE.left('Error');
			}),
			TE.match(
				e =>
					runInAction(() => {
						console.log(e);
						this.passwordResetInfo = { error: true };
					}),
				passwordResetInfo =>
					runInAction(() => {
						this.passwordResetInfo = { info: passwordResetInfo, error: false };
					})
			)
		)();
	}

	public async resetPassword(passwordResetRequest: PasswordResetRequest): Promise<boolean> {
		return pipe(
			TE.tryCatch(
				async () =>
					fetch(api + '/password-reset', {
						method: 'POST',
						mode: 'cors',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(passwordResetRequest),
					}),
				() => 'Error' as const
			),
			TE.map(response => response.status === StatusCodes.OK),
			TE.match(
				() => false,
				s => s
			)
		)();
	}
}
