import { Button, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import {
	ControllerRenderProps,
	ControllerFieldState,
	UseFormStateReturn,
	FieldValues,
	FieldPath,
} from 'react-hook-form';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';

import { Icons } from '@thingos/design-icons';

const Root = styled(Grid).attrs({ container: true, spacing: 1 })({
	display: 'flex',
	flexDirection: 'column',
	minHeight: 150,
});

const FileRow = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	color: 'rgba(0, 0, 0, 0.6)',
});

type ConnectFileUploadProps<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
	defaultFilename?: string;
	field: ControllerRenderProps<TFieldValues, TName>;
	fieldState: ControllerFieldState;
	formState: UseFormStateReturn<TFieldValues>;
} & React.HTMLAttributes<HTMLDivElement>;
export const ConnectFileUpload: React.FC<ConnectFileUploadProps> = props => {
	const uploadFile = React.useCallback(noop, []);
	const { t } = useTranslation('templates');
	const [filename, setFilename] = useState<string | undefined>(props.defaultFilename);

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			const file = acceptedFiles[0];
			if (file != null) {
				setFilename(file.name);
				props.field.onChange(file);
			}
		},
		[setFilename, props.field]
	);
	const { getRootProps, getInputProps } = useDropzone({
		accept: ['.connect'],
		onDrop,
		maxFiles: 1,
		validator: file =>
			file.name.match(/\.*\.connect$/) != null
				? null
				: { code: 'file-invalid-type', message: 'Only accepts .connect files' },
	});
	return (
		<Root {...getRootProps()}>
			<input {...getInputProps()} />
			<Grid item>
				<Typography variant="subtitle1" color="primary">
					{t('edit.connectFile')}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="subtitle1">{t('edit.uploadConnectFile')}</Typography>
			</Grid>
			<Grid item>
				{filename != null && (
					<FileRow>
						<Icons kind="ui/document" />
						<Typography variant="subtitle1">{filename}</Typography>
					</FileRow>
				)}
			</Grid>
			<Grid item>
				{filename == null ? (
					<Button variant="contained" color="primary" onClick={uploadFile}>
						{t('edit.selectFile')}
					</Button>
				) : (
					<Button variant="contained" color="default" onClick={uploadFile}>
						{t('edit.replaceFile')}
					</Button>
				)}
			</Grid>
		</Root>
	);
};
