import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { StatusCodes } from 'http-status-codes';
import * as TE from 'fp-ts/lib/TaskEither';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { PathReporter } from 'io-ts/lib/PathReporter';

import {
	TemplateInfo,
	TemplateImageUploadResponse,
	PostTemplateDataRequest,
	DeleteTemplateRequest,
	TemplateConnectFileUploadResponse,
	GetTemplateConnectFileResponse,
} from '@thingos/m4i-webservice-shared';

import { Template } from './template';
import { api } from '../config';
import { TemplateFormData } from '../components/TemplateEditor';

export class Templates {
	drafts: Map<string, Template>;
	templates: Map<string, Template>;
	selectedTemplate: null | Template;

	get isEmpty(): boolean {
		return this.drafts.size + this.templates.size === 0;
	}

	selectTemplate(template: Template) {
		runInAction(() => {
			this.selectedTemplate = template;
		});
	}

	handleTemplateInfo(templateInfo: TemplateInfo, template: Template) {
		runInAction(() => {
			template.id = templateInfo.id;
			template.name = templateInfo.name;
			template.imageUrl = templateInfo.imageUrl;
			template.vendor = templateInfo.vendor;
			template.version = templateInfo.version;
			template.connectFileName = templateInfo.connectFileName;
			template.connectFileSize = templateInfo.connectFileSize;
			template.reference = templateInfo.reference;
			template.updatedAt = templateInfo.updatedAt;
			template.description = templateInfo.description || undefined;
			if (templateInfo.isDraft) {
				if (!this.drafts.has(templateInfo.id)) {
					this.drafts.set(templateInfo.id, template);
				}
			} else {
				if (this.drafts.has(templateInfo.id)) {
					this.drafts.delete(templateInfo.id);
				}
				if (!this.templates.has(templateInfo.id)) {
					this.templates.set(templateInfo.id, template);
				}
			}
		});
	}

	uploadConnectFile(
		templateInfo: TemplateInfo,
		connectFile: File
	): TE.TaskEither<string, TemplateInfo> {
		const formData = new FormData();
		formData.set('file', connectFile);
		formData.set('templateId', templateInfo.id);
		return pipe(
			TE.tryCatch(
				() =>
					fetch(api + `/template/connect`, {
						credentials: 'include',
						mode: 'cors',
						method: 'POST',
						body: formData,
					}),
				() => 'Failed to upload connect file'
			),
			TE.chain(response => {
				if (response.status === StatusCodes.OK) {
					return pipe(
						TE.tryCatch(
							() => response.json(),
							() => 'Failed to get response json'
						),
						TE.chain(json =>
							pipe(
								TemplateConnectFileUploadResponse.decode(json),
								E.mapLeft(() => 'Failed to decode connect file response'),
								TE.fromEither
							)
						)
					);
				} else return TE.left('Unexpected image upload response');
			}),
			TE.map(templateConnectFileUploadResponse => ({
				...templateInfo,
				...templateConnectFileUploadResponse,
			}))
		);
	}

	uploadImage(templateInfo: TemplateInfo, image: File): TE.TaskEither<string, TemplateInfo> {
		const formData = new FormData();
		formData.set('file', image);
		formData.set('templateId', templateInfo.id);
		return pipe(
			TE.tryCatch(
				() =>
					fetch(api + `/template/image`, {
						credentials: 'include',
						mode: 'cors',
						method: 'POST',
						body: formData,
					}),
				() => 'Failed to upload template image'
			),
			TE.chain(response => {
				if (response.status === StatusCodes.OK) {
					return pipe(
						TE.tryCatch(
							() => response.json(),
							() => 'Failed to get response json'
						),
						TE.chain(json =>
							pipe(
								TemplateImageUploadResponse.decode(json),
								E.mapLeft(() => 'Failed to decode image upload response'),
								TE.fromEither
							)
						)
					);
				} else return TE.left('Unexpected image upload response');
			}),
			TE.map(templateImageUploadResponse => ({ ...templateInfo, ...templateImageUploadResponse }))
		);
	}

	postTemplateData(templateData: PostTemplateDataRequest): TE.TaskEither<string, TemplateInfo> {
		return pipe(
			TE.tryCatch(
				() =>
					fetch(api + '/template', {
						credentials: 'include',
						mode: 'cors',
						method: 'POST',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(templateData),
					}),
				() => 'Failed to post template data'
			),
			TE.chain(response => {
				if (response.status === StatusCodes.OK) {
					return pipe(
						TE.tryCatch(
							() => response.json(),
							() => 'Failed to get body'
						),
						TE.chain(body =>
							pipe(
								TemplateInfo.decode(body),
								E.mapLeft(e => PathReporter.report(E.left(e)).join('\n')),
								TE.fromEither,
								TE.mapLeft(e => {
									console.error(e);
									return 'Could not decode template response';
								})
							)
						)
					);
				}
				return TE.left('Invalid response');
			})
		);
	}

	updateTemplate(
		template: Template,
		postTemplateData: PostTemplateDataRequest,
		image?: File,
		connectFile?: File
	): Promise<boolean> {
		return pipe(
			this.postTemplateData(postTemplateData),
			TE.chain(templateInfo => {
				if (image != null) {
					return this.uploadImage(templateInfo, image);
				}
				return TE.right(templateInfo);
			}),
			TE.chain(templateInfo => {
				if (connectFile != null) {
					return this.uploadConnectFile(templateInfo, connectFile);
				}
				return TE.right(templateInfo);
			}),
			TE.match(
				e => {
					console.error(e);
					return false;
				},
				templateInfo => {
					this.handleTemplateInfo(templateInfo, template);
					return true;
				}
			)
		)();
	}

	async saveAsDraft(draftData: TemplateFormData, template: Template): Promise<boolean> {
		const { image, connectFile, ...rest } = draftData;
		const templateData =
			template.id != null
				? PostTemplateDataRequest.encode({ ...rest, isDraft: true, templateId: template.id })
				: PostTemplateDataRequest.encode({ ...rest, isDraft: true, companyId: this.companyId });
		return this.updateTemplate(template, templateData, image, connectFile);
	}

	async saveAndPublish(templateFormData: TemplateFormData, template: Template): Promise<boolean> {
		await this.saveAsDraft(templateFormData, template);
		const { image, connectFile, ...rest } = templateFormData;
		const templateData = PostTemplateDataRequest.encode({
			...rest,
			isDraft: false,
			templateId: template.id!,
		});
		return this.updateTemplate(template, templateData, image, connectFile);
	}

	constructor(public companyId: string, templates: TemplateInfo[]) {
		const templateMap = new Map<string, Template>();
		const draftMap = new Map<string, Template>();
		templates
			.map(template => new Template(template))
			.forEach(template => {
				if (template.isDraft) {
					draftMap.set(template.id!, template);
				} else {
					templateMap.set(template.id!, template);
				}
			});
		this.selectedTemplate = null;
		this.drafts = new Map<string, Template>();
		this.templates = new Map<string, Template>();
		runInAction(() => {
			this.templates = observable.map(templateMap);
			this.drafts = observable.map(draftMap);
		});

		makeObservable(this, {
			templates: observable,
			drafts: observable,
			selectedTemplate: observable,
			isEmpty: computed,
			saveAsDraft: action,
			selectTemplate: action,
			saveAndPublish: action,
		});
	}

	public async delete(template: Template): Promise<boolean> {
		return pipe(
			TE.tryCatch(
				() =>
					fetch(api + '/template', {
						method: 'DELETE',
						credentials: 'include',
						mode: 'cors',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(DeleteTemplateRequest.encode({ templateId: template.id! })),
					}),
				() => 'Failed to issue delete'
			),
			TE.chain(response => {
				if (response.status === StatusCodes.OK) {
					return TE.right(true);
				}
				return TE.left('Status code indicated failure');
			}),
			TE.match(
				error => {
					console.error(`Failed to delete the template: ${error}`);
					return false;
				},
				() => {
					runInAction(() => {
						this.templates.delete(template.id!);
						this.drafts.delete(template.id!);
					});
					return true;
				}
			)
		)();
	}

	public async downloadConnectFile(template: Template): Promise<boolean> {
		return pipe(
			TE.tryCatch(
				async () =>
					fetch(api + `/template/connect/${template.id!}`, {
						method: 'GET',
						credentials: 'include',
						mode: 'cors',
					}),
				e => `Failed to request template file ${e}`
			),
			TE.chain(response => {
				if (response.status === StatusCodes.OK) {
					return pipe(
						TE.tryCatch(
							async () => response.json(),
							() => 'Failed to decode json'
						),
						TE.chainW(response => TE.fromEither(GetTemplateConnectFileResponse.decode(response)))
					);
				}
				return TE.left('Status code indicated failure');
			}),
			TE.match(
				error => {
					console.error(`Failed to get the template: ${error}`);
					return false;
				},
				response => {
					const element = document.createElement('a');
					element.setAttribute(
						'href',
						'data:text/plain;charset=utf-8,' + encodeURIComponent(response.connectFile)
					);
					element.setAttribute('download', template.connectFileName || 'Template.connect');

					element.style.display = 'none';
					document.body.appendChild(element);

					element.click();

					document.body.removeChild(element);
					return true;
				}
			)
		)();
	}
}
