import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { TextField } from '../library';
import { StoreContext } from '../stores';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { SubmitButton } from './SubmitButton';
import { Fragment, useCallback, useContext } from 'react';

const useStyles = makeStyles(theme => ({
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	form: {
		position: 'relative',
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
}));

type NameChangeData = { name: string };

type NameChangeFormProps = {
	currentName?: string;
	onDone: () => void;
};

export const NameChangeForm: React.FC<NameChangeFormProps> = ({ currentName, onDone }) => {
	const classes = useStyles();
	const { accountStore } = useContext(StoreContext);
	const snackbar = useSnackbar();
	const { t } = useTranslation('settings');

	const changeName = useCallback(
		async (values: NameChangeData) => {
			const response = await accountStore.changeName(values.name);
			if (response) {
				onDone();
			} else {
				snackbar.enqueueSnackbar(t('changeName.failure'), { variant: 'error' });
			}
		},
		[onDone, accountStore, snackbar, t]
	);

	const formMethods = useForm<NameChangeData>({
		mode: 'all',
		defaultValues: { name: currentName },
	});
	const { handleSubmit, control } = formMethods;

	return (
		<Fragment>
			<FormProvider {...formMethods}>
				<form className={classes.form} onSubmit={handleSubmit(changeName)}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Controller
								name="name"
								control={control}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<TextField
										label={t('changeName.nameLabel')}
										placeholder={t('changeName.namePlaceholder')}
										fullWidth
										autoFocus
										value={value}
										onChange={onChange}
										autoComplete="name"
										error={!!error}
										helperText={error?.message || ''}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<SubmitButton>{t('changeName.change')}</SubmitButton>
						</Grid>
						<Grid item xs={12}>
							<Button onClick={onDone} fullWidth variant="contained" color="secondary">
								{t('changeName.cancel')}
							</Button>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
		</Fragment>
	);
};
