import { Button, ButtonProps, LinearProgress } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const StyledProgress = styled(LinearProgress)({
	progress: {
		width: '100%',
		position: 'absolute',
		borderTopLeftRadius: 2,
		borderTopRightRadius: 2,
		top: 0,
	},
});

type Props = Omit<ButtonProps, 'disabled'> & {
	validate?: boolean;
};

export const SubmitButton: React.FC<Props> = ({
	fullWidth = true,
	variant = 'contained',
	color = 'primary',
	validate = true,
	type = 'submit',
	children,
	...rest
}) => {
	const { formState } = useFormContext();
	return (
		<Button
			type={type}
			disabled={(!formState.isValid && validate) || formState.isSubmitting}
			fullWidth={fullWidth}
			variant={variant}
			color={color}
			{...rest}
		>
			{formState.isSubmitting && <StyledProgress />}
			{children}
		</Button>
	);
};
