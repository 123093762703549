import styled from 'styled-components';

const Placeholder = styled.div({
	width: '100%',
	height: '100%',
	margin: 'auto',
	justifyContent: 'center',
	display: 'flex',
	flexDirection: 'column',
	backgroundColor: '#E0E0E0',
	color: '#A0A0A0',
	textAlign: 'center',
});

const Image = styled.img({
	width: '100%',
	height: '100%',
	objectFit: 'cover',
});

type Props = {
	url: string | null;
} & React.HTMLAttributes<HTMLDivElement>;
export const Preview: React.FC<Props> = ({ url, ...rest }) =>
	url != null ? (
		<Image src={url} {...rest} />
	) : (
		<Placeholder {...rest}>
			<div>?</div>
		</Placeholder>
	);
