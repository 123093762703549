import { makeStyles, Typography, IconButton, Button, Theme, withStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { red } from '@material-ui/core/colors';
import { Edit } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

import { UserInfo } from '@thingos/m4i-webservice-shared';

import { Header } from '../components/Header';
import { StoreContext } from '../stores';
import { AccountStore } from '../stores/accountStore';

import { PasswordChangeForm } from '../components/PasswordChangeForm';
import { DeleteUserDialog } from '../components/DeleteUserDialog';
import { DefaultRedirect } from './DefaultRedirect';
import { Raised } from '../components';
import { NameIcon } from '../components/NameIcon';
import { useTranslation } from 'react-i18next';
import { NameChangeForm } from '../components/NameChangeForm';

const DeleteButton = withStyles((theme: Theme) => ({
	root: {
		color: theme.palette.getContrastText(red[500]),
		backgroundColor: red[500],
		'&:hover': {
			backgroundColor: red[700],
		},
	},
}))(Button);

const Page = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
});

const useStyles = makeStyles(theme => ({
	formGroup: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	button: {
		marginTop: theme.spacing(1),
	},
}));

type Props = {
	accountStore: AccountStore;
	userInfo: UserInfo;
};
export const Settings: React.FC<Props> = observer(function Settings({ accountStore, userInfo }) {
	const classes = useStyles();
	const { t } = useTranslation('settings');
	const { email, name } = userInfo;
	const history = useHistory();
	const snackbar = useSnackbar();
	const [isPasswordEditActive, setPasswordEditActive] = useState(false);
	const [isNameEditActive, setNameEditActive] = useState(false);

	const [deleteDialogIsOpen, setDeleteDialogState] = useState(false);

	const onHandleDialogOk = React.useCallback(
		async password => {
			try {
				const response = await accountStore.deleteAccount(password);
				if (response) {
					history.push('/login');
				} else {
					snackbar.enqueueSnackbar(t('deleteAccount.couldNotDelete'), { variant: 'error' });
				}
			} catch {
				snackbar.enqueueSnackbar(t('deleteAccount.couldNotDelete'), { variant: 'error' });
			}
			setDeleteDialogState(false);
		},
		[accountStore, setDeleteDialogState, history, snackbar, t]
	);

	const onHandleDialogCancel = React.useCallback(() => {
		setDeleteDialogState(false);
	}, [setDeleteDialogState]);

	const onClickDelete = React.useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			setDeleteDialogState(true);
		},
		[setDeleteDialogState]
	);

	return (
		<Page>
			<Header title={t('title')} />
			<Raised layer={1} style={{ width: 500, padding: '64px', flex: 1, overflow: 'auto' }}>
				<NameIcon style={{ marginBottom: 32 }} name={name} email={email} />
				<div style={{ flexGrow: 1 }}>
					{!isNameEditActive ? (
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
							<div className={classes.formGroup}>
								<Typography variant="caption">{t('name')}</Typography>
								<Typography variant="body1">
									{name != null ? name : <i>{t('nameNotSet')}</i>}
								</Typography>
							</div>

							<IconButton onClick={() => setNameEditActive(s => !s)} style={{ height: '48px' }}>
								<Edit />
							</IconButton>
						</div>
					) : (
						<NameChangeForm currentName={name} onDone={_.partial(setNameEditActive, false)} />
					)}
					<div className={classes.formGroup}>
						<Typography variant="caption">{t('email')}</Typography>
						<Typography variant="body1">{email ?? 'unknown'}</Typography>
					</div>
					{!isPasswordEditActive ? (
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
							<div className={classes.formGroup}>
								<Typography variant="caption">{t('password')}</Typography>
								<Typography variant="body1">••••••••••</Typography>
							</div>
							<IconButton onClick={() => setPasswordEditActive(s => !s)} style={{ height: '48px' }}>
								<Edit />
							</IconButton>
						</div>
					) : (
						<PasswordChangeForm email={email} onDone={_.partial(setPasswordEditActive, false)} />
					)}
				</div>
				<DeleteButton
					onClick={onClickDelete}
					fullWidth
					variant="contained"
					color="secondary"
					className={classes.button}
				>
					{t('deleteAccount.button')}
				</DeleteButton>
				<DeleteUserDialog
					open={deleteDialogIsOpen}
					onCancel={onHandleDialogCancel}
					onDelete={onHandleDialogOk}
				/>
			</Raised>
		</Page>
	);
});

export const SettingsPage: React.FC = observer(function SettingsPage() {
	const { accountStore } = useContext(StoreContext);
	const { userInfo } = accountStore;
	if (userInfo == null) {
		return <DefaultRedirect />;
	} else {
		return <Settings accountStore={accountStore} userInfo={userInfo} />;
	}
});
