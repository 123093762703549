import { Button, ButtonProps, LinearProgress } from '@material-ui/core';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

const StyledProgress = styled(LinearProgress)({
	progress: {
		width: '100%',
		position: 'absolute',
		borderTopLeftRadius: 2,
		borderTopRightRadius: 2,
		top: 0,
	},
});

export type AsyncButtonProps = ButtonProps & {
	inProgress?: boolean; // In case of controlled async button (not using the async callback
};

export const AsyncButton: React.FC<AsyncButtonProps> = ({
	fullWidth = true,
	variant = 'contained',
	color,
	type = undefined,
	inProgress,
	disabled,
	onClick,
	children,
	...rest
}) => {
	const [handlingClick, setHandlingClick] = useState(false);
	const onClickWrapper = useCallback(
		async e => {
			if (onClick != null) {
				setHandlingClick(true);
				await onClick(e);
				setHandlingClick(false);
			}
		},
		[setHandlingClick, onClick]
	);
	return (
		<Button
			type={type}
			disabled={inProgress || handlingClick || disabled}
			fullWidth={fullWidth}
			variant={variant}
			color={color}
			onClick={onClickWrapper}
			{...rest}
		>
			{(handlingClick || inProgress) && <StyledProgress />}
			{children}
		</Button>
	);
};
