import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import { TemplateEditor } from '../components/TemplateEditor';
import { Template } from '../models/template';
import { StoreContext } from '../stores';
import { DefaultRedirect } from './DefaultRedirect';

export const NewTemplate: React.FC = observer(function NewTemplate() {
	const [draft] = useState(new Template({ isDraft: true }));
	const { companyStore } = useContext(StoreContext);
	if (companyStore.selectedCompany == null) {
		return <DefaultRedirect />;
	}
	return (
		<TemplateEditor
			allowDraft
			templates={companyStore.selectedCompany.templates}
			template={draft}
			title="New Template"
		/>
	);
});
