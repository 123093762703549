import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@thingos/design-icons/web';

import { AsyncButton, AsyncButtonProps } from './AsyncButton';

type Props = Omit<AsyncButtonProps, 'children'> & {
	label?: string;
};
export const RemoveButton: React.FC<Props> = ({ label, color, ...rest }) => {
	const { t: common } = useTranslation('common');
	return (
		<AsyncButton color={color} {...rest}>
			<React.Fragment>
				{label || common('actions.remove')}
				<div style={{ marginLeft: 8, display: 'flex', justifyContent: 'center' }}>
					<Icon
						kind="ui/delete"
						color={color === 'primary' ? '#fff' : undefined}
						width="18"
						height="18"
					/>
				</div>
			</React.Fragment>
		</AsyncButton>
	);
};
