import { ThemeProvider } from '@material-ui/core';
import { ThemeProvider as StyledComponentThemeProvider } from 'styled-components';

import { useContext } from 'react';
import { StoreContext } from '../stores';
import { observer } from 'mobx-react';

type Props = React.HTMLProps<HTMLDivElement>;

export const DynamicThemeProvider: React.FC<Props> = observer(function DynamicThemeProvider({
	children,
}) {
	const { themeStore } = useContext(StoreContext);
	return (
		<ThemeProvider theme={themeStore.muiTheme}>
			<StyledComponentThemeProvider theme={themeStore.styledComponentTheme}>
				{children}
			</StyledComponentThemeProvider>
		</ThemeProvider>
	);
});
