import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { TextField, PasswordField } from '../library';
import { PasswordData, passwordStrengthColor, calculatePasswordStrength } from '../utils/password';
import { StoreContext } from '../stores';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { SubmitButton } from './SubmitButton';
import { Fragment, useCallback, useContext, useRef } from 'react';

const useStyles = makeStyles(theme => ({
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	form: {
		position: 'relative',
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
}));

type PasswordChangeData = PasswordData & { oldPassword: string };

type PasswordChangeFormProps = {
	email: string;
	onDone: () => void;
};

export const PasswordChangeForm: React.FC<PasswordChangeFormProps> = ({ email, onDone }) => {
	const classes = useStyles();
	const { accountStore } = useContext(StoreContext);
	const snackbar = useSnackbar();
	const { t } = useTranslation('settings');

	const changePassword = useCallback(
		async (values: PasswordChangeData) => {
			// TODO change password and login a anew
			const response = await accountStore.changePassword(values.oldPassword, values.password || '');
			if (response) {
				snackbar.enqueueSnackbar(t('changePassword.success'), { variant: 'success' });
				onDone();
			} else {
				snackbar.enqueueSnackbar(t('changePassword.failure'), { variant: 'error' });
			}
		},
		[onDone, accountStore, snackbar, t]
	);

	const formMethods = useForm<PasswordChangeData>({ mode: 'all' });
	const passwordInputRef = useRef<HTMLInputElement>();
	const passwordRepeatInputRef = useRef<HTMLInputElement>();
	const { handleSubmit, control, watch } = formMethods;
	const password = watch('password');

	return (
		<Fragment>
			<FormProvider {...formMethods}>
				<form className={classes.form} onSubmit={handleSubmit(changePassword)}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<TextField
								label={t('changePassword.email')}
								autoComplete="username"
								placeholder={t('changePassword.email')}
								disabled
								hidden
								fullWidth
								value={email}
							/>
						</Grid>
						<Grid item xs={12} style={{ position: 'relative' }}>
							<Controller
								name="oldPassword"
								control={control}
								defaultValue=""
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<PasswordField
										label={t('changePassword.oldPasswordLabel')}
										inputRef={ref => (passwordInputRef.current = ref)}
										placeholder={t('changePassword.oldPasswordPlaceholder')}
										fullWidth
										autoFocus
										value={value}
										onChange={onChange}
										autoComplete="password"
										error={!!error}
										nextInputOnEnter={passwordInputRef}
										helperText={error?.message || ''}
									/>
								)}
								rules={{
									required: t('changePassword.requiredField'),
								}}
							/>
						</Grid>

						<Grid item xs={12} style={{ position: 'relative' }}>
							<Typography
								variant="caption"
								style={{
									position: 'absolute',
									right: '0px',
									color: passwordStrengthColor(calculatePasswordStrength(password || '')),
								}}
							>
								{calculatePasswordStrength(password || '')}
							</Typography>
							<Controller
								name="password"
								control={control}
								defaultValue=""
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<PasswordField
										label={t('changePassword.newPasswordLabel')}
										inputRef={ref => (passwordInputRef.current = ref)}
										placeholder={t('changePassword.newPasswordPlaceholder')}
										fullWidth
										autoFocus
										autoComplete="new-password"
										value={value}
										onChange={onChange}
										nextInputOnEnter={passwordRepeatInputRef}
										error={!!error}
										helperText={error?.message || ''}
									/>
								)}
								rules={{
									required: t('changePassword.requiredField'),
								}}
							/>
						</Grid>
						<Grid item xs={12} style={{ position: 'relative' }}>
							<Controller
								name="repeatPassword"
								control={control}
								defaultValue=""
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<PasswordField
										label={t('changePassword.newPasswordRepeatLabel')}
										inputRef={ref => (passwordRepeatInputRef.current = ref)}
										placeholder={t('changePassword.newPasswordRepeatPlaceholder')}
										fullWidth
										autoFocus
										autoComplete="new-password"
										value={value}
										onChange={onChange}
										error={!!error}
										helperText={error?.message || ''}
									/>
								)}
								rules={{
									validate: value =>
										value !== password ? t('changePassword.matchPassword') : undefined,
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<SubmitButton>{t('changePassword.change')}</SubmitButton>
						</Grid>
						<Grid item xs={12}>
							<Button onClick={onDone} fullWidth variant="contained" color="secondary">
								{t('changePassword.cancel')}
							</Button>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
		</Fragment>
	);
};
