import { Button, Grid, makeStyles, Popover, Typography } from '@material-ui/core';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Cell, Column, useFlexLayout, useTable } from 'react-table';

import { TableHeaderCell, TableRowCell } from '@thingos/thingos-components';

import { Header } from '../components/Header';
import { TableRow } from '../components/TableRow';
import { StoreContext } from '../stores';
import { BatchState, Template } from '../models/template';
import { DefaultRedirect } from './DefaultRedirect';
import { TableHeader, TableBody } from './Companies';
import { Empty } from '../components/Empty';
import { Batch } from '../models/batch';
import { NewBatchForm } from '../components/NewBatchForm';
import { LoadingIndicator } from '../components/LoadingIndicator';
import { api } from '../config';
import { useTranslation } from 'react-i18next';
import { DeleteBatchDialog } from '../components/DeleteBatchDialog';

const ActionGroup: React.FC<{ cell: Cell<Batch, string>; template: Template }> = ({
	cell,
	template,
}): JSX.Element => {
	const { t } = useTranslation('templates');

	const [deleteDialogIsOpen, setDeleteDialogState] = useState(false);
	const onRemove = useCallback(() => setDeleteDialogState(true), [setDeleteDialogState]);
	const onHandleDialogCancel = useCallback(
		() => setDeleteDialogState(false),
		[setDeleteDialogState]
	);
	const onHandleDialogOk = useCallback(async () => {
		await template.removeBatch(cell.row.original.id);
		setDeleteDialogState(false);
	}, [template, cell, setDeleteDialogState]);

	return (
		<Grid container direction="row" justify="flex-end" spacing={1}>
			<Grid item>
				<Button variant="outlined" color="default" onClick={onRemove}>
					{t('networkCodes.remove')}
				</Button>
				<DeleteBatchDialog
					open={deleteDialogIsOpen}
					onCancel={onHandleDialogCancel}
					onDelete={onHandleDialogOk}
				/>
			</Grid>
			<Grid item>
				<Button
					variant="contained"
					color="primary"
					href={`${api}/batch/csv/${cell.row.original.id}`}
					download
				>
					{t('networkCodes.csv')}
				</Button>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles(theme => ({
	popover: {
		marginTop: theme.spacing(2),
		maxWidth: '400px',
	},
}));

const Page = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
});

type Props = {
	template: Template;
};
export const NetworkCodesView: React.FC<Props> = observer(function NetworkCodesView({ template }) {
	const { t } = useTranslation('templates');
	useEffect(() => {
		template.loadBatches();
	}, [template]);
	const styles = useStyles();

	const columns = useMemo<Column<Batch>[]>(
		() => [
			{
				Header: t('networkCodes.templateNameHeader'),
				accessor: 'templateName',
				maxWidth: 100,
			},
			{
				Header: t('networkCodes.customerHeader'),
				accessor: 'customer',
				maxWidth: 100,
			},
			{
				Header: t('networkCodes.amountHeader'),
				accessor: 'amount',
				maxWidth: 100,
			},
			{
				Header: t('networkCodes.dateHeader'),
				accessor: batch => batch.date.toLocaleDateString(),
				maxWidth: 100,
			},
			{
				Header: '',
				accessor: 'id',
				Cell: ActionGroup,
				width: 250,
				canResize: false,
			},
		],
		[t]
	);

	const { getTableProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data: Array.from(template.batches.values()),
		},
		useFlexLayout
	);
	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const addBatch = useCallback(
		(event: React.MouseEvent) => {
			setAnchorEl(event.currentTarget as Element);
		},
		[setAnchorEl]
	);

	const handleClose = useCallback(() => {
		setAnchorEl(null);
	}, [setAnchorEl]);
	const open = Boolean(anchorEl);
	const id = open ? 'add-batch-popover' : undefined;

	return (
		<Page {...getTableProps()}>
			<Header
				title={t('networkCodes.title', { templateName: template.name })}
				backButton
				actionGroup={
					<Fragment>
						<Button aria-describedby={id} variant="contained" color="primary" onClick={addBatch}>
							{t('networkCodes.add')}
						</Button>
						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							PaperProps={{ className: styles.popover }}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						>
							<NewBatchForm done={handleClose} template={template} />
						</Popover>
					</Fragment>
				}
				subHeader={
					template.batches.size === 0 ? undefined : (
						<TableHeader>
							{headerGroups.map((headerGroup, index) => (
								<div
									{...(headerGroup.getHeaderGroupProps({
										// style: { paddingRight: '15px' },
									}) as any)}
									className="tr"
									key={index}
								>
									{headerGroup.headers.map((column, _index) => {
										return (
											<TableHeaderCell {...(column.getHeaderProps() as any)} key={column.id}>
												{column.render('Header')}
											</TableHeaderCell>
										);
									})}
								</div>
							))}
						</TableHeader>
					)
				}
			/>
			{template.batchState === BatchState.loading ? (
				<LoadingIndicator />
			) : template.batches.size === 0 ? (
				<Empty text={t('networkCodes.noCodes')} />
			) : (
				<TableBody>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							{rows.map(row => {
								prepareRow(row);
								return (
									<TableRow {...(row.getRowProps() as any)} key={row.id}>
										{row.cells.map((cell, index) => {
											return (
												<TableRowCell {...(cell.getCellProps() as any)} key={index}>
													{cell.column.Cell != null ? (
														cell.render('Cell', { template })
													) : (
														<Typography variant="body2">{cell.render('Cell')}</Typography>
													)}
												</TableRowCell>
											);
										})}
									</TableRow>
								);
							})}
						</Grid>
					</Grid>
				</TableBody>
			)}
		</Page>
	);
});

export const NetworkCodes: React.FC = observer(function NetworkCodes() {
	const { companyStore } = useContext(StoreContext);
	if (
		companyStore.selectedCompany == null ||
		companyStore.selectedCompany.templates.selectedTemplate == null
	) {
		return <DefaultRedirect />;
	}
	return <NetworkCodesView template={companyStore.selectedCompany.templates.selectedTemplate} />;
});
