import { computed, makeObservable, observable } from 'mobx';

import { createMuiTheme } from '@material-ui/core';
import { DefaultTheme } from 'styled-components';

export class ThemeStore {
	primaryColor = '#D20A27';

	get muiTheme() {
		return createMuiTheme({
			palette: {
				primary: {
					main: this.primaryColor,
				},
				secondary: {
					main: this.primaryColor,
				},
			},
		});
	}

	get styledComponentTheme(): DefaultTheme {
		return {
			...this.muiTheme,
		};
	}

	public constructor() {
		makeObservable(this, {
			primaryColor: observable,
			muiTheme: computed,
			styledComponentTheme: computed,
		});
	}
}
