import { observer } from 'mobx-react-lite';

import { StoreContext } from '../stores';
import { PrivatePath } from '../pages';
import { Redirect } from 'react-router-dom';
import { useContext } from 'react';

export const DefaultRedirect: React.FC = observer(function DefaultRedirect() {
	const { companyStore } = useContext(StoreContext);
	if (companyStore.selectedCompany != null) {
		return <Redirect to={`/${companyStore.selectedCompany.id}:/templates`} />;
	}
	return <Redirect to={PrivatePath.companies} />;
});
