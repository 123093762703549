import { Button, Grid, makeStyles, Popover, Typography } from '@material-ui/core';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { Cell, Column, useFlexLayout, useTable } from 'react-table';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { TableHeaderCell, TableRowCell } from '@thingos/thingos-components';

import { Header } from '../components/Header';
import { TableRow } from '../components/TableRow';
import { NewUserForm } from '../components/NewUserForm';
import { Company } from '../models/company';
import { TableBody, TableHeader } from './Companies';
import { Empty } from '../components/Empty';
import { EditUserForm } from '../components/EditUserForm';
import { UserRole } from '../models/userRole';
import { WithCompany } from '../components/WithCompany';
import { useTranslation } from 'react-i18next';
import { DefaultCellRenderer } from '../components/TableCellRenderer';

const Page = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
});

const useStyles = makeStyles(theme => ({
	popover: {
		marginTop: theme.spacing(2),
		maxWidth: '400px',
	},
}));

export const EditUser: React.FC<{ cell: Cell<UserRole, string> }> = ({ cell }): JSX.Element => {
	const styles = useStyles();
	const { t } = useTranslation('common');
	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const edit = useCallback(
		(event: React.MouseEvent) => {
			setAnchorEl(event.currentTarget as Element);
		},
		[setAnchorEl]
	);
	const handleClose = useCallback(() => {
		setAnchorEl(null);
	}, [setAnchorEl]);
	const open = Boolean(anchorEl);
	const id = open ? `edit-${cell.row.original.user.id}` : undefined;
	return (
		<Fragment>
			<Button variant="contained" color="primary" onClick={edit}>
				{t('actions.edit')}
			</Button>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				PaperProps={{ className: styles.popover }}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<EditUserForm done={handleClose} userRole={cell.row.original} />
			</Popover>
		</Fragment>
	);
};

type GroupProps = {
	name: string;
};
export const Group: React.FC<GroupProps> = ({ name }) => {
	return (
		<Grid item xs={12}>
			<Typography variant="h6">{name}</Typography>
		</Grid>
	);
};

type Props = {
	company: Company;
};
export const Users: React.FC<Props> = observer(function Users({ company }) {
	const { t } = useTranslation('users');
	const styles = useStyles();

	const columns = useMemo<Column<UserRole>[]>(
		() => [
			{
				Header: t('emailHeader'),
				id: 'email',
				accessor: userRole => userRole.user.email,
				width: 100,
			},
			{
				Header: '',
				id: 'action-group',
				accessor: userRole => userRole.company.name,
				Cell: EditUser,
				width: 120,
				canResize: false,
			},
		],
		[t]
	);

	const { getTableProps, headerGroups, rows: adminRows, prepareRow } = useTable(
		{
			defaultColumn: {
				Cell: DefaultCellRenderer,
			},
			columns,
			data: company.adminRoles,
		},
		useFlexLayout
	);

	const { rows: templateCreatorRows } = useTable(
		{
			columns,
			data: company.templateCreatorRoles,
		},
		useFlexLayout
	);

	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const addUser = useCallback(
		(event: React.MouseEvent) => {
			setAnchorEl(event.currentTarget as Element);
		},
		[setAnchorEl]
	);

	const handleClose = useCallback(() => {
		setAnchorEl(null);
	}, [setAnchorEl]);
	const open = Boolean(anchorEl);
	const id = open ? 'add-company-popover' : undefined;
	return (
		<Page {...getTableProps()}>
			<Header
				title={t('title')}
				actionGroup={
					<Fragment>
						<Button aria-describedby={id} variant="contained" color="primary" onClick={addUser}>
							{t('add')}
						</Button>
						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							PaperProps={{ className: styles.popover }}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						>
							<NewUserForm done={handleClose} company={company} />
						</Popover>
					</Fragment>
				}
				subHeader={
					company.users.length === 0 ? undefined : (
						<TableHeader>
							{headerGroups.map((headerGroup, index) => (
								<div
									{...(headerGroup.getHeaderGroupProps({
										// style: { paddingRight: '15px' },
									}) as any)}
									className="tr"
									key={index}
								>
									{headerGroup.headers.map((column, _index) => {
										return (
											<TableHeaderCell {...(column.getHeaderProps() as any)} key={column.id}>
												{column.render('Header')}
											</TableHeaderCell>
										);
									})}
								</div>
							))}
						</TableHeader>
					)
				}
			/>
			{company.users.length === 0 ? (
				<Empty text="No users added yet" />
			) : (
				<TableBody>
					<Grid container spacing={1}>
						<Group name="Company Administrators" />
						<Grid item xs={12}>
							{adminRows.map(row => {
								prepareRow(row);
								return (
									<TableRow {...(row.getRowProps() as any)} key={row.id}>
										{row.cells.map((cell, index) => {
											return (
												<TableRowCell {...(cell.getCellProps() as any)} key={index}>
													<Typography variant="body2">{cell.render('Cell')}</Typography>
												</TableRowCell>
											);
										})}
									</TableRow>
								);
							})}
						</Grid>
						<Group name="Template Creators" />
						<Grid item xs={12}>
							{templateCreatorRows.map(row => {
								prepareRow(row);
								return (
									<TableRow {...(row.getRowProps() as any)} key={row.id}>
										{row.cells.map((cell, index) => {
											return (
												<TableRowCell {...(cell.getCellProps() as any)} key={index}>
													<Typography variant="body2">{cell.render('Cell')}</Typography>
												</TableRowCell>
											);
										})}
									</TableRow>
								);
							})}
						</Grid>
					</Grid>
				</TableBody>
			)}
		</Page>
	);
});

export const UsersPage: React.FC = () => {
	return <WithCompany companyConsumer={company => <Users company={company} />} />;
};
