import { Box, Container, makeStyles, Paper } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import { Legal, LoginForm, Logo } from '../../components';
import { PasswordResetSent } from '../../components/PasswordResetSent';
import { RequestPasswordResetForm } from '../../components/RequestPasswordResetForm';
import { PasswordReset } from './PasswordReset';
import { Registration } from './Registration';
import { TermsOfService } from './TermsOfService';

const useStyles = makeStyles(theme => ({
	root: {
		flexDirection: 'column',
		flex: 1,
		alignItems: 'stretch',
		display: 'flex',
		overflow: 'scroll',
		backgroundColor: '#F5F5F5',
	},
	main: {
		flexDirection: 'column',
		display: 'flex',
		flex: 1,
	},
	sidebar: {
		flexShrink: 1,
		display: 'flex',
	},
	logo: {
		margin: 32,
		minWidth: 10,
		minHeight: 10,
		flexShrink: 1,
	},
	container: {
		flexDirection: 'column',
		display: 'flex',
		justifyContent: 'center',
		flex: 1,
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	copyright: _ => ({
		bottom: theme.spacing(4),
		width: '100%',
	}),
}));

export const PublicPaths = {
	login: '/login',
	registration: '/accept-invite/:invitation',
	requestPasswordReset: '/request-password-reset',
	passwordResetLinkSent: '/password-reset-sent',
	passwordReset: '/password-reset/:passwordResetToken',
	termsOfService: '/terms-of-service',
};

export const Public: React.FC = () => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<div className={classes.sidebar}>
				<Logo className={classes.logo} />
			</div>
			<div className={classes.main}>
				<Container component="main" maxWidth="xs" className={classes.container}>
					<Paper className={classes.paper}>
						<Switch>
							<Route path={PublicPaths.login}>{<LoginForm />}</Route>
						</Switch>
						<Switch>
							<Route path={PublicPaths.registration}>{<Registration />}</Route>
						</Switch>
						<Switch>
							<Route path={PublicPaths.requestPasswordReset}>{<RequestPasswordResetForm />}</Route>
						</Switch>
						<Switch>
							<Route path={PublicPaths.passwordResetLinkSent}>{<PasswordResetSent />}</Route>
						</Switch>
						<Switch>
							<Route path={PublicPaths.passwordReset}>{<PasswordReset />}</Route>
						</Switch>
						<Switch>
							<Route path={PublicPaths.termsOfService}>{<TermsOfService />}</Route>
						</Switch>
					</Paper>
				</Container>
				<Box className={classes.copyright}>{<Legal />}</Box>
			</div>
		</div>
	);
};
