import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	open: boolean;
	onCancel: () => void;
	onPublish: () => Promise<void>;
};
export const PublishDialog: React.FC<Props> = ({ open, onCancel, onPublish }) => {
	const { t } = useTranslation('templates');
	const { t: common } = useTranslation('common');
	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth="xs"
			aria-labelledby="confirmation-dialog-title"
			open={open}
		>
			<DialogTitle id="confirmation-dialog-title">{t('publishDialog.title')}</DialogTitle>
			<DialogContent dividers={false}>
				<DialogContentText>{t('publishDialog.info')}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={onCancel} color="primary">
					{common('actions.cancel')}
				</Button>
				<Button variant="contained" color="primary" onClick={onPublish}>
					{t('publishDialog.publish')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
