import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
const Root = styled.div({
	flex: 1,
	display: 'flex',
	alignSelf: 'center',
	justifyContent: 'center',
});

export const LoadingIndicator: React.FC = () => (
	<Root>
		<CircularProgress />
	</Root>
);
