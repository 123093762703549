import { Grid, InputLabel, Typography } from '@material-ui/core';
import styled from 'styled-components';
import {
	ControllerRenderProps,
	ControllerFieldState,
	UseFormStateReturn,
	FieldValues,
	FieldPath,
} from 'react-hook-form';

import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icons } from '@thingos/design-icons';
import { useInputStyle } from '../library';
import { useTranslation } from 'react-i18next';

const Root = styled(Grid).attrs({ container: true, spacing: 1 })<{ error: boolean }>(props => ({
	position: 'relative',
	width: '100%',
	aspectRatio: '1',
	alignContent: 'center',
	justifyContent: 'center',
	backgroundColor: '#F5F5F5',
	borderRadius: '8px',
	border: '2px solid',
	borderColor: props.error ? 'rgb(244, 67, 54)' : '#DCDCDC',
	overflow: 'hidden',
}));

const Image = styled.img({
	position: 'absolute',
	width: '100%',
	height: '100%',
	objectFit: 'cover',
	backgroundColor: 'white',
	':hover': {
		opacity: 0.1,
	},
});

type ImageUploadProps<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
	defaultSrc?: string;
	accept?: string;
	label: string;
	field: ControllerRenderProps<TFieldValues, TName>;
	fieldState: ControllerFieldState;
	formState: UseFormStateReturn<TFieldValues>;
} & React.HTMLAttributes<HTMLDivElement>;

export function ImageUpload<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
	props: ImageUploadProps<TFieldValues, TName>
): ReactElement<ImageUploadProps<TFieldValues, TName>> {
	const { t } = useTranslation('common');
	const [preview, setPreview] = useState<string | undefined>(props.defaultSrc);
	const inputClasses = useInputStyle();

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			console.log('accepted file');
			if (acceptedFiles[0] != null) {
				setPreview(URL.createObjectURL(acceptedFiles[0]));
				props.field.onChange(acceptedFiles[0]);
			}
		},
		[setPreview, props.field]
	);
	const { getRootProps, getInputProps } = useDropzone({
		accept: props.accept || 'image/*',
		onDrop,
		maxFiles: 1,
	});
	const previewColor = useMemo(() => (preview == null ? '#DCDCDC' : '#A9A9A9'), [preview]);
	return (
		<div {...props}>
			<InputLabel
				className={inputClasses.label}
				shrink
				style={{ marginBottom: 8, marginLeft: -8 }}
				error={props.fieldState.error != null}
			>
				{props.label}
			</InputLabel>
			<Root {...getRootProps()} error={props.fieldState.error != null}>
				<input {...getInputProps()} />
				<Icons kind="ui/documentImage" width="48" height="48" color={previewColor} />
				<Typography variant="subtitle1" style={{ color: previewColor }} align="center">
					{t('forms.imageSelection')}
				</Typography>
				{preview != null && <Image src={preview} />}
			</Root>
		</div>
	);
}
