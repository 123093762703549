import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteDialog } from './DeleteDialog';

type Props = {
	open: boolean;
	onCancel: () => void;
	onDelete: () => Promise<void>;
};
export const DeleteTemplateDialog: React.FC<Props> = props => {
	const { t } = useTranslation('templates');
	return <DeleteDialog {...props} title={t('deleteDialog.title')} info={t('deleteDialog.info')} />;
};
