/**
 * Root page of the private routes, making sure the user is loged in.
 */

import { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { PublicPaths as publicPaths } from './public';
import { StoreContext } from '../stores';
import { NavbarSidebar } from '../components';
import styled from 'styled-components';
import { TemplatesPage } from './Templates';
import { NewTemplate } from './NewTemplate';
import { Companies } from './Companies';
import { UsersPage } from './Users';
import { EditTemplate } from './EditTemplate';
import { SettingsPage } from './Settings';
import { AdminPage } from './Admins';
import { NetworkCodes } from './NetworkCodes';
import { TemplateInfoPage } from './TemplateInfo';
import { LoadingIndicator } from '../components/LoadingIndicator';

export enum PrivatePath {
	templates = '/:companyId/templates',
	template = '/template',
	newTemplate = '/new-template',
	editTemplate = '/edit-template',
	companies = '/companies',
	users = '/:companyId/users',
	admin = '/admin',
	settings = '/settings',
	networkCodes = '/:templateId/network-codes',
}

const RootView = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'row',
});

export const Root: React.FC = observer(function Root() {
	const {
		accountStore: { checking, loggedIn },
	} = useContext(StoreContext);
	// Make sure the user is logged in before going to the content pages.
	if (checking) {
		return <LoadingIndicator />;
	} else if (!loggedIn) {
		return <Redirect to={publicPaths.login} />;
	}
	return (
		<RootView>
			<NavbarSidebar />
			<Switch>
				<Route path={PrivatePath.newTemplate}>
					<NewTemplate />
				</Route>
				<Route path={PrivatePath.editTemplate}>
					<EditTemplate />
				</Route>
				<Route path={PrivatePath.companies}>
					<Companies />
				</Route>
				<Route path={PrivatePath.users}>
					<UsersPage />
				</Route>
				<Route path={PrivatePath.templates}>
					<TemplatesPage />
				</Route>
				<Route path={PrivatePath.template}>
					<TemplateInfoPage />
				</Route>
				<Route path={PrivatePath.networkCodes}>
					<NetworkCodes />
				</Route>
				<Route path={PrivatePath.settings}>
					<SettingsPage />
				</Route>
				<Route path={PrivatePath.admin}>
					<AdminPage />
				</Route>
			</Switch>
		</RootView>
	);
});
