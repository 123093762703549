import { Link as MaterialLink, Grid, makeStyles } from '@material-ui/core';

import { Link } from '@thingos/thingos-components';
import { PublicPaths } from '../pages';

const useStyles = makeStyles({
	root: {
		align: 'center',
		margin: 'auto',
		maxWidth: '25rem',
	},
	item: {
		textAlign: 'center',
		width: 'auto',
	},
	copyright: {
		color: '#bdbdbd',
	},
});

export const Legal: React.FC = () => {
	const classes = useStyles();
	return (
		<Grid container spacing={1} className={classes.root}>
			<Grid item xs={4} className={classes.item}>
				<Link color="inherit" to={PublicPaths.termsOfService}>
					{'Terms of Service'}
				</Link>
			</Grid>
			<Grid item xs={3} className={classes.item}>
				<MaterialLink color="inherit" href="mailto:hcm-auto-setup@thingos.io">
					{'Support'}
				</MaterialLink>
			</Grid>
			<Grid item xs={5} className={classes.item}>
				<Link color="inherit" to="https://thingos.io/" className={classes.copyright}>
					{`© ${new Date().getFullYear()} ThingOS GmbH`}
				</Link>
			</Grid>
		</Grid>
	);
};
