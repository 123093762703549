import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useCallback, useMemo, useState } from 'react';

import { TextField, TextFieldProps } from './TextField';

type FormikPasswordFieldProps = Omit<TextFieldProps, 'type'> & {
	state?: {
		visible: boolean;
		setVisibility: (visible: boolean) => void;
		showButton: boolean;
	};
};

const useStyles = makeStyles({
	visiblityButton: {
		position: 'absolute',
		right: '0px',
	},
});

/**
 * Wrapper for text field to have password entry.
 */
export const PasswordField: React.FC<FormikPasswordFieldProps> = ({ state, ...rest }) => {
	const classes = useStyles();
	const [visiblityInternal, setVisibilityInternal] = useState(false);
	const visible = useMemo(() => {
		return state != null ? state.visible : visiblityInternal;
	}, [state, visiblityInternal]);
	const setVisibility = useCallback(
		(visible: boolean) => {
			return state != null ? state.setVisibility(visible) : setVisibilityInternal(visible);
		},
		[state, setVisibilityInternal]
	);
	const showButton = useMemo(() => {
		if (state != null) {
			return state.showButton;
		}
		return true;
	}, [state]);
	return (
		<div>
			<TextField {...rest} type={visible ? 'text' : 'password'} />
			{showButton &&
				(visible ? (
					<IconButton
						aria-label="hide password"
						className={classes.visiblityButton}
						onClick={() => setVisibility(false)}
					>
						<VisibilityOff />
					</IconButton>
				) : (
					<IconButton
						aria-label="show password"
						className={classes.visiblityButton}
						onClick={() => setVisibility(true)}
					>
						<Visibility />
					</IconButton>
				))}
		</div>
	);
};
