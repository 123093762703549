import { Button, Grid, makeStyles, Popover, Typography } from '@material-ui/core';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Cell, Column, useFlexLayout, useTable } from 'react-table';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { TableHeaderCell, TableRowCell } from '@thingos/thingos-components';

import { ActionGroup } from '../components/ActionGroup';
import { TableRow } from '../components/TableRow';
import { Header } from '../components/Header';
import { StoreContext } from '../stores';
import { TableBody, TableHeader } from './Companies';
import { DefaultRedirect } from './DefaultRedirect';
import { Empty } from '../components/Empty';
import { AdminInfo, AdminsStore } from '../stores/adminsStore';
import { AddAdminForm } from '../components/AddAdminForm';
import { useSnackbar } from 'notistack';
import { RemoveAdminDialog } from '../components/RemoveAdminDialog';
import { useTranslation } from 'react-i18next';
import { DefaultCellRenderer } from '../components/TableCellRenderer';
import { RemoveButton } from '../components/RemoveButton';

const Page = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
});

const useStyles = makeStyles(theme => ({
	popover: {
		marginTop: theme.spacing(2),
		maxWidth: '400px',
	},
	progress: {
		width: '100%',
		position: 'absolute',
		borderTopLeftRadius: 2,
		borderTopRightRadius: 2,
		top: 0,
	},
}));

type GroupProps = {
	name: string;
};
export const Group: React.FC<GroupProps> = ({ name }) => {
	return (
		<Grid item xs={12}>
			<Typography variant="h5">{name}</Typography>
		</Grid>
	);
};

const RemoveAdmin: React.FC<{ cell: Cell<AdminInfo, string> }> = ({ cell }): JSX.Element => {
	const { t } = useTranslation('admins');
	const admin = cell.row.original;
	const [removing, setRemoving] = React.useState(false);
	const { adminsStore } = useContext(StoreContext);
	const { enqueueSnackbar } = useSnackbar();
	const [deleteDialogIsOpen, setDeleteDialogState] = useState(false);
	const onHandleDialogOk = React.useCallback(
		async password => {
			setDeleteDialogState(false);
			if (await adminsStore.removeAdmin(admin, password)) {
				enqueueSnackbar(t('removeForm.success', { email: admin.email }), { variant: 'success' });
			} else {
				enqueueSnackbar(t('removeForm.failed'), { variant: 'error' });
			}
			setRemoving(false);
		},
		[adminsStore, admin, setDeleteDialogState, enqueueSnackbar, setRemoving, t]
	);

	const onHandleDialogCancel = React.useCallback(() => {
		setDeleteDialogState(false);
		setRemoving(false);
	}, [setDeleteDialogState]);

	const onClickDelete = React.useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			setRemoving(true);
			setDeleteDialogState(true);
		},
		[setDeleteDialogState, setRemoving]
	);

	return (
		<Fragment>
			<ActionGroup>
				<RemoveButton
					variant="contained"
					color="primary"
					onClick={onClickDelete}
					inProgress={removing}
				/>
			</ActionGroup>
			<RemoveAdminDialog
				open={deleteDialogIsOpen}
				onCancel={onHandleDialogCancel}
				onDelete={onHandleDialogOk}
			/>
		</Fragment>
	);
};

const InvitedItalicsWrapper: React.FC<{ cell: Cell<AdminInfo, string> }> = observer(
	({ cell }): JSX.Element => (
		<Typography variant="body2">
			{cell.row.original.invitePending ? <i>{cell.value}</i> : cell.value}
		</Typography>
	)
);

type Props = {
	adminsStore: AdminsStore;
};
export const Admins: React.FC<Props> = observer(function Admins({ adminsStore }) {
	const { t } = useTranslation('admins');
	const styles = useStyles();

	const columns = useMemo<Column<AdminInfo>[]>(
		() => [
			{
				Header: t('emailHeader'),
				accessor: 'email',
				Cell: InvitedItalicsWrapper,
				width: 100,
			},
			{
				Header: t('nameHeader'),
				Cell: InvitedItalicsWrapper,
				accessor: adminData => (adminData.invitePending ? t('invitePending') : adminData.name),
				width: 100,
			},
			{
				Header: '',
				id: 'action-group',
				Cell: RemoveAdmin,
				accessor: adminData => adminData,
				width: 150,
				canResize: false,
			},
		],
		[t]
	);

	const { getTableProps, headerGroups, rows: adminRows, prepareRow } = useTable(
		{
			defaultColumn: {
				Cell: DefaultCellRenderer,
			},
			columns,
			data: Array.from(adminsStore.admins.values()),
		},
		useFlexLayout
	);

	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const addUser = useCallback(
		(event: React.MouseEvent) => {
			setAnchorEl(event.currentTarget as Element);
		},
		[setAnchorEl]
	);

	const handleClose = useCallback(() => {
		setAnchorEl(null);
	}, [setAnchorEl]);
	const open = Boolean(anchorEl);
	const id = open ? 'add-company-popover' : undefined;
	return (
		<Page {...getTableProps()}>
			<Header
				title={t('title')}
				actionGroup={
					<Fragment>
						<Button aria-describedby={id} variant="contained" color="primary" onClick={addUser}>
							Add admin
						</Button>
						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							PaperProps={{ className: styles.popover }}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						>
							<AddAdminForm done={handleClose} adminsStore={adminsStore} />
						</Popover>
					</Fragment>
				}
				subHeader={
					adminsStore.admins.size === 0 ? undefined : (
						<TableHeader>
							{headerGroups.map((headerGroup, index) => (
								<div
									{...(headerGroup.getHeaderGroupProps({
										// style: { paddingRight: '15px' },
									}) as any)}
									className="tr"
									key={index}
								>
									{headerGroup.headers.map((column, _index) => {
										return (
											<TableHeaderCell {...(column.getHeaderProps() as any)} key={column.id}>
												{column.render('Header')}
											</TableHeaderCell>
										);
									})}
								</div>
							))}
						</TableHeader>
					)
				}
			/>

			{adminsStore.admins.size === 0 ? (
				<Empty text={t('noAdmins')} />
			) : (
				<TableBody>
					{adminRows.map(row => {
						prepareRow(row);
						return (
							<TableRow {...(row.getRowProps() as any)} key={row.id}>
								{row.cells.map((cell, index) => {
									return (
										<TableRowCell {...(cell.getCellProps() as any)} key={index}>
											{cell.render('Cell')}
										</TableRowCell>
									);
								})}
							</TableRow>
						);
					})}
				</TableBody>
			)}
		</Page>
	);
});

export const AdminPage: React.FC = observer(function AdminPage() {
	const { adminsStore, accountStore } = useContext(StoreContext);
	useEffect(() => {
		adminsStore.loadAdmins();
	}, [adminsStore]);
	if (!accountStore.isAdmin) {
		return <DefaultRedirect />;
	}
	return <Admins adminsStore={adminsStore} />;
});
