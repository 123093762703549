import { Grid, styled } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useCallback, useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isInt from 'validator/lib/isInt';

import { TextField } from '../library';
import { NewBatchData } from '../models/batch';
import { Template } from '../models/template';
import { SubmitButton } from './SubmitButton';

const useStyles = makeStyles(theme => ({
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	progress: {
		width: '100%',
		position: 'absolute',
		borderTopLeftRadius: 2,
		borderTopRightRadius: 2,
		top: 0,
	},
}));

const FormGrid = styled(Grid)({ justifyContent: 'center' });

type Props = {
	template: Template;
	done: () => void;
};

export const NewBatchForm: React.FC<Props> = ({ template, done }) => {
	const { t } = useTranslation('templates');
	const { t: common } = useTranslation('common');
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const onAdd = useCallback(
		async (batchData: NewBatchData) => {
			if (await template.addBatch(batchData)) {
				done();
			} else {
				enqueueSnackbar(t('batch.failure'), { variant: 'error' });
			}
		},
		[done, enqueueSnackbar, template, t]
	);
	const formMethods = useForm<NewBatchData>({ mode: 'all' });
	const { handleSubmit, control } = formMethods;
	const amountInputRef = useRef<HTMLInputElement>();
	const purposeInputRef = useRef<HTMLInputElement>();

	return (
		<FormProvider {...formMethods}>
			<form className={classes.form} onSubmit={handleSubmit(onAdd)} style={{ padding: '16px' }}>
				<FormGrid container>
					<Grid item xs={12}>
						<Controller
							name="customer"
							control={control}
							defaultValue=""
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<TextField
									label={t('batch.customer')}
									placeholder={t('batch.customer')}
									fullWidth
									autoFocus
									value={value}
									onChange={onChange}
									error={!!error}
									helperText={error?.message || ''}
									nextInputOnEnter={amountInputRef}
								/>
							)}
							rules={{
								required: common('forms.required'),
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="amount"
							control={control}
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<TextField
									label={t('batch.amount')}
									placeholder={t('batch.amount')}
									fullWidth
									type="number"
									InputProps={{ inputProps: { min: 1, max: 10000 } }}
									inputRef={ref => (amountInputRef.current = ref)}
									value={isNaN(value) || value === 0 ? '' : value.toString()}
									onChange={e => {
										const output = parseInt(e.target.value, 10);
										onChange(isNaN(output) ? 0 : output);
									}}
									error={!!error}
									helperText={error?.message || ''}
									nextInputOnEnter={purposeInputRef}
								/>
							)}
							rules={{
								validate: amount => {
									if (!amount) {
										return common('forms.required');
									} else if (!isInt(amount + '')) return t('batch.invalidAmount');
									return undefined;
								},
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<Controller
							name="purposeNotes"
							control={control}
							defaultValue=""
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<TextField
									label={t('batch.purposeNote')}
									placeholder={t('batch.purposeNote')}
									fullWidth
									inputRef={ref => (purposeInputRef.current = ref)}
									value={value}
									onChange={onChange}
									error={!!error}
									helperText={error?.message || ''}
								/>
							)}
						/>
					</Grid>
				</FormGrid>
				<SubmitButton>{t('batch.add')}</SubmitButton>
			</form>
		</FormProvider>
	);
};
