import { StatusCodes } from 'http-status-codes';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import * as TE from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';

import {
	LoginData,
	UserInfo,
	PasswordChangeRequest,
	NameChangeRequest,
	DeleteAccountRequest,
} from '@thingos/m4i-webservice-shared';

import { PrivatePath } from '../pages';
import { CompanyStore } from './companyStore';
import { api } from '../config';

type LoginResult =
	| {
			success: true;
			redirect: PrivatePath;
	  }
	| {
			success: false;
			message: string;
	  };

export class AccountStore {
	checking = true;
	loggedIn = false;
	userInfo: UserInfo | null = null;

	get isAdmin(): boolean {
		return this.userInfo?.isAdmin || false;
	}

	get isCompanyAdminOfSelectedCompany(): boolean {
		if (this.companyStore.selectedCompany != null && this.userInfo != null) {
			return (
				this.companyStore.selectedCompany.adminRoles.find(
					adminRole => adminRole.user.id === this.userInfo!.id
				) != null
			);
		}
		return false;
	}

	get showCompaniesInNavbar(): boolean {
		return this.isAdmin || this.companyStore.companies.size > 1;
	}

	get showUsersInNavbar(): boolean {
		if (this.companyStore.selectedCompany != null) {
			return this.isCompanyAdminOfSelectedCompany || this.isAdmin;
		} else {
			return false;
		}
	}

	public constructor(private companyStore: CompanyStore) {
		makeObservable(this, {
			checking: observable,
			loggedIn: observable,
			userInfo: observable,
			isAdmin: computed,
			isCompanyAdminOfSelectedCompany: computed,
			showCompaniesInNavbar: computed,
			login: action,
			logout: action,
			checkLogin: action,
		});

		void this.checkLogin();
	}

	public async login(loginData: LoginData): Promise<LoginResult> {
		this.checking = true;
		const response = await fetch(api + '/login', {
			method: 'POST',
			credentials: 'include',
			mode: 'cors',
			headers: new Headers({
				'Content-Type': 'application/json',
			}),
			body: JSON.stringify(loginData),
		});
		if (response.status === StatusCodes.OK) {
			const userInfo = await response.json();
			runInAction(() => {
				this.userInfo = observable.object(userInfo);
				this.checking = false;
				this.loggedIn = true;
			});
			return {
				success: true,
				redirect: PrivatePath.companies,
			};
		} else {
			runInAction(() => {
				this.userInfo = null;
				this.checking = false;
				this.loggedIn = false;
			});
			if (response.status === StatusCodes.UNAUTHORIZED) {
				return {
					success: false,
					message: 'No such user found',
				};
			} else if (response.status === StatusCodes.FORBIDDEN) {
				return {
					success: false,
					message: 'Wrong password',
				};
			} else {
				return {
					success: false,
					message: 'Server error, try again later',
				};
			}
		}
	}

	public async deleteAccount(password: string): Promise<boolean> {
		return pipe(
			TE.tryCatch(
				() =>
					fetch(api + '/account', {
						credentials: 'include',
						mode: 'cors',
						method: 'DELETE',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(DeleteAccountRequest.encode({ password })),
					}),
				() => 'Failed to delte account'
			),
			TE.match(
				error => {
					console.error(error);
					return false;
				},
				response => {
					if (response.status === StatusCodes.OK) {
						return true;
					} else {
						return false;
					}
				}
			)
		)();
	}

	public async changePassword(password: string, newPassword: string): Promise<boolean> {
		return pipe(
			TE.tryCatch(
				() =>
					fetch(api + '/password', {
						credentials: 'include',
						mode: 'cors',
						method: 'POST',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(PasswordChangeRequest.encode({ password, newPassword })),
					}),
				() => 'Failed to change password'
			),
			TE.match(
				error => {
					console.error(error);
					return false;
				},
				response => {
					if (response.status === StatusCodes.OK) {
						return true;
					} else {
						return false;
					}
				}
			)
		)();
	}

	public async changeName(name: string): Promise<boolean> {
		return pipe(
			TE.tryCatch(
				() =>
					fetch(api + '/name', {
						credentials: 'include',
						mode: 'cors',
						method: 'POST',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(NameChangeRequest.encode({ name })),
					}),
				() => 'Failed to change name'
			),
			TE.match(
				error => {
					console.error(error);
					return false;
				},
				response => {
					if (response.status === StatusCodes.OK) {
						runInAction(() => {
							this.userInfo = { ...this.userInfo!, name };
						});
						return true;
					} else {
						return false;
					}
				}
			)
		)();
	}

	public async logout(): Promise<boolean> {
		await fetch(api + '/logout', {
			method: 'POST',
			credentials: 'include',
			mode: 'cors',
		});
		runInAction(() => {
			this.loggedIn = false;
			this.userInfo = null;
		});
		return true;
	}

	async checkLogin(): Promise<void> {
		this.checking = true;
		const response = await fetch(api + '/user', {
			credentials: 'include',
			mode: 'cors',
			method: 'GET',
		});
		if (response.status === StatusCodes.OK) {
			const userInfo = await response.json();
			runInAction(() => {
				this.userInfo = observable.object(userInfo);
				this.loggedIn = true;
				this.checking = false;
			});
		} else {
			runInAction(() => {
				this.userInfo = null;
				this.loggedIn = false;
				this.checking = false;
			});
		}
	}
}
