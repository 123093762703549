import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteDialog } from './DeleteDialog';

type Props = {
	open: boolean;
	onCancel: () => void;
	onDelete: () => Promise<void>;
};
export const DeleteBatchDialog: React.FC<Props> = props => {
	const { t } = useTranslation('templates');
	return (
		<DeleteDialog
			{...props}
			title={t('deleteBatchDialog.title')}
			info={t('deleteBatchDialog.info')}
		/>
	);
};
