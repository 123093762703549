import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/lib/TaskEither';
import * as E from 'fp-ts/lib/Either';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { PathReporter } from 'io-ts/lib/PathReporter';

import {
	AddTagRequest,
	BatchDeletionRequest,
	DeleteTagRequest,
	BatchInfo,
	GetBatchesResponse,
	TagInfo,
	TagId,
} from '@thingos/m4i-webservice-shared';

import { api } from '../config';
import { Batch, NewBatchData } from './batch';
import { StatusCodes } from 'http-status-codes';
import { CompanyStore } from '../stores/companyStore';

export type NewTemplateData = {
	name?: string;
	version?: string;
	vendor?: string;
	imageUrl?: string;
	reference?: string;
	description?: string | null;
	connectFileName?: string;
	connectFileSize?: number;
};

export enum BatchState {
	uninitialized = 'uninitialized',
	loading = 'loading',
	loaded = 'loaded',
	reloading = 'reloading',
}

export type TemplateData = NewTemplateData & {
	id?: string;
	isDraft: boolean;
	updatedAt?: Date;
	tagIds?: Iterable<TagId>;
};

export class Template implements TemplateData {
	id?: string;
	name?: string;
	version?: string;
	vendor?: string;
	imageUrl?: string;
	connectFileName?: string;
	connectFileSize?: number;
	reference?: string;
	description?: string;
	updatedAt?: Date;
	isDraft: boolean;
	batchState: BatchState = BatchState.uninitialized;
	tags: Set<TagId> = new Set<TagId>();

	batches: Map<string, Batch> = observable.map();

	constructor(templateData: TemplateData) {
		this.id = templateData.id;
		this.name = templateData.name;
		this.version = templateData.version;
		this.vendor = templateData.vendor;
		this.reference = templateData.reference;
		this.description = templateData.description || undefined;
		this.imageUrl = templateData.imageUrl;
		this.connectFileName = templateData.connectFileName;
		this.connectFileSize = templateData.connectFileSize;
		this.updatedAt = templateData.updatedAt;
		this.isDraft = templateData.isDraft;

		makeObservable(this, {
			batches: observable,
			batchState: observable,
			tags: observable.shallow,
			addBatch: action,
			loadBatches: action.bound,
			addTag: action.bound,
			removeTag: action.bound,
		});
		runInAction(() => (this.tags = new Set<TagId>(templateData.tagIds || [])));
	}

	get isValid(): boolean {
		if (this.name == null || this.name.length === 0) {
			return false;
		}
		if (this.vendor == null || this.vendor.length === 0) {
			return false;
		}
		if (this.imageUrl == null || this.imageUrl.length === 0) {
			return false;
		}
		if (this.reference == null || this.reference.length === 0) {
			return false;
		}
		if (this.description == null || this.description.length === 0) {
			return false;
		}
		return true;
	}

	async loadBatches(): Promise<void> {
		if (this.batchState === BatchState.uninitialized) {
			this.batchState = BatchState.loading;
		} else {
			this.batchState = BatchState.reloading;
		}
		return pipe(
			TE.tryCatch(
				async () =>
					fetch(api + `/batches/${this.id!}`, {
						method: 'GET',
						credentials: 'include',
						mode: 'cors',
					}),
				() => 'Failed to get batches'
			),
			TE.chain(response => {
				console.log(response);
				if (response.status === StatusCodes.OK) {
					return pipe(
						TE.tryCatch(
							() => response.json(),
							() => 'Could not get json body of response'
						),
						TE.chain(response =>
							pipe(
								GetBatchesResponse.decode(response),
								E.mapLeft(() => 'Could not decode response'),
								TE.fromEither
							)
						)
					);
				}
				return TE.left('Error');
			}),
			TE.match(
				e => {
					console.log(e);
					return;
				},
				batches => {
					runInAction(() => {
						this.batches = observable.map(
							batches.map(batchInfo => [batchInfo.id, new Batch(batchInfo)])
						);
					});
					return;
				}
			)
		)().then(() =>
			runInAction(() => {
				this.batchState = BatchState.loaded;
			})
		);
	}

	async addBatch(batch: NewBatchData): Promise<boolean> {
		return pipe(
			TE.tryCatch(
				async () =>
					fetch(api + '/batches', {
						method: 'POST',
						credentials: 'include',
						mode: 'cors',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify({ ...batch, templateId: this.id! }),
					}),
				() => 'Failed to post batch'
			),
			TE.chain(response => {
				console.log(response);
				if (response.status === StatusCodes.OK) {
					return pipe(
						TE.tryCatch(
							() => response.json(),
							() => 'Could not get json body of response'
						),
						TE.chain(response =>
							pipe(
								BatchInfo.decode(response),
								E.mapLeft(e => PathReporter.report(E.left(e)).join('\n')),
								TE.fromEither
							)
						)
					);
				}
				return TE.left('Error');
			}),
			TE.match(
				e => {
					console.log(e);
					return false;
				},
				batchInfo => {
					runInAction(() => {
						this.batches.set(batchInfo.id, new Batch(batchInfo));
					});
					return true;
				}
			)
		)();
	}

	async removeBatch(batchId: string): Promise<boolean> {
		return pipe(
			TE.tryCatch(
				async () =>
					fetch(api + '/batch', {
						method: 'DELETE',
						credentials: 'include',
						mode: 'cors',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(BatchDeletionRequest.encode({ batchId })),
					}),
				() => 'Failed to delete batch'
			),
			TE.chain(response => {
				if (response.status === StatusCodes.OK) {
					return TE.right(true);
				}
				return TE.left('Bad status code for deletion request');
			}),
			TE.match(
				e => {
					console.log(e);
					return false;
				},
				() => {
					runInAction(() => {
						this.batches.delete(batchId);
					});
					return true;
				}
			)
		)();
	}

	async addTag(
		tag: Omit<AddTagRequest, 'templateId'>,
		companyStore: CompanyStore
	): Promise<boolean> {
		return pipe(
			TE.tryCatch(
				async () =>
					fetch(api + '/tags', {
						method: 'POST',
						credentials: 'include',
						mode: 'cors',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(AddTagRequest.encode({ ...tag, templateId: this.id! })),
					}),
				() => 'Failed to post tag'
			),
			TE.chain(response => {
				console.log(response);
				if (response.status === StatusCodes.OK) {
					return pipe(
						TE.tryCatch(
							() => response.json(),
							() => 'Could not get json body of response'
						),
						TE.chain(response =>
							pipe(
								TagInfo.decode(response),
								E.mapLeft(e => PathReporter.report(E.left(e)).join('\n')),
								TE.fromEither
							)
						)
					);
				}
				return TE.left('Error');
			}),
			TE.match(
				e => {
					console.log(e);
					return false;
				},
				tagInfo => {
					companyStore.addTagToCompany(tagInfo);
					runInAction(() => {
						this.tags.add(tagInfo.id);
					});
					return true;
				}
			)
		)();
	}

	async removeTag(id: TagId): Promise<boolean> {
		return pipe(
			TE.tryCatch(
				async () =>
					fetch(api + '/tag', {
						method: 'DELETE',
						credentials: 'include',
						mode: 'cors',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(DeleteTagRequest.encode({ id, templateId: this.id! })),
					}),
				() => 'Failed to delete tag'
			),
			TE.chain(response => {
				if (response.status === StatusCodes.OK) {
					return TE.right(true);
				}
				return TE.left('Bad status code for deletion request');
			}),
			TE.match(
				e => {
					console.log(e);
					return false;
				},
				() => {
					runInAction(() => {
						this.tags.delete(id);
					});
					return true;
				}
			)
		)();
	}
}
