import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText,
} from '@material-ui/core';
import React from 'react';

import { PasswordField } from '../library';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SubmitButton } from './SubmitButton';

type PasswordFormData = {
	password: string;
};

type Props = {
	title: string;
	info: string;
	open: boolean;
	onCancel: () => void;
	onDelete: (password: string) => Promise<void>;
};
export const ConfirmPasswordDialog: React.FC<Props> = ({
	title,
	info,
	open,
	onDelete,
	onCancel,
}) => {
	const { t } = useTranslation('common');
	const onDeletion = React.useCallback(async ({ password }) => onDelete(password), [onDelete]);
	const formMethods = useForm<PasswordFormData>({
		mode: 'all',
		defaultValues: {
			password: '',
		},
	});
	const { handleSubmit, control } = formMethods;
	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth="xs"
			aria-labelledby="confirmation-dialog-title"
			open={open}
		>
			<DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
			<FormProvider {...formMethods}>
				<form onSubmit={handleSubmit(onDeletion)}>
					<DialogContent dividers={false}>
						<DialogContentText>{info}</DialogContentText>
						<Controller
							name="password"
							control={control}
							defaultValue=""
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<PasswordField
									label={t('forms.passwordLabel')}
									placeholder={t('forms.passwordPlaceholder')}
									fullWidth
									autoComplete="password"
									autoFocus
									value={value}
									onChange={onChange}
									error={!!error}
									helperText={error?.message || ''}
								/>
							)}
							rules={{
								required: t('forms.required'),
							}}
						/>
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={onCancel} color="primary">
							{t('actions.cancel')}
						</Button>

						<SubmitButton>{t('actions.remove')}</SubmitButton>
					</DialogActions>
				</form>
			</FormProvider>
		</Dialog>
	);
};
