import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextField } from '../library';
import { Company } from '../models/company';
import { StoreContext } from '../stores';
import { DeleteCompanyDialog } from '../components/DeleteCompanyDialog';
import { ImageUpload } from './ImageUpload';
import { ColorPicker } from './ColorPicker';
import { SubmitButton } from './SubmitButton';
import { observer } from 'mobx-react';
import { RemoveButton } from './RemoveButton';

const useStyles = makeStyles(theme => ({
	submit: {
		margin: theme.spacing(1, 0, 1),
	},
	form: {
		marginTop: theme.spacing(1),
	},
	progress: {
		width: '100%',
		position: 'absolute',
		borderTopLeftRadius: 2,
		borderTopRightRadius: 2,
		top: 0,
	},
}));

export type CompanyEdit = {
	name: string;
	highlightColor: string;
	image?: File;
};

type Props = {
	done: () => void;
	company: Company;
};

export const EditCompanyForm: React.FC<Props> = observer(function EditCompanyForm({
	company,
	done,
}) {
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const { companyStore } = useContext(StoreContext);
	const { t } = useTranslation('companies');

	const onUpdate = useCallback(
		async (companyData: CompanyEdit) => {
			if (await companyStore.updateCompany(company, companyData)) {
				done();
			} else {
				enqueueSnackbar(t('editCompany.editFailure'), { variant: 'error' });
			}
		},
		[done, enqueueSnackbar, companyStore, company, t]
	);
	const [removing, setRemoving] = useState(false);

	const formMethods = useForm<CompanyEdit>({
		mode: 'all',
		defaultValues: { name: company.name, highlightColor: company.highlightColor },
	});
	const { handleSubmit, control } = formMethods;

	const [deleteDialogIsOpen, setDeleteDialogState] = useState(false);

	const onHandleDialogOk = useCallback(
		async password => {
			setDeleteDialogState(false);
			setRemoving(true);
			if (await companyStore.deleteCompany(company, password)) {
				done();
			} else {
				enqueueSnackbar(t('editCompany.deleteFailure'), { variant: 'error' });
			}
			setRemoving(false);
		},
		[companyStore, company, setDeleteDialogState, done, enqueueSnackbar, t]
	);

	const onHandleDialogCancel = useCallback(() => {
		setDeleteDialogState(false);
	}, [setDeleteDialogState]);

	const onClickDelete = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			setDeleteDialogState(true);
		},
		[setDeleteDialogState]
	);

	const defaultSrc = company.companyLogo;

	return (
		<FormProvider {...formMethods}>
			<form
				className={classes.form}
				onSubmit={handleSubmit(onUpdate)}
				style={{ padding: '16px' }}
				onClick={event => event.stopPropagation()}
			>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Controller
							name="name"
							control={control}
							defaultValue=""
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<TextField
									label={t('newCompany.companyNameLabel')}
									placeholder={t('newCompany.companyNamePlaceholder')}
									fullWidth
									autoFocus
									value={value}
									onChange={onChange}
									error={!!error}
									helperText={error?.message || ''}
								/>
							)}
							rules={{
								required: t('newCompany.required'),
							}}
						/>
					</Grid>
					<Grid item xs={6} alignItems="center">
						<Controller
							name="image"
							control={control}
							render={props => (
								<ImageUpload
									label={t('newCompany.companyLogo')}
									style={{ padding: '8px' }}
									defaultSrc={defaultSrc}
									accept="image/svg+xml"
									{...props}
								/>
							)}
						/>
						<Typography variant="body2" color="textSecondary">
							{t('newCompany.logoInstructions')}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Controller
							name="highlightColor"
							control={control}
							render={({ field: { value, onChange } }) => (
								<ColorPicker
									label={t('newCompany.highlightColor')}
									value={value}
									onChange={onChange}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Grid container direction="row" spacing={1}>
							<Grid item>
								<RemoveButton variant="contained" onClick={onClickDelete} inProgress={removing} />
							</Grid>
							<Grid item>
								<SubmitButton>{t('editCompany.save')}</SubmitButton>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
			<DeleteCompanyDialog
				open={deleteDialogIsOpen}
				onCancel={onHandleDialogCancel}
				onDelete={onHandleDialogOk}
			/>
		</FormProvider>
	);
});
