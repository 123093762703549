import styled from 'styled-components';

export const TableRow = styled.div({
	padding: '8px',
	boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.1)',
	backgroundColor: 'white',
	marginLeft: '-8px',
	marginRight: '-8px',
	borderRadius: '5px',
	marginBottom: '10px',

	height: '55px',
	display: 'flex',
	flex: '1 0 auto',
	position: 'relative',
	flexDirection: 'row',
	overflow: 'hidden',
});
