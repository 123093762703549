import { TextField as MUITextField, StandardTextFieldProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCallback } from 'react';

export const useInputStyle = makeStyles({
	label: {
		textTransform: 'uppercase',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
});

export type TextFieldProps = StandardTextFieldProps & {
	nextInputOnEnter?: React.MutableRefObject<HTMLInputElement | undefined>;
};

/**
 * Wrapper for text fields to follow design.
 */
export const TextField: React.FC<TextFieldProps> = ({ nextInputOnEnter, onKeyDown, ...props }) => {
	const classes = useInputStyle();
	const onKeyDownProxy = useCallback(
		(event: React.KeyboardEvent<HTMLDivElement>) => {
			if (event.key === 'Enter' && nextInputOnEnter != null) {
				nextInputOnEnter.current?.focus();
				event.stopPropagation();
			}
			if (onKeyDown != null) {
				onKeyDown(event);
			}
		},
		[nextInputOnEnter, onKeyDown]
	);
	return (
		<MUITextField
			margin="dense"
			{...props}
			onKeyDown={onKeyDownProxy}
			InputLabelProps={{
				shrink: true,
				className: classes.label,
			}}
		/>
	);
};
