import { Button, makeStyles, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { Icons } from '@thingos/design-icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Raised } from './Raised';

const Root = styled(Raised).attrs({ layer: 1 })({
	display: 'flex',
	flexDirection: 'column',
	minHeight: 150,
	justifyContent: 'space-between',
	padding: '36px 64px 0px 64px',
});

const TitleRow = styled.div({
	width: '100%',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
});

const TitleGroup = styled.div({
	display: 'flex',
	flexDirection: 'row',
	flex: 1,
});

const useStyles = makeStyles({
	root: {
		padding: '0 0px',
		marginRight: '16px',
	},
	label: {
		padding: '0px',
	},
});

type Props = {
	title: string;
	actionGroup?: React.ReactNode;
	subHeader?: React.ReactNode;
	backButton?: boolean;
};

export const Header: React.FC<Props> = ({ title, actionGroup, subHeader, backButton = false }) => {
	const classes = useStyles();
	const { goBack } = useHistory();
	return (
		<Root>
			<TitleRow>
				<TitleGroup>
					{backButton && (
						<Button variant="outlined" onClick={goBack} classes={classes}>
							<Icons kind="ui/arrowLeft" height="24" width="24" />
						</Button>
					)}
					<Typography variant="h5">{title}</Typography>
				</TitleGroup>
				{actionGroup}
			</TitleRow>
			{subHeader}
		</Root>
	);
};
