import { Button, Popover, makeStyles, ListItem } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { TableHeaderCell, TableRowCell } from '@thingos/thingos-components';

import { Header } from '../components/Header';
import { TableRow } from '../components/TableRow';
import { NewCompanyForm } from '../components';
import { StoreContext } from '../stores';
import { Cell, Column, useFlexLayout, useTable } from 'react-table';
import { Company } from '../models/company';
import { Empty } from '../components/Empty';
import { EditCompanyForm } from '../components/EditCompanyForm';
import { DefaultCellRenderer } from '../components/TableCellRenderer';
import { ActionGroup } from '../components/ActionGroup';
import { observer } from 'mobx-react';

const EditCompany: React.FC<{
	cell: Cell<Company, string>;
}> = ({ cell }): JSX.Element => {
	const styles = useStyles();
	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const { t } = useTranslation('companies');
	const [open, setOpen] = useState(false);
	const edit = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			setAnchorEl(event.currentTarget as Element);
			setTimeout(() => setOpen(true), 50);
		},
		[setAnchorEl]
	);
	const handleClose = useCallback(() => {
		setAnchorEl(null);
		setOpen(false);
	}, [setAnchorEl]);
	const id = open ? `edit-${cell.row.original.id}` : undefined;
	return (
		<ActionGroup onClick={e => e.stopPropagation()}>
			<Button variant="contained" color="primary" onClick={edit}>
				{t('companyEditButton')}
			</Button>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				PaperProps={{ className: styles.popover }}
				onClose={event => {
					(event as any).stopPropagation();
					handleClose();
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<EditCompanyForm done={handleClose} company={cell.row.original} />
			</Popover>
		</ActionGroup>
	);
};

// header
// ------------------------------
const useTableHeaderStyle = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: '2px',

		position: 'relative',
		paddingBlock: '8px',
	},
});

export interface IHeaderProps extends React.HTMLProps<HTMLDivElement> {
	inline?: boolean;
}
export const TableHeader: React.FC<IHeaderProps> = props => {
	const classes = useTableHeaderStyle(props);
	return <div className={classes.root} {...props} />;
};

// body
// ------------------------------
const useTableBodyStyle = makeStyles(_theme => ({
	root: {
		padding: '10px 64px',
		flex: 1,
		overflowY: 'auto',
	},
}));

export const TableBody: React.FC<React.HTMLProps<HTMLDivElement>> = props => {
	const classes = useTableBodyStyle(props);
	return <div className={classes.root} {...props} />;
};

const Page = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
});

const useStyles = makeStyles(theme => ({
	popover: {
		marginTop: theme.spacing(2),
		maxWidth: '500px',
	},
	row: {
		textTransform: 'none',
	},
}));

export const Companies: React.FC = observer(function Companies() {
	const { companyStore, accountStore } = useContext(StoreContext);
	const styles = useStyles();
	const history = useHistory();
	const { t } = useTranslation('companies');

	useEffect(() => {
		companyStore.loadCompanies();
	}, [companyStore]);

	const columns = useMemo<Column<Company>[]>(
		() => [
			{
				Header: t('companyNameHeader'),
				accessor: 'name',
				width: 100,
			},
			{
				Header: t('companyAdminsHeader'),
				accessor: 'adminNames',
				width: 200,
			},
			{
				Header: '',
				id: 'action-group',
				Cell: EditCompany,
				accessor: 'id',
				width: 150,
				canResize: false,
				align: 'end',
			},
		],
		[t]
	);

	const { getTableProps, headerGroups, rows, prepareRow } = useTable(
		{
			defaultColumn: {
				Cell: DefaultCellRenderer,
			},
			columns,
			data: Array.from(companyStore.companies.values()),
		},
		useFlexLayout
	);

	const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
	const addCompany = useCallback(
		(event: React.MouseEvent) => {
			setAnchorEl(event.currentTarget as Element);
		},
		[setAnchorEl]
	);

	const handleClose = React.useCallback(() => {
		setAnchorEl(null);
	}, [setAnchorEl]);
	const open = Boolean(anchorEl);
	const id = open ? 'add-company-popover' : undefined;
	return (
		<Page {...getTableProps()}>
			<Header
				title={t('title')}
				actionGroup={
					accountStore.isAdmin && (
						<div>
							<Button
								aria-describedby={id}
								variant="contained"
								color="primary"
								onClick={addCompany}
							>
								{t('newCompanyButton')}
							</Button>
							<Popover
								id={id}
								open={open}
								anchorEl={anchorEl}
								PaperProps={{ className: styles.popover }}
								onClose={handleClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
							>
								<NewCompanyForm done={handleClose} />
							</Popover>
						</div>
					)
				}
				subHeader={
					companyStore.isEmpty ? undefined : (
						<TableHeader>
							{headerGroups.map((headerGroup, index) => (
								<div
									{...(headerGroup.getHeaderGroupProps({
										// style: { paddingRight: '15px' },
									}) as any)}
									className="tr"
									key={index}
								>
									{headerGroup.headers.map((column, _index) => {
										return (
											<TableHeaderCell {...(column.getHeaderProps() as any)} key={column.id}>
												{column.render('Header')}
											</TableHeaderCell>
										);
									})}
								</div>
							))}
						</TableHeader>
					)
				}
			/>
			{companyStore.isEmpty ? (
				<Empty text={t('noCompanies')} />
			) : (
				<TableBody>
					{rows.map(row => {
						prepareRow(row);
						const onClick = () => {
							companyStore.selectCompany(row.original);
							history.push(`/${row.original.id}/templates`);
						};
						return (
							<TableRow key={row.id} style={{ padding: 0 }}>
								{
									<ListItem
										button
										onClick={onClick}
										{...(row.getRowProps() as any)}
										className={styles.row}
									>
										{row.cells.map((cell, index) => {
											return (
												<TableRowCell {...(cell.getCellProps() as any)} key={index}>
													{cell.render('Cell')}
												</TableRowCell>
											);
										})}
									</ListItem>
								}
							</TableRow>
						);
					})}
				</TableBody>
			)}
		</Page>
	);
});
