import { Grid, GridProps } from '@material-ui/core';
import { TagInfo } from '@thingos/m4i-webservice-shared';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AddTag, TagRowWithCollapseMore } from '@thingos/thingos-components';

import { Template } from '../models/template';
import { StoreContext } from '../stores';

type Props = {
	template: Template;
	tags: TagInfo[];
} & GridProps;
export const Tags: React.FC<Props> = ({ tags, template, ...rest }) => {
	const { tagStore, companyStore } = useContext(StoreContext);
	const { t } = useTranslation('templates');

	const removeTag = useCallback(
		(tag: TagInfo) => {
			template.removeTag(tag.id);
		},
		[template]
	);

	return (
		<Grid container direction="row" alignItems="center" spacing={1} {...rest}>
			<Grid item>
				<AddTag
					tags={Array.from(tagStore.tags.values())}
					onTagSelect={tag => template.addTag(tag, companyStore)}
					onCreate={tag => template.addTag(tag, companyStore)}
					createText={input => t('addTag.addNew', { name: input })}
				/>
			</Grid>
			<TagRowWithCollapseMore
				showMoreText={count => t('tagExpand', { count })}
				onDelete={removeTag}
				onSelect={tagStore.selectTag}
				tags={tags}
				style={{
					position: 'relative',
					height: '100%',
					flexWrap: 'nowrap',
				}}
			/>
		</Grid>
	);
};
