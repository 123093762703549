import { observer } from 'mobx-react-lite';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { ConfirmPasswordDialog } from './ConfirmPasswordDialog';

type Props = {
	open: boolean;
	onCancel: () => void;
	onDelete: (password: string) => Promise<void>;
};
export const DeleteCompanyDialog: React.FC<Props> = observer(props => {
	const { t } = useTranslation('companies');
	return (
		<ConfirmPasswordDialog
			{...props}
			title={t('removeDialog.title')}
			info={t('removeDialog.info')}
		/>
	);
});
