import { createContext } from 'react';
import { configure } from 'mobx';

import { AccountStore } from './accountStore';
import { CompanyStore } from './companyStore';
import { UserSelectionStore } from './userSelectionStore';
import { AdminsStore } from './adminsStore';
import { RegistrationStore } from './registrationStore';
import { PasswordResetStore } from './passwordResetStore';
import { ThemeStore } from './themeStore';
import { TagStore } from './tagStore';

// Configure mobx as strict as possible
configure({
	enforceActions: 'always',
	computedRequiresReaction: true,
	reactionRequiresObservable: true,
	observableRequiresReaction: true,
	disableErrorBoundaries: true,
});

const themeStore = new ThemeStore();
const tagStore = new TagStore();
const companyStore = new CompanyStore(themeStore, tagStore);
const adminsStore = new AdminsStore();
const accountStore = new AccountStore(companyStore);
const userSelectionStore = new UserSelectionStore();
const registrationStore = new RegistrationStore();
const passwordResetStore = new PasswordResetStore();

export const stores = {
	themeStore,
	tagStore,
	adminsStore,
	accountStore,
	companyStore,
	userSelectionStore,
	registrationStore,
	passwordResetStore,
};

export const StoreContext = createContext(stores);
