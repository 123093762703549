import { observer } from 'mobx-react';
import { useContext, useMemo } from 'react';
import logo from '../assets/logo.svg';
import { StoreContext } from '../stores';

export const Logo: React.FC<
	Omit<
		React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
		'src' | 'width' | 'height'
	>
> = observer(function Logo(props) {
	const { companyStore } = useContext(StoreContext);
	const src = useMemo(
		() =>
			companyStore.selectedCompany != null ? `${companyStore.selectedCompany.companyLogo}` : logo,
		[companyStore.selectedCompany]
	);
	return <img src={src} width="41" height="41" alt="Mesh4Industries" {...props} />;
});
